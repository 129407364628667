import React, { useEffect, useReducer, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link, RouteComponentProps } from 'react-router-dom'
import { App, Breadcrumb, Tooltip } from 'antd'
import {
  InfoCircleOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'
import jwtDecode from 'jwt-decode'
import * as Sentry from '@sentry/react'
import { useManualQuery } from 'graphql-hooks'
import { filter, find, isEmpty, isEqual, uniq } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import Api from '@vacationtracker/shared/services/api'
import * as logger from '../../services/logger'
import { initialState, reducer } from './reducer'
import { actions } from './actions'
import { filterData } from './filter-data'
import { useShouldEnableFeatures } from '../../store/use-should-enable-features'
import { getSyncedCalendarsData } from '../../graphql/custom-queries'
import { selectAuthCompanySlice } from '../../store/auth-company-slice'
import { useAppSelector } from '../../store/hooks'
import { selectAuthUserSlice } from '../../store/auth-user-slice'
import { track } from '../../services/analytics/analytics'
import { wait } from '@vacationtracker/shared/functions/wait'
import { GoogleAuth } from '../../services/auth/google/google'
import { MicrosoftAuth } from '../../services/auth/microsoft/microsoft'

import IntlMessages from '../../util/IntlMessages'
import CircularProgress from '../../components/circular-progress'
import FilterAdvanced from '@vacationtracker/shared/components/filter-advanced'
import {
  Calendars,
  Connect,
  CreateUpdate,
  Empty,
  EmptySearch,
  IcalCreate,
  IcalSummary,
  ConnectCalendarAction,
  helpDeskLink,
  Reconnect
} from './components'

import { ISyncedCalendarsData } from '../../types/custom-queries'
import { IGoogleCalendar, IOutlookCalendar, ISyncedCalendar, ISyncedCalendarTypeEnum } from '@vacationtracker/shared/types/calendar'
import { ISelected } from '@vacationtracker/shared/components/filter-advanced/types'
import { FrontendUrls } from '../../types/urls'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { IFilter } from '@vacationtracker/shared/types/filter'
import { CalendarSyncViewEnum, ICalendarSyncState, CalendarSyncStorageEnum} from './types'
import { ITokens as IMicrosoftTokens } from '../../types/microsoft'
import { IGoogleOAuthTokens } from '@vacationtracker/shared/types/google'
import { CalendarAlreadyConnected } from '@vacationtracker/shared/errors/calendar'


const googleAuth = new GoogleAuth(process.env.REACT_APP_GOOGLE_CLIENT_ID)
const msAuth = new MicrosoftAuth(process.env.REACT_APP_MICROSOFT_CLIENT_ID as string)


const CalendarSyncPage: React.FC<RouteComponentProps> = (props) => {
  const { formatMessage } = useIntl()
  const { modal, notification } = App.useApp()
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const shouldEnableFeatures = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.labels)
  const shouldEnableSyncedSharedCalendar = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.syncSharedCalendars)
  const [calendarTokens, setCalendarTokens] = useState<IMicrosoftTokens | IGoogleOAuthTokens | null>(null)
  const [state, dispatch] = useReducer(
    reducer,
    initialState
  ) as [ICalendarSyncState, React.Dispatch<any>]
  const {
    calendars,
    filters,
    isFetchingData,
    whatToRender,
    calendar,
    calendarToConnect,
    googleSyncCalendarData,
    outlookSyncCalendarData,
    locations,
    departments,
    labels,
    initialFilterValues,
    calendarToConnectType,
    filteredCalendars,
  } = state
  const {
    setCalendarToConnect,
    setCalendarToConnectType,
    setGoogleSyncCalendarData,
    setOutlookSyncCalendarData,
    setWhatToRender,
    setInitialFilters,
    setFilteredCalendars,
    setFilters,
    getCalendarsStart,
    getCalendarsSuccess,
    setCalendar,
  } = actions
  const timeoutAbortController = new AbortController()

  const handleInitialFilters = (shouldReset: boolean) => {
    const [ locationIds = [], teamIds = [], labelIds = [] ] = ['locations', 'departments', 'labels'].map(name => new URLSearchParams(location.search).get(name)?.split(','))
    const initialUrlFilters: ISelected[] = []
    if (locationIds?.length) {
      initialUrlFilters.push({ type: 'Locations', values: locationIds })
    }
    if (teamIds?.length) {
      initialUrlFilters.push({ type: 'Departments', values: teamIds })
    }
    if (labelIds?.length) {
      initialUrlFilters.push({ type: 'Labels', values: labelIds })
    }

    const initilFiltersCalendarStateInit = localStorage.getItem(`${authUser?.id ? `${authUser?.id}#` : ''}calendarFilters`)
    const initilFiltersCalendarState = initilFiltersCalendarStateInit ? JSON.parse(initilFiltersCalendarStateInit) : [{}]
    if (shouldReset) {
      if (initilFiltersCalendarState) {
        dispatch(setInitialFilters([{}]))
        localStorage.removeItem(`${authUser?.id ? `${authUser?.id}#` : ''}calendarFilters`)
      }
      return
    }
    const initilFilters = initilFiltersCalendarState?.length !== 0 && !isEmpty(initilFiltersCalendarState[0])
      ? initilFiltersCalendarState
      : initialUrlFilters.length > 0
        ? initialUrlFilters
        : [{}]
    dispatch(setInitialFilters(initilFilters))
  }

  const handleUrlFilterParams = (data) => {
    const { locationIds, teamIds, labelIds } = data
    let url = location.pathname
    let searchParams = ''
    const calendarIdParam = new URLSearchParams(location.search).get('calendarId')
    if (calendarIdParam) {
      searchParams += `&calendarId=${calendarIdParam}`
    }
    if (locationIds?.length) {
      searchParams += `&locations=${locationIds.join(',')}`
    }
    if (teamIds?.length) {
      searchParams += `&departments=${teamIds.join(',')}`
    }
    if (labelIds?.length) {
      searchParams += `&labels=${labelIds.join(',')}`
    }
    url += searchParams.replace('&', '?')
    window.history.pushState({}, '', url)
  }

  useEffect(() => {
    handleInitialFilters(false)
    return () => {
      // Cancel timeouts in the `wait` function
      timeoutAbortController.abort()
    }
  }, [])

  useEffect(() => {
    const googleSyncCalendar = localStorage.getItem(CalendarSyncStorageEnum.googleSyncCalendar)
    if (googleSyncCalendar) {
      dispatch(setGoogleSyncCalendarData(JSON.parse(googleSyncCalendar)))
    }
  }, [])

  useEffect(() => {
    const calendarsData: ISyncedCalendar[] = calendars
    if (isEmpty(filters.teamIds) && isEmpty(filters.locationIds) && isEmpty(filters.labelIds)) {
      dispatch(setFilteredCalendars(calendarsData))
    }
    const filteredCalendars: ISyncedCalendar[] = filterData(filters, calendarsData)
    dispatch(setFilteredCalendars(filteredCalendars))
  }, [filters])

  useEffect(() => {
    fetchCalendars()
  }, [])

  useEffect(() => {
    // ROUTING
    switch (props.location.pathname) {
      case FrontendUrls.calendarSyncPage:
        dispatch(setWhatToRender(CalendarSyncViewEnum.calendars))
        break
      case FrontendUrls.calendarSyncPageIcalCreate:
        dispatch(setWhatToRender(CalendarSyncViewEnum.icalCreate))
        break
      case FrontendUrls.calendarSyncPageIcalSummary: {
        dispatch(setWhatToRender(CalendarSyncViewEnum.icalSummary))
        const calendarId = new URLSearchParams(location.search).get('calendarId') && new URLSearchParams(location.search).get('calendarId')
        const foundCalendar = calendars.find(calendar => calendar.id === calendarId)
        dispatch(setCalendar(foundCalendar))
        break
      }
      case FrontendUrls.calendarSyncPageGoogleReconnect: {
        const calendarId = new URLSearchParams(props.location.search).get('calendarId')
        const calendarToReconnect = find(state.calendars, (cal: ISyncedCalendar) => cal.id === calendarId)
        dispatch(setCalendarToConnect(calendarToReconnect))
        dispatch(setWhatToRender(CalendarSyncViewEnum.googleReconnect))
        break
      }
      case FrontendUrls.calendarSyncPageOutlookReconnect: {
        const calendarId = new URLSearchParams(props.location.search).get('calendarId')
        const calendarToReconnect = find(state.calendars, (cal: ISyncedCalendar) => cal.id === calendarId)
        dispatch(setCalendarToConnect(calendarToReconnect))
        dispatch(setWhatToRender(CalendarSyncViewEnum.outlookReconnect))
        break
      }
      case FrontendUrls.calendarSyncPageGoogleConnect: {
        const googleSyncCalendar = localStorage.getItem(CalendarSyncStorageEnum.googleSyncCalendar)
        if (googleSyncCalendar) {
          props.history.push(FrontendUrls.calendarSyncPageGoogleCreate)
          dispatch(setWhatToRender(CalendarSyncViewEnum.googleCreate))
          dispatch(setGoogleSyncCalendarData(JSON.parse(googleSyncCalendar)))
          break
        } else {
          dispatch(setWhatToRender(CalendarSyncViewEnum.googleConnect))
          break
        }
      }
      case FrontendUrls.calendarSyncPageGoogleCreate: {
        const googleSyncCalendar = localStorage.getItem(CalendarSyncStorageEnum.googleSyncCalendar)
        if (googleSyncCalendar) {
          dispatch(setWhatToRender(CalendarSyncViewEnum.googleCreate))
          dispatch(setGoogleSyncCalendarData(JSON.parse(googleSyncCalendar)))
          break
        } else {
          props.history.push(FrontendUrls.calendarSyncPageGoogleConnect)
          dispatch(setWhatToRender(CalendarSyncViewEnum.googleConnect))
          break
        }
      }
      case FrontendUrls.calendarSyncPageGoogleUpdate: {
        const calendarId = new URLSearchParams(location.search).get('calendarId') && new URLSearchParams(location.search).get('calendarId')
        const foundCalendar = calendars.find(calendar => calendar.id === calendarId)
        dispatch(setCalendar(foundCalendar))
        const initialFilters: ISelected[] = []
        if (foundCalendar?.locations?.length) {
          initialFilters.push({ type: 'Locations', values: foundCalendar?.locations })
        }
        if (foundCalendar?.departments?.length) {
          initialFilters.push({ type: 'Departments', values: foundCalendar?.departments })
        }
        if (foundCalendar?.labels?.length) {
          initialFilters.push({ type: 'Labels', values: foundCalendar?.labels })
        }
        dispatch(setInitialFilters(initialFilters))
        dispatch(setWhatToRender(CalendarSyncViewEnum.googleUpdate))
        break
      }
      case FrontendUrls.calendarSyncPageOutlookConnect: {
        const outlookSyncCalendar = localStorage.getItem(CalendarSyncStorageEnum.outlookSyncCalendar)
        if (outlookSyncCalendar) {
          props.history.push(FrontendUrls.calendarSyncPageOutlookCreate)
          dispatch(setWhatToRender(CalendarSyncViewEnum.outlookCreate))
          dispatch(setOutlookSyncCalendarData(JSON.parse(outlookSyncCalendar)))
          break
        } else {
          dispatch(setWhatToRender(CalendarSyncViewEnum.outlookConnect))
          break
        }
      }
      case FrontendUrls.calendarSyncPageOutlookCreate: {
        const outlookSyncCalendar = localStorage.getItem(CalendarSyncStorageEnum.outlookSyncCalendar)
        if (outlookSyncCalendar) {
          dispatch(setWhatToRender(CalendarSyncViewEnum.outlookCreate))
          dispatch(setOutlookSyncCalendarData(JSON.parse(outlookSyncCalendar)))
          break
        } else {
          props.history.push(FrontendUrls.calendarSyncPageOutlookConnect)
          dispatch(setWhatToRender(CalendarSyncViewEnum.outlookConnect))
          break
        }
      }
      case FrontendUrls.calendarSyncPageOutlookUpdate: {
        const calendarId = new URLSearchParams(location.search).get('calendarId') && new URLSearchParams(location.search).get('calendarId')
        const foundCalendar = calendars.find(calendar => calendar.id === calendarId)
        dispatch(setCalendar(foundCalendar))
        const initialFilters: ISelected[] = []
        if (foundCalendar?.locations?.length) {
          initialFilters.push({ type: 'Locations', values: foundCalendar?.locations })
        }
        if (foundCalendar?.departments?.length) {
          initialFilters.push({ type: 'Departments', values: foundCalendar?.departments })
        }
        if (foundCalendar?.labels?.length) {
          initialFilters.push({ type: 'Labels', values: foundCalendar?.labels })
        }
        dispatch(setInitialFilters(initialFilters))
        dispatch(setWhatToRender(CalendarSyncViewEnum.outlookUpdate))
        break
      }
      default:
        dispatch(setWhatToRender(CalendarSyncViewEnum.calendars))
        break
    }
  }, [props.location.pathname, calendars])

  const [ getSyncedCalendarsDataQuery ] = useManualQuery<ISyncedCalendarsData, { companyId: string }>(getSyncedCalendarsData)

  const fetchCalendars = async () => {
    try {
      if (!authCompany?.id) {
        return
      }
      dispatch(getCalendarsStart())
      const response = await getSyncedCalendarsDataQuery({ variables: { companyId: authCompany?.id }})
      if (!response.data || response.error) throw response.error
      dispatch(getCalendarsSuccess({
        locations: response.data?.getLocationList,
        departments: response.data?.getTeamListV2,
        labels: response.data?.getLabels,
        calendars: [
          ...response.data?.getCalendars?.filter(cal => !cal.deleted).map((calendar: ISyncedCalendar) => {
            return {
              type: !calendar.type || calendar.type === ISyncedCalendarTypeEnum.ical ? ISyncedCalendarTypeEnum.ical : calendar.type,
              id: calendar.id,
              refreshToken: calendar.refreshToken,
              accessToken: calendar.accessToken,
              locations: (calendar.locationIds?.length
                ? uniq([...calendar.locationIds, calendar.locationId]).filter(Boolean)
                : calendar.locationId
                  ? [calendar.locationId]
                  : []) as string[],
              departments: (calendar.teamIds?.length
                ? uniq([...calendar.teamIds, calendar.teamId]).filter(Boolean)
                : calendar.teamId
                  ? [calendar.teamId]
                  : []) as string[],
              labels: calendar.labelIds?.length
                ? calendar.labelIds
                : [],
              creator: calendar.creator,
              user: calendar?.user,
              error: calendar.error || calendar.errors,
              calendarName: calendar.calendarName,
              timezone: calendar.timezone,
            }
          }) || [],
        ],
      }))
    } catch (err) {
      logger.error('error fetching calendars', err)
    }
  }

  const filterChanged = (data: IFilter) => {
    if (!isEqual(data, filters)) {
      handleUrlFilterParams(data)
      handleInitialFilters(false)
      dispatch(setFilters(data))
    }
  }

  const handleSelectCalendarToSyncType = (type) => {
    dispatch(setCalendarToConnectType(type.key))
    switch (type.key) {
      case ISyncedCalendarTypeEnum.google:
        props.history.push(FrontendUrls.calendarSyncPageGoogleConnect)
        break
      case ISyncedCalendarTypeEnum.outlook:
        props.history.push(FrontendUrls.calendarSyncPageOutlookConnect)
        break
      case ISyncedCalendarTypeEnum.ical:
        props.history.push(FrontendUrls.calendarSyncPageIcalCreate)
        break
    }
  }

  const handleSelectCalendarToSync = (calendarId: string) => {
    const syncCalendarData = googleSyncCalendarData || outlookSyncCalendarData
    if (!syncCalendarData) return

    const calendar = find(syncCalendarData.calendarList, (cal: IGoogleCalendar | IOutlookCalendar) => cal.id === calendarId) as IGoogleCalendar | IOutlookCalendar
    if (!calendar) return

    if (calendars.find(connectedCalendars => connectedCalendars.id === calendar.id)) {
      notification.error({
        message: formatMessage({ id: 'calendar.sync.calendarAlreadyConnected' }),
      })
      return
    }
    notification.destroy()
    dispatch(setCalendarToConnect(calendar))
  }

  const handleCreateIcalCalendarSync = async () => {
    const notificationKey = uuidv4()
    try {
      notification.info({
        message: formatMessage({ id: 'calendar.sync.creating' }),
        key: notificationKey,
      })
      const response = await Api.post('/core/calendar-id', {
        ...filters,
        creator: authUser?.id,
        type: ISyncedCalendarTypeEnum.ical,
      })
      notification.success({
        key: notificationKey,
        message: formatMessage({ id: 'calendar.sync.creatingSuccess' }),
      })
      const url = `${FrontendUrls.calendarSyncPageIcalSummary}?calendarId=${response.id}`
      handleInitialFilters(true)
      props.history.push(url)
      track('CALENDAR_LINK_GENERATED_ICAL', {
        creator: authUser.id,
        companyId: authCompany?.id,
        ...filters,
      })
    } catch (error) {
      notification.error({
        key: notificationKey,
        message: formatMessage({ id: 'calendar.sync.creatingError' }),
      })
      logger.warning(error)
    }
  }

  const confirmDeleteCalendar = (calendar: ISyncedCalendar, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()
    modal.confirm({
      title: formatMessage({ id: 'calendar.sync.deleteTitle' }),
      icon: <ExclamationCircleOutlined />,
      content: formatMessage(
        { id: 'calendar.sync.confirmDelete' },
        {
          name: calendar.calendarName,
          strong: (...chunks) => <strong>{chunks}</strong>,
        }),
      okText: formatMessage({ id: 'app.delete' }),
      okType: 'danger',
      maskClosable: true,
      onOk() {
        handleDeleteCalendar(calendar.id)
      },
    })
  }

  const handleDeleteCalendar = async (calendarId: string) => {
    const notificationKey = uuidv4()
    try {
      notification.info({
        key: notificationKey,
        message: formatMessage({ id: 'calendar.sync.deleting' }),
      })
      await Api.post('/core/delete-calendar-id', {
        calendarId,
      })
      notification.success({
        key: notificationKey,
        message: formatMessage({ id: 'calendar.sync.deletingSuccess' }),
      })
      handleInitialFilters(true)
      props.history.push(FrontendUrls.calendarSyncPage)
    } catch (error) {
      Sentry.captureException(error)
      notification.error({
        key: notificationKey,
        message: formatMessage({ id: 'calendar.sync.deletingError' }),
      })
    }
  }

  const refreshCalendarList = async (platform: 'Google' | 'Outlook') => {
    if (platform === 'Google') {
      if (calendarTokens?.accessToken) {
        await loadGoogleCalendarData(calendarTokens as IGoogleOAuthTokens)
      } else {
        await handleGoogleConnect()
      }
    } else if (platform === 'Outlook') {
      if (calendarTokens?.accessToken) {
        await loadOutlookCalendarData(calendarTokens as IMicrosoftTokens)
      } else {
        await handleOutlookConnect()
      }
    }
  }

  const setGoogleSyncCalendarState = (data) => {
    localStorage.setItem(CalendarSyncStorageEnum.googleSyncCalendar, JSON.stringify(data))
  }

  const loadGoogleCalendarData = async (tokens: IGoogleOAuthTokens) => {
    const googleCalendarsResponse = await googleAuth.getGoogleCalendars(tokens.accessToken as string)
    if (googleCalendarsResponse?.items?.length as number > 0) {
      const userCalendars = filter(googleCalendarsResponse?.items, (cal: IGoogleCalendar) => cal.accessRole === 'owner' || cal.accessRole === 'writer')
      const newGoogleSyncCalendarState = {
        ...tokens,
        calendarList: userCalendars,
      }
      setGoogleSyncCalendarState(newGoogleSyncCalendarState)
      dispatch(setGoogleSyncCalendarData(newGoogleSyncCalendarState))
    }
  }

  const handleGoogleConnect = async () => {
    try {
      const response = await googleAuth.grantCalendarPermission()
      if (response && response.accessToken) {
        setCalendarTokens(response)
        await loadGoogleCalendarData(response)
      }
      if (props.location.pathname !== FrontendUrls.calendarSyncPageGoogleCreate) {
        props.history.push(FrontendUrls.calendarSyncPageGoogleCreate)
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const handleGoogleCreate = async () => {
    const notificationKey = uuidv4()
    try {
      notification.info({
        key: notificationKey,
        message: formatMessage({ id: 'calendar.sync.creating' }),
      })
      const body = {
        type: ISyncedCalendarTypeEnum.google,
        accessToken: googleSyncCalendarData?.accessToken,
        refreshToken: googleSyncCalendarData?.refreshToken,
        calendarId: calendarToConnect?.id,
        calendarName: calendarToConnect?.summary,
        timezone: calendarToConnect?.timeZone,
        locationIds: filters.locationIds,
        teamIds: filters.teamIds,
        labelIds: filters.labelIds,
        creator: authUser?.id,
      }

      await Api.post('/core/calendar-id', body)
      track('SHARED_CALENDAR_GENERATED_GOOGLE', {
        creator: authUser.id,
        companyId: authCompany?.id,
        ...filters,
      })
      localStorage.removeItem(CalendarSyncStorageEnum.googleSyncCalendar)
      dispatch(setCalendarToConnect(null))
      notification.success({
        key: notificationKey,
        message: formatMessage({ id: 'calendar.sync.creatingSuccess' }),
      })
      handleInitialFilters(true)
      props.history.push(FrontendUrls.calendarSyncPage)
    } catch (error) {
      if (error?.response?.data?.code === CalendarAlreadyConnected.code) {
        notification.error({
          key: notificationKey,
          message: formatMessage({ id: 'calendar.sync.calendarAlreadyConnected' }),
          duration: 10,
        })
        return
      }
      notification.error({
        key: notificationKey,
        message: formatMessage({ id: 'calendar.sync.creatingError' }),
      })
      Sentry.captureException(error)
    }
  }

  const handleGoogleUpdate = async () => {
    const notificationKey = uuidv4()
    try {
      notification.info({
        key: notificationKey,
        message: formatMessage({ id: 'calendar.sync.updating' }),
      })
      const body = {
        type: calendar?.type,
        accessToken: calendar?.accessToken,
        refreshToken: calendar?.refreshToken,
        calendarId: calendar?.id,
        calendarName: calendar?.calendarName,
        timezone: calendar?.timezone,
        creator: state?.calendar?.creator,
        isCalendarUpdate: true,
        // only filters can be changed on calendar update
        locationIds: filters.locationIds,
        teamIds: filters.teamIds,
        labelIds: filters.labelIds,
      }

      await Api.post('/core/calendar-id', body)
      dispatch(setCalendarToConnect(null))
      notification.success({
        key: notificationKey,
        message: formatMessage({ id: 'calendar.sync.updatingSuccess' }),
      })
      handleInitialFilters(true)
      props.history.push(FrontendUrls.calendarSyncPage)
    } catch (error) {
      logger.error(error)
      notification.error({
        key: notificationKey,
        message: formatMessage({ id: 'calendar.sync.updatingError' }),
      })
    }
  }

  const setOutlookSyncCalendarState = (data) => {
    localStorage.setItem(CalendarSyncStorageEnum.outlookSyncCalendar, JSON.stringify(data))
  }

  const loadOutlookCalendarData = async (tokenResponse: IMicrosoftTokens) => {
    const timezone = await msAuth.getUserOutlookTimezone(tokenResponse.accessToken)
    if (timezone) {
      const outlookCalendarsResponse = await msAuth.getCalendars(tokenResponse.accessToken)
      if (outlookCalendarsResponse?.length as number > 0) {
        const userCalendars = filter(outlookCalendarsResponse, (cal: IOutlookCalendar) => !cal.isDefaultCalendar && (cal.canEdit || cal.canShare))
        const newOutlookSyncCalendarState = {
          ...tokenResponse,
          calendarList: userCalendars.map(cal => ({...cal, timezone })),
        }
        setOutlookSyncCalendarState(newOutlookSyncCalendarState)
        dispatch(setOutlookSyncCalendarData(newOutlookSyncCalendarState))
      }
    }
  }

  const handleOutlookConnect = async () => {
    try {
      const tokenResponse = await msAuth.getWebAppTokensForOutlook('offline_access Calendars.ReadWrite MailboxSettings.Read')
      if (tokenResponse && tokenResponse.accessToken) {
        setCalendarTokens(tokenResponse)
        await loadOutlookCalendarData(tokenResponse)
        if (props.location.pathname !== FrontendUrls.calendarSyncPageOutlookCreate) {
          props.history.push(FrontendUrls.calendarSyncPageOutlookCreate)
        }
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const handleOutlookCreate = async () => {
    let tenantId
    try {
      const decoded = jwtDecode<{tid: string}>(outlookSyncCalendarData?.accessToken as string)
      tenantId = decoded.tid
    } catch (error) {
      Sentry.captureException(JSON.stringify({
        ...error,
        message: 'Error decoding microsoft token - fallback to "common" tenant',
      }))
      tenantId = 'common'
    }
    const notificationKey = uuidv4()
    try {
      notification.info({
        key: notificationKey,
        message: formatMessage({ id: 'calendar.sync.creating' }),
      })
      const body = {
        type: ISyncedCalendarTypeEnum.outlook,
        accessToken: outlookSyncCalendarData?.accessToken,
        refreshToken: outlookSyncCalendarData?.refreshToken,
        calendarId: calendarToConnect?.id,
        calendarName: calendarToConnect?.name,
        timezone: calendarToConnect?.timezone,
        locationIds: filters.locationIds,
        teamIds: filters.teamIds,
        labelIds: filters.labelIds,
        creator: authUser?.id,
        tenant: tenantId,
      }

      await Api.post('/core/calendar-id', body)
      track('SHARED_CALENDAR_GENERATED_OUTLOOK', {
        creator: authUser.id,
        companyId: authCompany?.id,
        ...filters,
      })
      localStorage.removeItem(CalendarSyncStorageEnum.outlookSyncCalendar)
      dispatch(setCalendarToConnect(null))
      notification.success({
        key: notificationKey,
        message: formatMessage({ id: 'calendar.sync.creatingSuccess' }),
      })
      handleInitialFilters(true)
      props.history.push(FrontendUrls.calendarSyncPage)
    } catch (error) {
      notification.error({
        key: notificationKey,
        message: formatMessage({ id: 'calendar.sync.creatingError' }),
      // TODO: IVAN add error message from backend
      })
      Sentry.captureException(error)
    }
  }

  const handleOutlookReconnect = async () => {
    const tokenResponse = await msAuth.getWebAppTokensForOutlook('offline_access Calendars.ReadWrite MailboxSettings.Read')
    const timezone = await msAuth.getUserOutlookTimezone(tokenResponse.accessToken)
    let tenantId
    try {
      const decoded = jwtDecode<{tid: string}>(tokenResponse.accessToken as string)
      tenantId = decoded.tid
    } catch (error) {
      Sentry.captureException(JSON.stringify({
        ...error,
        message: 'Error decoding microsoft token - fallback to "common" tenant',
      }))
      tenantId = 'common'
    }
    const notificationKey = uuidv4()
    try {
      notification.info({
        key: notificationKey,
        message: formatMessage({ id: 'calendar.sync.reconnecting' }),
      })
      const body = {
        type: ISyncedCalendarTypeEnum.outlook,
        accessToken: tokenResponse?.accessToken,
        refreshToken: tokenResponse?.refreshToken,
        calendarId: calendarToConnect?.id,
        calendarName: calendarToConnect?.name,
        timezone: timezone,
        locationIds: filters.locationIds,
        teamIds: filters.teamIds,
        labelIds: filters.labelIds,
        creator: authUser?.id,
        tenant: tenantId,
        shouldReconnect: true,
      }

      await Api.post('/core/calendar-id', body)
      track('SHARED_CALENDAR_RECONNECTED_OUTLOOK', {
        creator: authUser.id,
        companyId: authCompany?.id,
        ...filters,
      })
      dispatch(setCalendarToConnect(null))
      await wait(3000, timeoutAbortController)
      notification.success({
        key: notificationKey,
        message: formatMessage({ id: 'calendar.sync.reconnectingSuccess' }),
      })
      handleInitialFilters(true)
      props.history.push(FrontendUrls.calendarSyncPage)
    } catch (error) {
      notification.error({
        key: notificationKey,
        message: formatMessage({ id: 'calendar.sync.reconnectingError' }),
      })
      Sentry.captureException(error)
    }
  }

  const handleGoogleReconnect = async () => {
    const response = await googleAuth.grantCalendarPermission()
    const notificationKey = uuidv4()
    try {
      notification.info({
        key: notificationKey,
        message: formatMessage({ id: 'calendar.sync.reconnecting' }),
      })
      const body = {
        type: ISyncedCalendarTypeEnum.google,
        accessToken: response?.accessToken,
        refreshToken: response?.refreshToken,
        calendarId: calendarToConnect?.id,
        calendarName: calendarToConnect?.summary,
        timezone: calendarToConnect?.timeZone,
        locationIds: filters.locationIds,
        teamIds: filters.teamIds,
        labelIds: filters.labelIds,
        creator: authUser?.id,
        shouldReconnect: true,
      }

      await Api.post('/core/calendar-id', body)
      track('SHARED_CALENDAR_RECONNECTED_GOOGLE', {
        creator: authUser.id,
        companyId: authCompany?.id,
        ...filters,
      })
      dispatch(setCalendarToConnect(null))
      await wait(3000, timeoutAbortController)
      notification.success({
        key: notificationKey,
        message: formatMessage({ id: 'calendar.sync.reconnectingSuccess' }),
      })
      handleInitialFilters(true)
      props.history.push(FrontendUrls.calendarSyncPage)
    } catch (error) {
      notification.error({
        key: notificationKey,
        message: formatMessage({ id: 'calendar.sync.reconnectingError' }),
      })
      Sentry.captureException(error)
    }
  }

  const handleOutlookUpdate = async () => {
    const notificationKey = uuidv4()
    try {
      notification.info({
        key: notificationKey,
        message: formatMessage({ id: 'calendar.sync.updating' }),
      })
      const body = {
        type: calendar?.type,
        accessToken: calendar?.accessToken,
        refreshToken: calendar?.refreshToken,
        calendarId: calendar?.id,
        calendarName: calendar?.calendarName,
        timezone: calendar?.timezone,
        creator: state?.calendar?.creator,
        isCalendarUpdate: true,
        // only filters can be changed on calendar update
        locationIds: filters.locationIds,
        teamIds: filters.teamIds,
        labelIds: filters.labelIds,
      }

      await Api.post('/core/calendar-id', body)
      dispatch(setCalendarToConnect(null))
      notification.success({
        message: formatMessage({ id: 'calendar.sync.updatingSuccess' }),
      })
      handleInitialFilters(true)
      notification.success({
        key: notificationKey,
        message: formatMessage({ id: 'calendar.sync.reconnectingSuccess' }),
      })
      props.history.push(FrontendUrls.calendarSyncPage)
    } catch (error) {
      logger.error(error)
      notification.error({
        key: notificationKey,
        message: formatMessage({ id: 'calendar.sync.updatingError' }),
      })
    }
  }

  const handleCancel = () => {
    handleInitialFilters(true)
    dispatch(setCalendarToConnect(null))
    localStorage.removeItem(CalendarSyncStorageEnum.googleSyncCalendar)
    localStorage.removeItem(CalendarSyncStorageEnum.outlookSyncCalendar)
    props.history.push(FrontendUrls.calendarSyncPage)
  }

  const handleLoginWihDifferentAccount = (provider: 'Google' | 'Outlook') => {
    const localStorageKey = provider === 'Google' ? CalendarSyncStorageEnum.googleSyncCalendar : CalendarSyncStorageEnum.outlookSyncCalendar
    const connectUrl = provider === 'Google' ? FrontendUrls.calendarSyncPageGoogleConnect : FrontendUrls.calendarSyncPageOutlookConnect
    localStorage.removeItem(localStorageKey)
    dispatch(setCalendarToConnect(null))
    props.history.push(connectUrl)
  }


  return (
    <div className="main-content vt-callendar-sync-page">
      <div className="main-content-header">
        <div className="main-content-header-title">
          <IntlMessages id="calendar.sync.title" />&nbsp;
          <Tooltip
            className="info-tooltip"
            title={<IntlMessages
              id="calendar.sync.tooltipInfo"
              values={{
                helpDesk: (...chunks) => (
                  <a
                    href={helpDeskLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {chunks}
                  </a>
                ),
              }}
            />} >
            <InfoCircleOutlined />
          </Tooltip>
        </div>
        <div className="main-content-header-breadcrumb">
          <Breadcrumb
            items={[
              {
                title: <Link to={FrontendUrls.dashboard}><IntlMessages id="sidebar.dashboard" /></Link>,
              },
              {
                title: <Link to={FrontendUrls.calendarPage}><IntlMessages id="sidebar.calendar" /></Link>,
              },
              {
                title: <IntlMessages id="app.sync" />,
              },
            ]}
          />
        </div>
      </div>
      <div className="main-content-body">
        {whatToRender === CalendarSyncViewEnum.calendars && <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          <ConnectCalendarAction
            handleSelectCalendarToSyncType={handleSelectCalendarToSyncType}
            shouldEnableSyncedSharedCalendar={shouldEnableSyncedSharedCalendar}
          />
          <div className="filters">
            <div>
              {!isFetchingData &&
                <FilterAdvanced
                  page='calendar'
                  data={{
                    Locations: locations,
                    Departments: departments,
                    Labels: labels,
                  }}
                  onChangeFilter={filterChanged}
                  initialValues={initialFilterValues}
                  showLabels={shouldEnableFeatures}
                  currentUserId={authUser.id}
                />
              }
            </div>
          </div>
        </div>}
        {isFetchingData && <CircularProgress />}
        <Calendars
          state={state}
          confirmDeleteCalendar={confirmDeleteCalendar}
          history={props.history} />
        <Empty
          state={{
            whatToRender,
            isFetchingData,
            calendars: calendars as ISyncedCalendar[],
            calendarToConnectType,
          }}
          shouldEnableSyncedSharedCalendar={shouldEnableSyncedSharedCalendar}
          handleSelectCalendarToSyncType={handleSelectCalendarToSyncType}
        />
        <EmptySearch
          state={{
            whatToRender,
            isFetchingData,
            filteredCalendars,
            calendars: calendars as ISyncedCalendar[],
          }} />
        <IcalCreate
          handleCreateIcalCalendarSync={handleCreateIcalCalendarSync}
          state={{
            whatToRender,
            isFetchingData,
            locations,
            departments,
            labels,
            initialFilterValues,
          }}
          filterChanged={filterChanged}
          handleCancel={handleCancel} />
        <IcalSummary
          state={{
            calendar,
            labels,
            locations,
            departments,
            whatToRender,
          }}
          confirmDeleteCalendar={confirmDeleteCalendar}
          handleCancel={handleCancel} />
        <Connect
          provider={whatToRender === CalendarSyncViewEnum.googleConnect ? 'Google' : 'Outlook'}
          whatToRender={whatToRender}
          handleGoogleConnect={handleGoogleConnect}
          handleOutlookConnect={handleOutlookConnect}
          handleCancel={handleCancel} />
        <Reconnect
          provider={whatToRender === CalendarSyncViewEnum.googleReconnect ? 'Google' : 'Outlook'}
          whatToRender={whatToRender}
          handleGoogleReconnect={handleGoogleReconnect}
          handleOutlookReconnect={handleOutlookReconnect}
          handleCancel={handleCancel} />
        <CreateUpdate
          provider={whatToRender === CalendarSyncViewEnum.googleCreate
            || whatToRender === CalendarSyncViewEnum.googleUpdate
            ? 'Google'
            : 'Outlook'}
          state={state}
          handleCancel={handleCancel}
          handleLoginWihDifferentAccount={handleLoginWihDifferentAccount}
          handleGoogleCreate={handleGoogleCreate}
          handleGoogleUpdate={handleGoogleUpdate}
          handleOutlookCreate={handleOutlookCreate}
          handleOutlookUpdate={handleOutlookUpdate}
          handleSelectCalendarToSync={handleSelectCalendarToSync}
          filterChanged={filterChanged}
          confirmDeleteCalendar={confirmDeleteCalendar}
          refreshCalendarList={refreshCalendarList}
        />
      </div>
    </div>
  )
}

export default CalendarSyncPage
