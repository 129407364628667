import * as Sentry from '@sentry/react'
import { CloudWatchLogsClient, PutLogEventsCommand, CreateLogStreamCommand, DescribeLogStreamsCommand } from '@aws-sdk/client-cloudwatch-logs'
import { IResult, UAParser } from 'ua-parser-js'
import { wait } from '@testing-library/user-event/dist/utils'
import { getCredentials, getCurrentUser } from '@vacationtracker/shared/services/auth'

interface ILogToCloudWatchParams {
  type: 'NONE' | 'DEBUG' | 'INFO' | 'WARNING' | 'ERROR'
  companyId: string
  userId: string
  logMessages: LogMessage[]
  client: CloudWatchLogsClient
  logGroupName: string
  logStreamName: string
}

type LogMessage = string | number | object | boolean | null | undefined | Error

const logLevel = process.env.REACT_APP_LOG_LEVEL || 'WARNING'
const logGroupName = process.env.REACT_APP_FRONTEND_LOG_GROUP_NAME
const sendLogsToCloudWatch = Boolean(process.env.REACT_APP_LOG_TO_CLOUDWATCH)
const region = process.env.REACT_APP_REGION || 'eu-central-1'

let initialized = false
let logStreamName: string | null = null
let client: CloudWatchLogsClient | null = null
let companyId: string | null = null
let userId: string | null = null
let platform: string | null = null

function getStackTrace() {
  const err = new Error()
  return err.stack
}


async function getCloudWatchLogsClient() {
  const credentials = await getCredentials()
  return new CloudWatchLogsClient({
    region: region,
    credentials,
  })
}

async function getOrCreateLogStream(companyId: string, userId: string) {
  const logStreamName = `${companyId}-${userId}`
  const client = await getCloudWatchLogsClient()

  try {
    // Check if the log stream already exists
    const describeCommand = new DescribeLogStreamsCommand({
      logGroupName,
      logStreamNamePrefix: logStreamName,
      limit: 1,
    })
    const describeResponse = await client.send(describeCommand)

    if (describeResponse.logStreams && describeResponse.logStreams.length > 0) {
      return logStreamName
    }

    // If not, create the log stream
    const createCommand = new CreateLogStreamCommand({
      logGroupName,
      logStreamName,
    })
    await client.send(createCommand)

    return logStreamName
  } catch (error) {
    console.error('Error getting or creating log stream', error)
    Sentry.captureException(error)
    throw error
  }
}

async function logToCloudWatch({ client, companyId, logGroupName, logStreamName, logMessages, type, userId }: ILogToCloudWatchParams) {
  if (!initialized) {
    await init()
    // If it's still not initialize, fallback to console.log
    if (!initialized) {
      console.log(...logMessages)
      return
    }
  }
  let userAgentInfo: null | IResult = null
  try {
    const uaParserInstance = new UAParser()
    const uaString = uaParserInstance.getUA()
    uaParserInstance.setUA(uaString)
    userAgentInfo = uaParserInstance.getResult()
  } catch(error) {
    // Swallow error
  }
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const logEventMessage: any = {
      type,
      messages: logMessages,
      metadata: {
        companyId,
        userId,
        platform,
        userAgentInfo,
        screen: {
          width: window.innerWidth,
          height: window.innerHeight,
          deviceWidth: window.screen.availWidth,
          deviceHeight: window.screen.availHeight,
        },
        location: {
          href: window.location.href,
          path: window.location.pathname,
        },
        browserLanguage: window.navigator.language,
      },
    }
    if (['ERROR', 'WARNING'].includes(type)) {
      logEventMessage.trace = getStackTrace()
    }
    const command = new PutLogEventsCommand({
      logGroupName,
      logStreamName,
      logEvents: [{
        message: JSON.stringify(logEventMessage),
        timestamp: Date.now(),
      }],
    })

    await client.send(command)
  } catch (error) {
    Sentry.captureException(error)
  }
}

async function init(retries = 5) {
  if (!logGroupName || !sendLogsToCloudWatch) {
    return
  }

  try {
    const user = await getCurrentUser()
    companyId = user.attributes['custom:companyId']
    userId = user.username
    platform = user.attributes['custom:platform']

    if (!companyId || !userId) {
      if (retries > 0) {
        await wait(1000)
        return init(retries - 1)
      }
      return
    }
    logStreamName = await getOrCreateLogStream(companyId, userId)
    client = await getCloudWatchLogsClient()
    initialized = true
  } catch (err) {
    // Swallow errors, we do not want to break the app
  }
}

function debug(...messages: LogMessage[]) {
  if (logLevel === 'NONE') {
    return
  }
  if (!sendLogsToCloudWatch || !client || !companyId || !userId || !logStreamName || !logGroupName) {
    console.log(...messages)
  } else {
    // Log to CloudWatch only if the debug level is 'DEBUG'
    if (logLevel !== 'DEBUG') {
      return
    }
    try {
      logToCloudWatch({ client, companyId, logGroupName, logStreamName, logMessages: messages, userId, type: 'INFO' })
    } catch (error) {
      // Swallow the log error to avoid breaking the program
    }
  }
}

function info(...messages: LogMessage[]) {
  if (logLevel === 'NONE') {
    return
  }
  if (!sendLogsToCloudWatch || !client || !companyId || !userId || !logStreamName || !logGroupName) {
    console.log(...messages)
  } else {
    // Log to CloudWatch only if the debug level is 'DEBUG' or 'INFO'.
    if (!['DEBUG', 'INFO'].includes(logLevel)) {
      return
    }
    try {
      logToCloudWatch({ client, companyId, logGroupName, logStreamName, logMessages: messages, userId, type: 'INFO' })
    } catch (error) {
      // Swallow the log error to avoid breaking the program, but still log it to Sentry
      Sentry.captureMessage(JSON.stringify(messages))
    }
  }
}

function warning(...messages: LogMessage[]) {
  if (logLevel === 'NONE') {
    return
  }
  if (!sendLogsToCloudWatch || !client || !companyId || !userId || !logStreamName || !logGroupName) {
    console.log(...messages)
  } else {
    // Log to CloudWatch unless the debug level is set to 'ERROR'
    if (logLevel === 'ERROR') {
      return
    }
    try {
      logToCloudWatch({ client, companyId, logGroupName, logStreamName, logMessages: messages, userId, type: 'WARNING' })
      if (messages.length === 1 && messages[0] instanceof Error) {
        Sentry.captureException(messages[0])
      }
      Sentry.captureException(messages)
    } catch (error) {
      // Swallow the log error to avoid breaking the program, but still log it to Sentry
      if (messages.length === 1 && messages[0] instanceof Error) {
        Sentry.captureException(messages[0])
      }
      Sentry.captureException(messages)
    }
  }
}

function error(...messages: LogMessage[]) {
  if (logLevel === 'NONE') {
    return
  }
  if (!sendLogsToCloudWatch || !client || !companyId || !userId || !logStreamName || !logGroupName) {
    console.log(...messages)
  } else {
    try {
      logToCloudWatch({ client, companyId, logGroupName, logStreamName, logMessages: messages, userId, type: 'ERROR' })
      if (messages.length === 1 && messages[0] instanceof Error) {
        Sentry.captureException(messages[0])
      }
      Sentry.captureException(messages)
    } catch (error) {
      // Swallow the log error to avoid breaking the program, but still log it to Sentry
      if (messages.length === 1 && messages[0] instanceof Error) {
        Sentry.captureException(messages[0])
      }
      Sentry.captureException(messages)
    }
  }
}

export {
  init,
  debug,
  info,
  warning,
  error
}
