import React, { useState, useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { omit } from 'lodash'
import { App, Badge, Breadcrumb, Menu, Row, Col, Modal, Tabs, Alert, TabsProps } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { RcFile } from 'antd/lib/upload/interface'
import { lowerCase, sortBy } from 'lodash'
import dayjs from 'dayjs'
import { useManualQuery } from 'graphql-hooks'

import Api from '@vacationtracker/shared/services/api'
import { getUserById, getConnectedCalendars, getTeamsForApprover, getAutomations, getSuggestedSubstituteApprovers } from '../../../graphql/custom-queries'
import * as logger from '../../../services/logger'

import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { roleAdmin, roleApprover, selectAuthUserSlice } from '../../../store/auth-user-slice'
import { useShouldEnableFeatures } from '../../../store/use-should-enable-features'
import { selectLeaveRequestActionEventSlice, setLeaveRequestActionEvent } from '../../../store/leave-request-action-event-slice'
import { selectLocaleSlice } from '../../../store/locale-slice'
import { selectAuthCompanySlice } from '../../../store/auth-company-slice'
import { notificationStore } from '../../../context/notificationsContext/store'
import { uploadAvatar } from '../../../services/api/files'

import IntlMessages from '../../../util/IntlMessages'
import CircularProgress from '../../../components/circular-progress'
import { UserAvatar } from '@vacationtracker/shared/components/user-avatar'
import LeaveForm from '@vacationtracker/shared/components/leave-form'
import UserTodayOff from '../../../components/user-today-off'
import UserProfileTab from '../../../components/user-profile-tab'
import UserLeavesTab from '../../../components/user-leaves-tab'
import UserLogsTab from '../../../components/user-logs-tab'
import UserLabels from '../../../components/user-labels'
import NotFoundPage from '../../NotFoundPage'
import UserMyProfileModal from '../../../components/modal-update-my-profile'
import { isToilLeave } from '@vacationtracker/shared/functions/is-toil-leave-request'

import { IQuotaUpdates, IRolloverQuotaUpdates } from '../../../components/user-profile-tab/types'
import { 
  IGetAutomations,
  IGetConnectedCalendarsResponse,
  IGetUser,
  IGetUserById,
  IGetUsersForAdminAddLeaveData,
  IUserHistory,
  IUserPendingLeaves,
  IUserProbationPeriod,
  IUserToday,
  IUserUpcomingLeaves
} from '../../../types/custom-queries'
import { IGetTeamsShort } from '../../../types/teams'
import { IGetLocationsShort } from '../../../types/locations'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { HourFormatEnum, IUserUpdatedEvent } from '@vacationtracker/shared/types/user'
import { Platform } from '@vacationtracker/shared/types/core-event'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { ShortestLeaveIntervalEnum } from '@vacationtracker/shared/types/leave-policy'
import { ILeaveFormSaveData } from '@vacationtracker/shared/components/leave-form/types'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { IConnectedGoogleCalendar, IConnectedOutlookCalendar } from '@vacationtracker/shared/types/calendar'
import { useSubstituteApprover } from '../../../util/hooks/use-substitute-approver'
import { IResendLeaveRequest } from '@vacationtracker/shared/types/leave-request'
import { FrontendUrls } from '../../../types/urls'
import { GetTeamsForApproverData } from '@vacationtracker/shared/components/add-request-leave-additional-info/types'

interface ILabel {
  id: string
  name: string
  color: string
  userCount?: number
}

interface IUserPage {
  match: {
    params: {
      id: string
    }
  }
  location: {
    pathname: string
    search: string
  }
}

const UserPage = ({ match, location }: IUserPage): React.ReactElement => {
  const { formatMessage } = useIntl()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const { notification } = App.useApp()
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const { locale } = useAppSelector(selectLocaleSlice)
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { leaveRequestActionEvent } = useAppSelector(selectLeaveRequestActionEventSlice)
  const dispatch = useAppDispatch()
  const amIAdmin = useSelector(roleAdmin)
  const amIApprover = useSelector(roleApprover)
  const shouldEnableSubstituteApprovers = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.substituteApprovers)

  const [visibleUserUpdateForm, setVisibleUserUpdateForm] = useState(false)
  const [ isUserEndDateEnabled, setIsUserEndDateEnabled ] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState<IGetUser>({
    id: '',
    name: '',
    imageUrl: '',
    role: '',
    isAdmin: false,
    email: '',
    platform: '',
    status: '',
    startDate: '',
    endDate: '',
    employeeId: '',
    isNameLocked: false,
    leaveDays: [
      {
        yearStart: new Date().toISOString(),
        yearEnd: new Date().toISOString(),
        leaveTypes: [
          {
            leaveTypeId: '',
            leaveTypeName: '',
            leavePolicyId: '',
            defaultDaysPerYear: 0,
            hasUnlimitedDays: false,
            rolloverFromPreviousYear: 0,
            maxRolloverDays: 0,
            expiredDays: 0,
            earnedDays: 0,
            earnedDaysWithoutRollover: 0,
            usedDays: 0,
            scheduledDays: 0,
            usedDaysInPeriod: 0,
            currentDays: 0,
            accrualType: 'NONE',
            shortestLeaveInterval: ShortestLeaveIntervalEnum.fullDay,
            userLeaveTypeOverride: false,
            isActive: false,
            position: 0,
            color: '',
            ignoreRolloverFromPreviousYear: false,
            rolloverExpiryDate: '',
            seniorityEntitlementDaysForYear: 0,
            senioirtyEntitlementExists: false,
            entitlementByRoleDaysForYear: 0,
            entitlementByRoleExists: false,
            quota: 0,
            baseQuota: 0,
            toilDays: 0,
            toilDaysLapsed: 0,
          },
        ],
      },
    ],
    workWeekType: 'LOCATION',
    workWeekInfo: [],
    team: {
      id: '',
      name: '',
      approvers: [],
    },
    location: {
      id: '',
      name: '',
      workWeek: [1, 2, 3, 4, 5],
      firstDayOfWeek: 1,
      rolloverNeverExpireDays: false,
      resetQuotas: 'FISCAL_YEAR',
      rolloverExpiryMonth: 0,
      rolloverExpiryDay: 0,
      rolloverExpiryAfterDays: 0,
      leavePolicies: [],
      timezone: '',
    },
    workWeek: [1, 2, 3, 4, 5],
    timestamp: '',
    upcomingLeaves: [],
    pendingLeaves: [],
    history: [],
    today: [],
    labels: [],
    locale: LocaleEnum.en,
    hourFormat: HourFormatEnum.twentyFour,
  })
  const [upcomingLeaves, setUpcomingLeaves] = useState<IUserUpcomingLeaves[]>([])
  const [historyLeaves, setHistoryLeaves] = useState<IUserHistory[]>([])
  const [pendingLeaves, setPendingLeaves] = useState<IUserPendingLeaves[]>([])
  const [currentLeaves, setCurrentLeaves] = useState<IUserToday[]>([])
  const [activeTab, setActiveTab] = useState(() => {
    const activeTab = location.search.replace('?', '')
    if (activeTab && (activeTab === 'profile' || activeTab === 'leaves' || activeTab === 'logs')) {
      return activeTab
    }

    return 'profile'
  })
  const [workWeek, setWorkWeek] = useState<number[]>([1, 2, 3, 4 ,5])
  const [teams, setTeams] = useState<IGetTeamsShort[]>([])
  const [locations, setLocations] = useState<IGetLocationsShort[]>([])
  const [visibleLeaveForm, setVisibleLeaveForm] = useState(false)
  const [createLeaveLoader, setCreateLeaveLoader] = useState(false)
  const [userLabels, setUserLabels] = useState<ILabel[]>([])
  const [allLabels, setAllLabels] = useState<ILabel[]>([])
  const shouldEnableFeatures = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.labels)
  const [ editProfileModalVisible, showEditProfileModal ] = useState(false)
  const [connectedGoogleCalendar, setConnectedGoogleCalendar] = useState<IConnectedGoogleCalendar | null>(null)
  const [connectedOutlookCalendar, setConnectedOutlookCalendar] = useState<IConnectedOutlookCalendar | null>(null)
  const [isResendingLeaveRequest, setIsResendingLeaveRequest] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [probationPeriod, setProbationPeriod] = useState<IUserProbationPeriod>()

  const [ getUserByIdQuery ] = useManualQuery<IGetUserById, {
    id: string
    date: string
    pendingLeavesDate: string
  }>(getUserById)
  const [ getConnectedCalendarsQuery ] = useManualQuery<IGetConnectedCalendarsResponse, {
    userId: string
  }>(getConnectedCalendars)

  const approverCondition = (): boolean => {
    return amIApprover && authUser.id !== match.params.id && !authUser.approverTo.find(user => user.id === match.params.id)
  }
  const [getTeamsForApproverQuery] = useManualQuery<GetTeamsForApproverData, {
    id: string
    date: string
  }>(getTeamsForApprover)
  const [getAutomationsQuery] = useManualQuery<IGetAutomations>(getAutomations)
  const [getSuggestedSubstituteApproversQuery] = useManualQuery<IGetUsersForAdminAddLeaveData>(getSuggestedSubstituteApprovers)
  const {
    setCheckIfSubstituteNeeded,
    suggestedSubstituteApprovers,
    shouldShowSubstituteApprovers,
    isSubstituteListLoading,
  } = useSubstituteApprover(
    {
      userId: user.id,
      startDate,
      endDate,
    }, 
    {
      getTeamsForApproverQuery,
      getAutomationsQuery,
      getSuggestedSubstituteApproversQuery,
    }
  )
  useEffect(() => {
    if (approverCondition()) {
      return
    }
    fetchUser(match.params.id)
  }, [ match.params.id, actionNotifications ])

  useEffect(() => {
    if (leaveRequestActionEvent) {
      if (approverCondition()) {
        return
      }
      fetchUser(match.params.id)
      dispatch(setLeaveRequestActionEvent(null))
    }
  }, [leaveRequestActionEvent])

  if (approverCondition()) {
    return <NotFoundPage />
  }

  const handleOnEndDateSelected = (dateRange) => {
    const {startDate, endDate} = dateRange
    setStartDate(startDate)
    setEndDate(endDate)
    if(shouldEnableSubstituteApprovers) {
      setCheckIfSubstituteNeeded(true)
    }
  }

  const fetchUser = async (id: string) => {
    try {
      const response = await getUserByIdQuery({ variables: {
        id,
        date: dayjs().format('YYYY-MM-DD'),
        pendingLeavesDate: dayjs().subtract(2, 'years').startOf('year').format('YYYY-MM-DD'),
      }})
      if (!response.data || response.error) throw response.error

      const calendarStatusResponse = await getConnectedCalendarsQuery({ variables: { userId: id }})
      if (calendarStatusResponse.data?.getConnectedGooogleCalendar?.calendarId) {
        setConnectedGoogleCalendar(calendarStatusResponse.data.getConnectedGooogleCalendar)
      }
      if (calendarStatusResponse.data?.getConnectedOutlookCalendar?.calendarId) {
        setConnectedOutlookCalendar(calendarStatusResponse.data.getConnectedOutlookCalendar)
      }

      const user = response.data.getUser
      setUser(user)
      
      setUserLabels(user.labels || [])
      setUpcomingLeaves(user.upcomingLeaves.sort(
        (a: IUserUpcomingLeaves, b: IUserUpcomingLeaves) => a.startDate > b.startDate ? 1 : -1
      ))
      setCurrentLeaves(user.today)
      setHistoryLeaves(user.history.filter(leave => leave.status !== 'OPEN').sort(
        (a: IUserHistory, b:IUserHistory) => a.startDate > b.startDate ? -1 : 1
      ))

      const probationPeriod = response.data.getUser?.probationPeriod?.sort((a, b) => a.lengthDays > b.lengthDays ? -1 : 1)[0]
      if (probationPeriod) {
        const leaveTypes = user.location.leavePolicies.map(leavePolicy => leavePolicy.leaveType)

        const userProbationLeaveTypes = probationPeriod.leaveTypeIds
          .map(leaveTypeId => leaveTypes.find(leaveType => leaveType.id === leaveTypeId)?.name)
          .filter(Boolean)

        if (userProbationLeaveTypes.length > 0) {
          setProbationPeriod({
            ...probationPeriod,
            leaveTypeNames: userProbationLeaveTypes.length === 1 ? userProbationLeaveTypes[0] as string : userProbationLeaveTypes.join(', '),
          })
        }
      }

      setWorkWeek(user.workWeek)

      setPendingLeaves(sortBy(user.pendingLeaves, ['startDate']))

      setLocations(response.data.getLocationList)
      setTeams(response.data.getTeamList)

      setIsUserEndDateEnabled(Boolean(response.data.getCompany.userEndDate))

      setAllLabels(response.data.getLabels)
      setIsLoading(false)
      setIsResendingLeaveRequest(false)
    } catch (err) {
      logger.error('error fetching user by id', err)
    }
  }

  const onUpdateUser = async (data) => {
    try {
      if(!user) {
        throw new Error('Missing user')
      }
      const correlationIds: string[] = []

      if (data.changeTeam && data.teamId !== user?.team.id) {
        const response = await Api.post('/core/event', {
          eventType: 'TEAM_USER_MOVED',
          eventGroup: 'USER',
          teamId: data.teamId,
          userId: user?.id,
        })
        correlationIds.push(response.correlationId as string)
        const teamName = teams.find(t => t.id === data.teamId)?.name
        notification.open({
          key: response.correlationId,
          message: formatMessage({ id: 'user.team.moveInProgress' }, { name: teamName }),
          icon: (<LoadingOutlined />),
          duration: 0,
        })
      }

      if (data.changeLocation && data.locationId !== user.location.id) {
        const response = await Api.post('/core/event', {
          eventType: 'LOCATION_USER_MOVED',
          eventGroup: 'USER',
          locationId: data.locationId,
          userId: user.id,
        })
        correlationIds.push(response.correlationId as string)
        const locationName = locations.find(l => l.id === data.locationId)?.name
        notification.open({
          key: response.correlationId,
          message: formatMessage({ id: 'user.location.moveInProgress' }, { name: locationName }),
          icon: (<LoadingOutlined />),
          duration: 0,
        })
      }

      const event: IUserUpdatedEvent = {
        eventType: 'USER_UPDATED',
        eventGroup: 'USER',
        userId: user.id,
        name: data.name,
        email: user.email,
        platform: user.platform,
        startDate: data.startDate,
        endDate: data.endDate,
        employeeId: data.employeeId,
        isAdmin: data.isAdmin,
        isNameLocked: user.isNameLocked ? true : data.changeName,
        imageUrl: data.imageUrl ?? user.imageUrl ?? undefined,
        ...(data.changeStatus && data.status !== user.status && {status: data.status}),
      }

      const apiResponse = await Api.post('/core/event', event)
      correlationIds.push(apiResponse.correlationId as string)
      notification.open({
        key: apiResponse.correlationId,
        message: formatMessage({ id: 'user.updateInProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })

      setUser({
        ...user,
      })
      setActionNotifications([ ...actionNotifications, ...correlationIds ])
      setVisibleUserUpdateForm(false)
    } catch (error) {
      showErrorNotification(error)
    }
  }

  const onSaveUserProfileChanges = async (name: string, profilePicture?: RcFile) => {
    const params: Partial<IUserUpdatedEvent> = {
      eventType: 'USER_UPDATED',
      eventGroup: 'USER',
      userId: user.id,
      name,
    }

    if (profilePicture) {
      const url = await uploadAvatar(profilePicture)
      params.imageUrl = url
    }

    const response = await Api.post('/core/event', params)
    if (response.correlationId) {
      setActionNotifications([...actionNotifications, response.correlationId])
    }
    showEditProfileModal(false)
    setVisibleUserUpdateForm(false)
  }

  const handleTabChange = (event) => {
    if(event.key !== 'link') {
      setActiveTab(event.key as string)
    }
  }

  const deleteLeave = async (leaveRequestId: string) => {
    try {
      if (!user) {
        throw new Error('Missing user')
      }
      const isToil = isToilLeave(leaveRequestId)

      const body = {
        eventType: isToil ? 'TOIL_REQUEST_DELETED' : 'LEAVE_REQUEST_DELETED',
        eventGroup: isToil ? 'USER_TOIL_REQUEST' : 'USER_LEAVE_REQUEST',
        userId: user.id,
      }
      isToil ? body['toilRequestId'] = leaveRequestId : body['leaveRequestId'] = leaveRequestId

      const response = await Api.post('/core/event', body)
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: isToil ? 'components.toil.deleteInProgress' : 'user.deleteLeaveInProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...(actionNotifications ?? []), response.correlationId ])
    } catch (error) {
      showErrorNotification(error)
    }
  }

  const cancelLeave = async (leaveRequestId: string) => {
    try {
      if (!user) {
        throw new Error('Missing user')
      }

      const isToil = isToilLeave(leaveRequestId)
      const body = {
        eventType: isToil ? 'TOIL_REQUEST_CANCELLED' : 'LEAVE_REQUEST_CANCELLED',
        eventGroup: isToil ? 'USER_TOIL_REQUEST' : 'USER_LEAVE_REQUEST',
        userId: user.id,
      }
      isToil ? body['toilRequestId'] = leaveRequestId : body['leaveRequestId'] = leaveRequestId

      const response = await Api.post('/core/event', body)
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: isToil ? 'components.toil.cancelInProgress' : 'user.cancelLeaveRequestInProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...(actionNotifications ?? []), response.correlationId ])
    } catch (error) {
      showErrorNotification(error)
    }
  }

  const handleUpdateWorkweek = async (days) => {
    try {
      if (!user) {
        throw new Error('Missing user')
      }

      const response = await Api.post('/core/event', {
        eventType: 'USER_WORK_WEEK_UPDATED',
        eventGroup: 'USER',
        userId: user.id,
        days,
      })
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'user.workWeek.updateInProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...actionNotifications, response.correlationId ])
    } catch (error) {
      showErrorNotification(error)
    }
  }

  const renderTabBar = () => {
    return <></>
  }

  const onUpdateLeaveRequest = async (leaveRequestId: string, leaveStatus, statusReason?: string) => {
    try {
      if (!user) {
        throw new Error('Missing user')
      }

      let eventGroup: string, eventType: string, messageId: string
      const isToil = isToilLeave(leaveRequestId)
      if (isToil) {
        eventGroup = 'USER_TOIL_REQUEST'
        eventType = leaveStatus ? 'TOIL_REQUEST_APPROVED' : 'TOIL_REQUEST_DENIED'
        messageId = leaveStatus ? 'components.toil.approveInProgress' : 'components.toil.denyInProgress'
      } else {
        eventGroup = 'USER_LEAVE_REQUEST'
        eventType = leaveStatus ? 'LEAVE_REQUEST_APPROVED' : 'LEAVE_REQUEST_DENIED'
        messageId = leaveStatus ? 'user.approveInProgress' : 'user.denyInProgress'
      }

      const body = { eventType, eventGroup, userId: user.id, statusReason }
      isToil ? body['toilRequestId'] = leaveRequestId : body['leaveRequestId'] = leaveRequestId

      const response = await Api.post('/core/event', body)
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: messageId }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...(actionNotifications ?? []), response.correlationId ])
    } catch (error) {
      logger.warning(error)
      showErrorNotification(error)
    }
  }

  const showErrorNotification = (error) => {
    const errorDescription = error.response?.data?.message ? error.response?.data.message : error.message ? error.message : JSON.stringify(error)
    notification.error({
      message: formatMessage({ id: 'error.generic' }),
      description: errorDescription,
      duration: 0,
    })
  }

  const handleSubmitLeave = async (data: ILeaveFormSaveData) => {
    setCreateLeaveLoader(true)
    let response
    try {
      if (!user) {
        throw new Error('Missing user')
      }

      response = await Api.post('/core/leave-request-validate', {
        eventType: 'LEAVE_REQUEST_ADDED',
        eventGroup: 'USER_LEAVE_REQUEST',
        ...data,
        userId: user.id,
      })
      response = await Api.post('/core/event', {
        eventType: 'LEAVE_REQUEST_ADDED',
        eventGroup: 'USER_LEAVE_REQUEST',
        ...data,
        userId: user.id,
      })
      setCreateLeaveLoader(false)
      setVisibleLeaveForm(false)

      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'addLeave.inProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...actionNotifications, response.correlationId ])
    } catch (error) {
      setCreateLeaveLoader(false)
      setVisibleLeaveForm(false)
      if (error.response?.data?.error || error.response.data.message) {
        notification.error({
          message: formatMessage({ id: 'error.leaveSubmitError' }),
          description: formatMessage({ id: error.response.data.error }),
          duration: 0,
        })
      } else {
        const description = response?.correlationId ? formatMessage({ id: 'app.correlationIdError' }, { correlationId: response.correlationId }) : JSON.stringify(error)

        notification.error({
          message: formatMessage({ id: 'error.leaveSubmitError' }),
          description,
          duration: 0,
        })
      }
    }
  }

  const onQuotaUpdate = async (updates: IQuotaUpdates | IRolloverQuotaUpdates) => {
    const response = await Api.post('/core/event', updates)

    if (updates.eventType === 'USER_LEAVE_TYPES_UPDATED') {
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'users.updateQuota.InProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...actionNotifications, response.correlationId ])
    } else if (updates.eventType === 'USER_LEAVE_TYPES_ROLLOVER_UPDATED') {
      fetchUser(match.params.id)
      notification.success({
        message: formatMessage({ id: 'users.updateRolloverQuota.successTitle' }),
        duration: 7,
      })
    }
  }

  const handleSelectLabel = async (value) => {
    if (!value.id) {
      const response = await Api.post('/core/event', {
        eventType: 'LABEL_CREATED',
        eventGroup: 'LABEL',
        name: value.name,
        color: value.color,
      })
      value.id = response.labelId
      value.userCount = 1
      setAllLabels(prevState => {
        return [...prevState, value]
      })
    } else {
      setAllLabels(prevState => {
        return prevState.map(state => {
          if (state.id === value.id && state.userCount) {
            state.userCount++
          }
          return state
        })
      })
    }

    setUserLabels(prevState => {
      return [...prevState, {id: value.id, name: value.name, color: value.color}]
    })

    await Api.post('/core/event', {
      eventType: 'LABEL_ASSIGNED_TO_USER',
      eventGroup: 'LABEL',
      labelId: value.id,
      name: value.name,
      userId: user.id,
    })

    setTimeout(() => {
      fetchUser(match.params.id)
    }, 2000)
  }

  const handleDeselectLabel = async (value) => {
    setUserLabels(prevState => {
      return prevState.filter((state => state.id !== value.id))
    })

    if (value?.userCount === 1) {
      setAllLabels(prevState => {
        return prevState.filter((state => state.id !== value.id))
      })
    } else {
      setAllLabels(prevState => {
        return prevState.map(state => {
          if (state.id === value.id && state.userCount) {
            state.userCount--
          }
          return state
        })
      })
    }

    await Api.post('/core/event', {
      eventType: 'LABEL_REMOVED_FROM_USER',
      eventGroup: 'LABEL',
      labelId: value.id,
      userId: user.id,
    })

    setTimeout(() => {
      fetchUser(match.params.id)
    }, 2000)
  }

  const updateLabel = async (value, labelId): Promise<void> => {
    setAllLabels(prevState => {
      return prevState.map(label => {
        if(label.id === labelId) {
          label.name = value.name
          label.color = value.color
        }

        return label
      })
    })
    setUserLabels(prevState => {
      return prevState.map(label => {
        if(label.id === labelId) {
          label.name = value.name
          label.color = value.color
        }

        return label
      })
    })

    await Api.post('/core/event', {
      eventType: 'LABEL_UPDATED',
      eventGroup: 'LABEL',
      labelId,
      name: value.name,
      color: value.color,
    })
  }

  const deleteLabel = async(labelId: string): Promise<void> => {
    await Api.post('/core/event', {
      eventType: 'LABEL_DELETED',
      eventGroup: 'LABEL',
      labelId,
    })

    setAllLabels(prevState => {
      return prevState.filter(label => label.id !== labelId)
    })
    setUserLabels(prevState => {
      return prevState.filter((state => state.id !== labelId))
    })
  }

  const onResendLeaveRequest = async (data: IResendLeaveRequest) => {
    setIsResendingLeaveRequest(true)
    let response
    try {
      const isToilRequest = isToilLeave(data.resentLeaveRequestId as string)
      const type = isToilRequest ? 'TOIL' : 'LEAVE'
      let params = data
      if (isToilRequest) {
        data['resentRequestId'] = data['resentLeaveRequestId']
        params = omit(data, 'resentLeaveRequestId')
      }
      response = await Api.post(`/core/${lowerCase(type)}-request-validate`, {
        eventType: `${type}_REQUEST_CREATED`,
        eventGroup: `USER_${type}_REQUEST`,
        ...params,
        userId: user.id,
      })

      response = await Api.post('/core/event', {
        eventType: `${type}_REQUEST_CREATED`,
        eventGroup: `USER_${type}_REQUEST`,
        ...params,
        userId: user.id,
      })
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: isToilRequest ? 'components.toil.requestToilProgress' : 'requestLeave.inProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...actionNotifications, response.correlationId ])
    } catch (error) {
      setIsResendingLeaveRequest(false)
      logger.warning('error', error)
      if (error?.response?.data?.code === 'BLACKOUT_PERIOD') {
        const data = error?.response?.data?.data
        notification.error({
          message: formatMessage(
            { id: 'automations.blackoutPeriodFromTo' },
            {
              fromDate: dayjs(data.startDate).format('MMMM Do YYYY.'),
              toDate: dayjs(data.endDate).format('MMMM Do YYYY.'),
              endDate: (...chunks) => data.startDate !== data.endDate ? <>{chunks}</> : '',
            }
          ),
          description: error.response.data.message,
          duration: 0,
        })
      } else if (error?.response?.data?.code === 'PROBATION_PERIOD') {
        const data = error?.response?.data?.data
        notification.error({
          message: formatMessage({ id: 'automations.probationPeriodMessages' }, { date: dayjs(data.date as string).format('MMMM Do YYYY.')}),
          description: error.response.data.message,
          duration: 0,
        })
      } else if (error.response?.data?.error || error.response.data.message) {
        notification.error({
          message: formatMessage({ id: 'error.leaveSubmitError' }),
          description: formatMessage({ id: error.response.data.message || error.response?.data?.error }),
          duration: 0,
        })
      } else {
        const description = response?.correlationId ? formatMessage({ id: 'app.correlationIdError' }, { correlationId: response.correlationId }) : JSON.stringify(error)

        notification.error({
          message: formatMessage({ id: 'error.leaveSubmitError' }),
          description,
          duration: 0,
        })
      }
    }
  }

  const shouldEnableEditForEmailPlatformUser = authUser.id === user.id && authUser.platform === 'email'

  const userProfileTabs: TabsProps['items'] = [
    {
      key: 'profile',
      label: <IntlMessages id="user.profile" />,
      children: <UserProfileTab
        amIAdmin={amIAdmin}
        user={{
          id: user.id,
          isAdmin: user.isAdmin,
          email: user.email,
          name: user.name,
          team: user.team,
          platform: user.platform as Platform,
          location: user.location,
          startDate: user.startDate,
          endDate: user.endDate,
          employeeId: user.employeeId,
          role: user.role,
          status: user.status,
          workWeekType: user.workWeekType,
          workWeekInfo: user.workWeekInfo,
          workHours: user.workHours,
          isNameLocked: user.isNameLocked,
          approvers: user.team.approvers ? user.team.approvers.map(approver => approver.name) : [],
          imageUrl: user?.imageUrl,
          locale: locale.locale,
        }}
        workingDays={{
          days: workWeek,
          firstDayOfWeek: Number(user.location.firstDayOfWeek),
        }}
        quotas={user.leaveDays}
        teams={teams}
        locations={locations}
        isUserEndDateEnabled={isUserEndDateEnabled}
        hourlyLeaveAccounting={Boolean(authCompany?.hourlyLeaveAccounting)}
        showUpdateUserForm={visibleUserUpdateForm}
        setShowUpdateUsersForm={setVisibleUserUpdateForm}
        onQuotaUpdate={onQuotaUpdate}
        updateUserProfile={onUpdateUser}
        updateWorkingDays={handleUpdateWorkweek}
        setActiveTab={handleTabChange}
        onEdit={shouldEnableEditForEmailPlatformUser
          ? () => showEditProfileModal(true)
          : () => {return}
        }
      />,
    },
    {
      key: 'leaves',
      label: <IntlMessages id="user.leaves" />,
      children: <UserLeavesTab
        amIAdmin={amIAdmin}
        amIApprover={amIApprover}
        pendingRequests={pendingLeaves}
        currentLeaves={currentLeaves}
        scheduledLeaves={upcomingLeaves}
        leaveHistory={historyLeaves}
        user={{
          id: user.id,
          name: user.name,
          locale: user.locale,
          hourFormat: authUser.hourFormat || HourFormatEnum.twentyFour,
        }}
        onCancelLeave={cancelLeave}
        onDeleteLeave={deleteLeave}
        onLeaveRequestUpdate={onUpdateLeaveRequest}
        onResendLeaveRequest={onResendLeaveRequest}
        isResendingLeaveRequest={isResendingLeaveRequest}
      />,
    },
    {
      key: 'logs',
      label: <IntlMessages id="app.logs" />,
      children: <UserLogsTab
        userId={match.params.id}
        amIAdmin={amIAdmin}
        reload={activeTab}
        locationTimezone={user.location.timezone}
        hourlyLeaveAccounting={Boolean(authCompany?.hourlyLeaveAccounting)}
        hourFormat={authUser.hourFormat || HourFormatEnum.twentyFour}
        workingHours={user.workHours}
      />,
    },
  ]

  return (
    <>
      {isLoading ?
        <CircularProgress /> :
        <>
          <div className="profile-banner">
            {probationPeriod &&
              <Alert
                message={<IntlMessages id="user.probationPeriodDescription"
                  values={{
                    leaveTypeNames: () => <strong>{probationPeriod.leaveTypeNames}</strong>,
                    probationPeriodName: () => <strong>{probationPeriod.name}</strong>,
                    lengthDays: () => <strong>{probationPeriod.lengthDays}</strong>,
                    date: () => <strong>{dayjs(user.startDate).add(probationPeriod.lengthDays, 'days').format('MMMM Do YYYY')}</strong>,
                  }}
                />}
                type="warning"
                style={{ top: -20 }}
              />
            }
            <div className="profile-container">
              <div className="profile-banner-top center-content">
                <Row>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 12 }}>
                    <div className="profile-banner-top-left">
                      <div className="profile-banner-avatar" style={{ alignSelf: 'flex-start' }}>
                        { shouldEnableEditForEmailPlatformUser &&
                          <div
                            className="profile-banner-avatar-overlay"
                            onClick={() => {
                              return amIAdmin ? setVisibleUserUpdateForm(true) : showEditProfileModal(true)}
                            }>
                            <IntlMessages id="app.edit" />
                          </div>
                        }
                        <UserAvatar id={user.id} avatar={user.imageUrl} name={user.name} avatarSize={90} shape="circle" />
                      </div>
                      <div className="profile-banner-avatar-info" style={{ flex: 1 }}>
                        <h2>{user.name}</h2>
                        {shouldEnableFeatures  ?
                          <UserLabels
                            isEditable={Boolean(amIAdmin)}
                            allLabels={allLabels}
                            userLabels={userLabels}
                            handleSelectLabel={handleSelectLabel}
                            handleDeselectLabel={handleDeselectLabel}
                            updateLabel={updateLabel}
                            deleteLabel={deleteLabel}
                          /> :
                          <p>{user.team.name}</p>
                        }
                      </div>
                    </div>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }} lg={{ span: 16 }} xl={{ span: 16 }} xxl={{ span: 12 }}>
                    {currentLeaves.length > 0 &&
                      <UserTodayOff todayLeaves={currentLeaves} />
                    }
                  </Col>
                </Row>
              </div>
              <div className="profile-banner-bottom center-content">
                <Menu
                  onClick={handleTabChange}
                  selectedKeys={[activeTab]}
                  mode="horizontal"
                  items={[
                    {
                      key: 'profile',
                      label: <IntlMessages id="user.profile" />,
                    },
                    {
                      key: 'leaves',
                      label: <>
                        <IntlMessages id="user.leaves" />
                        <Badge style={{marginLeft: 4}} count={pendingLeaves.length} />
                      </>,
                    },
                    {
                      key: 'logs',
                      label: <IntlMessages id="app.logs" />,
                    },
                    {
                      key: 'link',
                      label: <IntlMessages id="app.addLeave" />,
                      onClick: () => { setVisibleLeaveForm(true) },
                      className: 'btn',
                    },
                  ]}
                />
                <Breadcrumb
                  items={[
                    {
                      title: <Link to={FrontendUrls.dashboard}><IntlMessages id="sidebar.dashboard" /></Link>,
                    },
                    {
                      title: <Link to='/app/users'><IntlMessages id="app.users" /></Link>,
                    },
                    {
                      title: user.name,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="user-page center-content">
            <Tabs activeKey={activeTab} renderTabBar={renderTabBar} items={userProfileTabs} />
          </div>
          {visibleLeaveForm &&
            <Modal
              title={<IntlMessages id='app.addLeave' />}
              open={visibleLeaveForm}
              onCancel={() => { setVisibleLeaveForm(false) }}
              footer={false}
            >
              <LeaveForm
                authUserId={authUser.id}
                authUserRole={authUser.role}
                hourFormat={authUser.hourFormat}
                loading={createLeaveLoader}
                listOfUsers={[user]}
                onEndDateSelected={handleOnEndDateSelected}
                onSave={(data: ILeaveFormSaveData) => {
                  (async () => {
                    await handleSubmitLeave(data)
                  })()
                }}
                onCancel={() => { setVisibleLeaveForm(false) }}
                formType={'add-to-user'}
                modalForm={true}
                connectedGoogleCalendar={connectedGoogleCalendar}
                connectedOutlookCalendar={connectedOutlookCalendar}
                shouldShowSubstituteApprovers={shouldShowSubstituteApprovers}
                substituteApprovers={suggestedSubstituteApprovers}
                isSubstituteListLoading={isSubstituteListLoading}
              />
            </Modal>
          }
          {shouldEnableEditForEmailPlatformUser && editProfileModalVisible && <UserMyProfileModal
            name={user.name}
            profilePhoto={user.imageUrl}
            visible={editProfileModalVisible}
            onCancel={() => showEditProfileModal(false)}
            onSave={onSaveUserProfileChanges}
          />}
        </>
      }
    </>
  )
}

export default UserPage
