import React, { useState, CSSProperties } from 'react'
import { App, Button } from 'antd'
import { CommentOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'

import Api from '@vacationtracker/shared/services/api'
import IntlMessages from '../../util/IntlMessages'
import { FeedbackModal } from '@vacationtracker/shared/components/feedback-modal'
import { track } from '../../services/analytics/analytics'

interface IAddOnFeedback {
  page: string
  buttonStyle?: CSSProperties
}

const AddOnFeedback = ({
  page,
  buttonStyle,
}: IAddOnFeedback) => {
  const { formatMessage } = useIntl()
  const [showAddOnContactForm, setShowAddOnContactForm] = useState(false)
  const { notification } = App.useApp()

  const addOnContactForm = () => {
    track('AUTOMATION_ANYTHING_MISSING_CLICKED', {
      buttonLocation: page,
    })
    setShowAddOnContactForm(true)
  }

  const sendAutomationContactForm = async (value) => {
    track('AUTOMATION_ANYTHING_MISSING_SUBMITTED', {
      buttonLocation: page,
    })
    try {
      await Api.post('/core/event', {
        ...value,
        eventGroup: 'FEEDBACK',
        eventType: 'AUTOMATION_FEEDBACK',
      })
      setShowAddOnContactForm(false)
      notification.success({
        message: formatMessage({ id: 'automation.feedback.thankYou' }),
        icon: <CommentOutlined style={{ color: '#7f00ff' }} />,
      })
    } catch (error) {
      const errorDescription = error.response?.data?.message ? error.response?.data.message : error.message ? error.message : JSON.stringify(error)
      notification.error({
        message: formatMessage({ id: 'error.generic' }),
        description: errorDescription,
        duration: 0,
      })
    }
  }

  return (
    <>
      <Button style={buttonStyle} type="link" onClick={addOnContactForm}>
        <IntlMessages id="automations.anythingMissing" />
      </Button>
      <FeedbackModal
        visible={showAddOnContactForm}
        onCancel={() => {setShowAddOnContactForm(false)}}
        onSave={sendAutomationContactForm}
        emailRequired={true}
      />
    </>
  )
}
export default AddOnFeedback
