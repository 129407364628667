/* eslint-disable @typescript-eslint/no-var-requires */
import axios, { AxiosError } from 'axios'
import qs from 'qs'
import jwtDecode from 'jwt-decode'
import { GetGroupsResponseBody, ITokens, IMicrosoftGroup, IMicrosoftList, IUserAndTeam, IMicrosoftUser, IMicrosoftTeam } from '../../../types/microsoft'
import * as MicrosoftGraphApi from '@microsoft/microsoft-graph-types'
import * as Sentry from '@sentry/react'
import { PKCEChallengeGenerator } from '../../../util/openid/pkce-challenge-generator'
import { OpenIdClient } from '../../../util/openid/openid-client'
import { BrowserRuntime } from '../../../util/openid/browser-runtime'
import { IOutlookCalendar } from '@vacationtracker/shared/types/calendar'
import Api from '@vacationtracker/shared/services/api'
import * as logger from '../../../services/logger'

interface IMSWebAppTokensData {
  client_id: string
  response_type: string
  response_mode: string
  scope: string
  refresh_token?: string
  grant_type?: string
}
export class MicrosoftAuth {
  private apiUrl = 'https://graph.microsoft.com/v1.0'
  private clientId: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private openIdClient: any
  private tokens?: ITokens
  private runtime

  constructor(clientId: string) {
    this.clientId = clientId

    const siteConfig = {
      OpenIDUrl: 'https://login.microsoftonline.com/common/oauth2/v2.0',
      OpenIDTargetUrl: `${window.location.origin}/msredirect/index.html`,
      OpenIDClient: this.clientId,
    }
    const runtime = BrowserRuntime()
    this.runtime = runtime
    const pkceChallengeGenerator = new PKCEChallengeGenerator({ runtime })
    this.openIdClient = new OpenIdClient({ runtime, siteConfig, pkceChallengeGenerator })

    // Get tokens from session stogage
    try {
      this.tokens = this.getTokens()
    } catch (err) {
      // Silent error
    }
  }

  setTokens(tokens: ITokens): void {
    this.tokens = tokens
    sessionStorage.setItem('msTokensIdToken', tokens.idToken)
    sessionStorage.setItem('msTokensAccessToken', tokens.accessToken)
    sessionStorage.setItem('msTokensRefreshToken', tokens.refreshToken)
  }

  setWebAppTokens(tokens: Pick<ITokens, 'accessToken' | 'refreshToken'>, expiration: string): void {
    sessionStorage.setItem('msWebAppTokensAccessToken', tokens.accessToken)
    sessionStorage.setItem('msWebAppTokensRefreshToken', tokens.refreshToken)
    sessionStorage.setItem('msWebAppTokensExpiration', expiration)
  }

  getWebAppTokens(): {
    accessToken: string
    refreshToken : string
  } {
    // Get tokens from session stogage
    const accessToken = sessionStorage.getItem('msWebAppTokensAccessToken')
    const refreshToken = sessionStorage.getItem('msWebAppTokensRefreshToken')

    if (accessToken && refreshToken) {
      return {
        accessToken,
        refreshToken,
      }
    }

    throw new Error('no_tokens')
  }

  getTokens(): ITokens {
    // Get tokens from session stogage
    const idToken = sessionStorage.getItem('msTokensIdToken')
    const accessToken = sessionStorage.getItem('msTokensAccessToken')
    const refreshToken = sessionStorage.getItem('msTokensRefreshToken')

    if (idToken && accessToken && refreshToken) {
      this.tokens = {
        idToken,
        accessToken,
        refreshToken,
      }
      return this.tokens
    }

    throw new Error('no_tokens')
  }

  removeTokens() {
    sessionStorage.clear()
    this.tokens = undefined
  }

  signin(scopes: string[], interactionRequired?: boolean) {
    const additionalParams = interactionRequired ? {prompt: 'consent'} : {}
    return this.openIdClient.authorise('authorize', scopes, additionalParams)
      .then(tokens => {
        // Store tokens to sessionStorage
        this.setTokens(tokens)
        return Promise.all([
          tokens,
          this.getUser(),
          this.getTenantId(),
        ])
      })
  }

  getMSWebAppTokens(tenantId: string, expiration: string, scope = 'offline_access user.read', refreshToken?: string | boolean): Promise<ITokens> {
    const data: IMSWebAppTokensData = {
      client_id: this.clientId,
      response_type: 'code',
      response_mode: 'query',
      scope,
    }
    if (refreshToken) {
      data.refresh_token = 'refresh_token'
      data.grant_type = 'refresh_token'
    }
    function encodeData(data) {
      return Object.keys(data).map(function(key) {
        return [key, data[key]].map(encodeURIComponent).join('=')
      }).join('&')
    }
    const params = encodeData(data)
    // eslint-disable-next-line max-len
    const url = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize?redirect_uri=${window.location.origin}/mswebappredirect/index.html&` + params
    // 1. open login
    return this.runtime.openAndWaitForMessage(url, 'openid')
      .then(response => {
        const parsed = qs.parse(response.slice(1))
        return {
          code: parsed.code,
          redirectUrl: `${window.location.origin}/mswebappredirect/index.html`,
        }
      })
      .then(res => {
        return Api.post('/microsoft/get-web-token/', {
          code: res.code,
          redirectUrl: res.redirectUrl,
        })
      })
      .then(msTokens => {
        const tokens = {
          accessToken: msTokens.access_token,
          refreshToken: msTokens.refresh_token,
        }
        this.setWebAppTokens(tokens, expiration)
        return tokens
      })
      .catch(e => {
        logger.error(e)
        throw new Error('FAILED CODE OR TOKEN FETCH')
      })
  }

  refreshTokens(): Promise<ITokens> {
    const refreshToken = sessionStorage.getItem('msTokensRefreshToken')
    return this.openIdClient.refreshTokens(refreshToken)
      .then((tokens: ITokens) => {
        // Store tokens to sessionStorage
        this.setTokens(tokens)
        return tokens
      })
  }

  getTenantId(): string {
    try {
      if (!this.tokens?.accessToken) {
        throw new Error('No access token')
      }
      const decoded = jwtDecode<{tid: string}>(this.tokens?.accessToken)
      return decoded.tid
    } catch(err) {
      return 'common'
    }
  }

  getPermissions(accessToken?: string): string {
    let token
    if (!accessToken) {
      token = this.tokens?.accessToken
    } else {
      token = accessToken
    }
    try {
      if (!token) {
        throw new Error('No access token')
      }
      const decoded = jwtDecode<{scp: string}>(token)
      return decoded.scp
    } catch(err) {
      return 'common'
    }
  }

  async getGroups(token: string): Promise<MicrosoftGraphApi.Group[] | undefined> {
    try {
      const groupsResponse = await axios.get<GetGroupsResponseBody>(`${this.apiUrl}/groups?$select=id,displayName`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return groupsResponse.data.value
    } catch(err) {
      return this.handleError<MicrosoftGraphApi.Group[] | undefined>(err, () => this.getGroups(this.tokens?.accessToken || token))
    }
  }

  async getJoinedTeams(token: string): Promise<IMicrosoftGroup[]> {
    try {
      const teams = await axios.get <IMicrosoftList<IMicrosoftGroup>>(`${this.apiUrl}/me/joinedTeams`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      return (teams.data && teams.data.value) || []
    } catch(err) {
      return this.handleError<IMicrosoftGroup[]>(err, () => this.getJoinedTeams(this.tokens?.accessToken || token))
    }
  }

  getWebAppTokensForOutlook(scope: string): Promise<ITokens> {
    const data = {
      client_id: this.clientId,
      response_type: 'code',
      response_mode: 'query',
      scope,
    }
    function encodeData(data) {
      return Object.keys(data).map(function(key) {
        return [key, data[key]].map(encodeURIComponent).join('=')
      }).join('&')
    }
    const params = encodeData(data)
    // eslint-disable-next-line max-len
    const url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?redirect_uri=${window.location.origin}/mswebappredirect/index.html&` + params
    // 1. open login
    return this.runtime.openAndWaitForMessage(url, 'openid')
      .then(response => {
        const parsed = qs.parse(response.slice(1))
        return {
          code: parsed.code,
          redirectUrl: `${window.location.origin}/mswebappredirect/index.html`,
          scope,
        }
      })
      .then(res => {
        return Api.post('/microsoft/get-web-token/', {
          code: res.code,
          redirectUrl: res.redirectUrl,
          scope: res.scope,
        })
      })
      .then(msTokens => {
        const tokens = {
          accessToken: msTokens.access_token,
          refreshToken: msTokens.refresh_token,
        }
        return tokens
      })
      .catch(e => {
        logger.error(e)
        throw new Error('FAILED CODE OR TOKEN FETCH')
      })
  }

  async getUserOutlookTimezone(accessToken: string): Promise<string> {
    try {
      const user = await axios.get(`${this.apiUrl}/me/mailboxSettings/timeZone`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      return  user.data.value
    } catch(err) {
      return this.handleError<string>(err, () => this.getUserOutlookTimezone(accessToken))
    }
  }

  async getCalendars(accessToken: string, nextLink?: string): Promise<IOutlookCalendar[]> {
    try {
      const url = nextLink ? nextLink : `${this.apiUrl}/me/calendars?$top=50`
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      const nextLinkUrl: string | undefined = (response?.data['@odata.nextLink']) as string || undefined
      const data = response.data.value as IOutlookCalendar[]
      if (nextLinkUrl) {
        const nextPageData = await this.getCalendars(accessToken, nextLinkUrl)
        return data.concat(nextPageData)
      }
      return data
    } catch(err) {
      return this.handleError<IOutlookCalendar[]>(err, () => this.getCalendars(accessToken))
    }
  }

  async getUser(): Promise<IMicrosoftUser> {
    try {
      const user = await axios.get<MicrosoftGraphApi.User>(`${this.apiUrl}/me`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.tokens?.accessToken}`,
        },
      })

      return user.data as IMicrosoftUser
    } catch(err) {
      return this.handleError<IMicrosoftUser>(err, () => this.getUser())
    }
  }

  async getUserAndTeam(token: string): Promise<IUserAndTeam> {
    try {
      const [user, teams] = await Promise.all([
        await axios.get<MicrosoftGraphApi.User>(`${this.apiUrl}/me`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }),
        await axios.get<IMicrosoftList<MicrosoftGraphApi.Team>>(`${this.apiUrl}/me/joinedTeams`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }),
      ])

      return {
        user: user.data as IMicrosoftUser,
        teams: (teams.data && teams.data.value as IMicrosoftTeam[]) || [],
      }
    } catch(err) {
      return this.handleError<IUserAndTeam>(err, () => this.getUserAndTeam(this.tokens?.accessToken || token))
    }
  }

  async getUsers(token: string, teamId?: string | null): Promise<IMicrosoftUser[]> {
    try {
      const url = teamId ? `${this.apiUrl}/groups/${teamId}/members` : `${this.apiUrl}/users`
      const pagedUrl = url + '?$select=displayName,givenName,userPrincipalName,assignedLicenses,surname,id,mail&$top=900'

      return this.getNextUserPages(pagedUrl, token)
    } catch(err) {
      return this.handleError<IMicrosoftUser[]>(err, () => this.getUsers(this.tokens?.accessToken || token, teamId))
    }
  }

  async getNextUserPages(url: string, token: string): Promise<IMicrosoftUser[]> {
    const users = await axios.get<IMicrosoftList<MicrosoftGraphApi.User>>(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })

    const currentPage = users.data ? users.data.value as IMicrosoftUser[] : []

    if (!users.data['@odata.nextLink']) {
      return currentPage
    }

    const nextPage = await this.getNextUserPages(users.data['@odata.nextLink'], token)
    return currentPage.concat(nextPage)
  }

  async getUserListPagination(token: string, nextPage?: string, limit = 200): Promise<{
    members: IMicrosoftUser[]
    nextPage?: string
  }> {
    try {
      const url = nextPage ? nextPage : `${this.apiUrl}/users?$select=displayName,givenName,userPrincipalName,assignedLicenses,surname,id,mail&$orderbydisplayName&$top=${limit}`

      const users = await axios.get<IMicrosoftList<MicrosoftGraphApi.User>>(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })

      return {
        members: users.data ? users.data.value as IMicrosoftUser[] : [],
        nextPage: users.data['@odata.nextLink'],
      }

    } catch(err) {
      return this.handleError<{
        members: IMicrosoftUser[]
        nextPage?: string
      }>(err, () => this.getUserListPagination(this.tokens?.accessToken || token, nextPage))
    }
  }

  async searchUserByEmailAndDisplayname(search: string, token: string, teamId: string | null): Promise<IMicrosoftUser[]> {
    try {
      const url = teamId ? `${this.apiUrl}/groups/${teamId}/members/microsoft.graph.user` : `${this.apiUrl}/users`  
      const queryParams = `?$search="displayName:${search}" OR "mail:${search}" OR "givenName:${search}" OR "surname:${search}"&$orderbydisplayName&$count=true`

      const users = await axios.get<IMicrosoftList<MicrosoftGraphApi.User>>(`${url}${queryParams}`, {
        headers: {
          'Content-Type': 'application/json',
          'ConsistencyLevel': 'eventual',
          Authorization: `Bearer ${token}`,
        },
      })

      return users.data ? users.data.value as IMicrosoftUser[] : []

    } catch(err) {
      return this.handleError<IMicrosoftUser[]>(err, () => this.searchUserByEmailAndDisplayname(search, this.tokens?.accessToken || token, teamId))
    }
  }

  async getTotalUsers(token: string, teamId: string | null = null): Promise<number> {
    try {
      const url = teamId ? `${this.apiUrl}/groups/${teamId}/members/$count` : `${this.apiUrl}/users/$count`
      const totalUsers = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'ConsistencyLevel': 'eventual',
          Authorization: `Bearer ${token}`,
        },
      })

      return totalUsers.data

    } catch(err) {
      return this.handleError<number>(err, () => this.getTotalUsers(this.tokens?.accessToken || token, teamId))
    }
  }

  async getChannels(teamId: string): Promise<MicrosoftGraphApi.Channel[]> {
    try {
      const url = `${this.apiUrl}/teams/${teamId}/channels`
      const channels = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.tokens?.accessToken}`,
        },
      })

      return channels.data && channels.data.value
    } catch(err) {
      return this.handleError<MicrosoftGraphApi.Channel[]>(err, () => this.getChannels(teamId))
    }
  }

  async installBot(teamId: string): Promise<MicrosoftGraphApi.TeamsAppInstallation> {
    try {
      const url = `${this.apiUrl}/teams/${teamId}/installedApps`
      const apps = await axios.post(url, {
        'teamsApp@odata.bind': `https://graph.microsoft.com/v1.0/appCatalogs/teamsApps/${process.env.REACT_APP_MICROSOFT_CLIENT_ID}`,
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.tokens?.accessToken}`,
        },
      })

      return apps.data
    } catch (err) {
      return this.handleError<MicrosoftGraphApi.TeamsAppInstallation>(err, () => this.installBot(teamId))
    }
  }

  async installTabs(teamId: string, channelId: string, name = 'Dashboard' ): Promise<MicrosoftGraphApi.TeamsAppInstallation> {
    try {
      const url = `${this.apiUrl}/teams/${teamId}/channels/${channelId}/tabs`
      const apps = await axios.post(url, {
        'displayName': `Vacation Tracker ${name}`,
        'teamsApp@odata.bind': `https://graph.microsoft.com/v1.0/appCatalogs/teamsApps/${process.env.REACT_APP_MICROSOFT_CLIENT_ID}`,
        'configuration': {
          'contentUrl': `https://ms-tabs.app.vacationtracker.io/tabs/${name.toLowerCase()}`,
          'websiteUrl': 'https://vacationtracker.io',
        },
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.tokens?.accessToken}`,
        },
      })

      return apps.data
    } catch (err) {
      return this.handleError<MicrosoftGraphApi.TeamsAppInstallation>(err, () => this.installBot(teamId))
    }
  }

  private async handleError<T>(err: AxiosError<any>, cb: () => Promise<T>): Promise<T> {
    if (err.response?.status === 401 && err.response?.data?.error?.message === 'Access token has expired.') {
      const tokens = await this.openIdClient.refreshTokens(this.tokens?.refreshToken)
      this.setTokens(tokens)
      return await cb()
    }
    Sentry.captureException(err)
    throw err.response
  }
}