import React, { useState } from 'react'
import { useIntl, WithIntlProps } from 'react-intl'
import { Modal, Form, InputNumber, Radio, Typography, Tooltip, Divider, Alert } from 'antd'
import { CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
require('dayjs/locale/fr')
dayjs.extend(utc)

const { Paragraph, Text } = Typography

import { getDaysOrHours, setDaysOrHours } from '../../functions/get-days-or-hours'
import { getLeaveDaysNumber } from '../utils/leave-quotas'
import { isFullDayInterval } from '../utils/interval'
import { calculateProratedQuota } from '../../functions/calculation-shared'
import { displayLeaveInDaysAndHours } from '../display-leave-in-days-and-hours'
import { AccrualTypeEnum } from '@vacationtracker/shared/types/leave-policy'

import { IMyProfileLeaveType, IUserLeaveTypeResult } from '../../../frontend/src/types/custom-queries'
import { LocaleEnum } from '../../types/i18n'
import { isBefore } from 'date-fns'
import { WorkingHours } from '../../types/work-week'
import { getUserWorkingHoursPerDay } from '../../functions/work-week'

interface IUserLeaveTypeForm {
  onSave: (e) => void
  handleCancel: (e) => void
  visible: boolean
  leaveType: IUserLeaveTypeResult | IMyProfileLeaveType
  dateRange: {
    name: string
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  IntlMessages: React.FC<WithIntlProps<any>> & { WrappedComponent: React.ComponentType<any> }
  hourlyLeaveAccounting: boolean
  userStartDate?: string
  locationResetQuotas?: string
  userName?: string
  workingHours?: WorkingHours
  period?: {
    start: string
    end: string
  }
  locale?: LocaleEnum
  isInitialPeriod: boolean
}

const UserLeaveTypeForm = ({
  onSave,
  handleCancel,
  visible,
  leaveType,
  dateRange,
  IntlMessages,
  hourlyLeaveAccounting,
  userStartDate,
  locationResetQuotas,
  userName,
  workingHours,
  period,
  locale,
  isInitialPeriod,
}: IUserLeaveTypeForm): React.ReactElement => {
  const [form] = Form.useForm()
  const { formatMessage } = useIntl()
  const [hasUnlimitedDays, setUnlimitedDays] = useState(leaveType.hasUnlimitedDays || false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [showQuotaAdditionalSummary, setShowQuotaAdditionalSummary] = useState(false)
  const workingHoursInDay = getUserWorkingHoursPerDay(workingHours)
  const [defaultDaysPerYear, setDefaultDaysPerYear] = useState(getDaysOrHours(leaveType.defaultDaysPerYear, hourlyLeaveAccounting, workingHoursInDay))
  const [seniorityEntitlementDaysPerYear, setSeniorityEntitlementDaysPerYear] = 
    useState(getDaysOrHours(leaveType.seniorityEntitlementDaysForYear, hourlyLeaveAccounting, workingHoursInDay))
  const [entitlementByRoleDaysForYear, setEntitlementByRoleDaysForYear] = useState(getDaysOrHours(leaveType.entitlementByRoleDaysForYear, hourlyLeaveAccounting, workingHoursInDay))
  const [shouldProrate, setShouldProrate] = useState(leaveType.shouldProrate)
  const [applyToFutureYears, setApplyToFutureYears] = useState(false)
  const [disableSeniorityEntitlementOverrideInFuture] = useState(dayjs(period?.start).isAfter(dayjs()))
  const defaultValue = {
    defaultDaysPerYear: getDaysOrHours(leaveType.defaultDaysPerYear, hourlyLeaveAccounting, workingHoursInDay),
    seniorityEntitlementDaysForYear: getDaysOrHours(leaveType.seniorityEntitlementDaysForYear, hourlyLeaveAccounting, workingHoursInDay),
    entitlementByRoleDaysForYear: getDaysOrHours(leaveType.entitlementByRoleDaysForYear, hourlyLeaveAccounting, workingHoursInDay),
    hasUnlimitedDays: leaveType.hasUnlimitedDays,
    applyToFutureYears,
    shouldProrate: leaveType.shouldProrate,
    toilDays: getDaysOrHours(leaveType.toilDays - leaveType.toilDaysLapsed, hourlyLeaveAccounting, workingHoursInDay),
  }

  const onFinish = async () => {
    setConfirmLoading(true)
    try {
      const values = await form.validateFields()
      if (values.hasUnlimitedDays) {
        values.defaultDaysPerYear = leaveType.defaultDaysPerYear
      }
      onSave({
        leaveTypeId: leaveType.leaveTypeId,
        ignoreRolloverFromPreviousYear: leaveType.ignoreRolloverFromPreviousYear,
        ...values,
        defaultDaysPerYear: setDaysOrHours(values.defaultDaysPerYear, hourlyLeaveAccounting, workingHoursInDay),
        seniorityEntitlementDaysForYear: setDaysOrHours(values.seniorityEntitlementDaysForYear, hourlyLeaveAccounting, workingHoursInDay),
        entitlementByRoleDaysForYear: setDaysOrHours(values.entitlementByRoleDaysForYear, hourlyLeaveAccounting, workingHoursInDay),
      })
      setConfirmLoading(false)
    } catch(err) {
      setConfirmLoading(false)
    }
  }

  const onChangeShouldProrate = () => {
    setShouldProrate(!shouldProrate)
    setShowQuotaAdditionalSummary(true)
  }

  const onChangeDaysPerYear = (days: number) => {
    const quotaDays = getLeaveDaysNumber(leaveType.accrualType, leaveType.allowHalfDays, leaveType.shortestLeaveInterval, days, hourlyLeaveAccounting)

    // if we track leaves in hours then we need to prevent decimal values
    const value = hourlyLeaveAccounting ? Math.trunc(quotaDays) : quotaDays

    form.setFieldsValue({
      defaultDaysPerYear: value,
    })
    setShowQuotaAdditionalSummary(true)
    setDefaultDaysPerYear(quotaDays)
  }
  
  const onChangeSeniorityEntitlementDaysPerYear = (days: number) => {
    const quotaDays = getLeaveDaysNumber(leaveType.accrualType, leaveType.allowHalfDays, leaveType.shortestLeaveInterval, days, hourlyLeaveAccounting)

    // if we track leaves in hours then we need to prevent decimal values
    const value = hourlyLeaveAccounting ? Math.trunc(quotaDays) : quotaDays

    form.setFieldsValue({
      seniorityEntitlementDaysForYear: value,
    })
    setShowQuotaAdditionalSummary(true)
    setSeniorityEntitlementDaysPerYear(quotaDays)
  }
  
  const onChangeEntitlementByRoleDaysPerYear = (days: number) => {
    const quotaDays = getLeaveDaysNumber(leaveType.accrualType, leaveType.allowHalfDays, leaveType.shortestLeaveInterval, days, hourlyLeaveAccounting)

    // if we track leaves in hours then we need to prevent decimal values
    const value = hourlyLeaveAccounting ? Math.trunc(quotaDays) : quotaDays

    form.setFieldsValue({
      entitlementByRoleDaysForYear: value,
    })
    setShowQuotaAdditionalSummary(true)
    setEntitlementByRoleDaysForYear(quotaDays)
  }

  const onChangeApplyToFutureYears = () => {
    setApplyToFutureYears(!applyToFutureYears)
    setShowQuotaAdditionalSummary(true)
  }

  const onChangeUnlimitedDays = (e) => {
    setUnlimitedDays(e.target.value)
    setShowQuotaAdditionalSummary(true)
  }

  const formItemLayout = {
    labelCol: { span: 18 },
    wrapperCol: { span: 6 },
  }

  const getProratedPreciseQuota = (convert = true) => {
    const calculated = calculateProratedQuota({
      defaultDaysPerYear,
      yearStart: dayjs(period?.start).utc().toDate(),
      userStartDate: dayjs(userStartDate).utc().toDate(),
    })

    return {
      proratedQuota: displayLeaveInDaysAndHours({
        doNotConvertToHours: !convert,
        value: calculated,
        formatMessage,
        numberOfWorkingHoursPerDay: getUserWorkingHoursPerDay(workingHours),
      }),
      preciseQuota: calculated,
    }
  }

  return (
    <Modal
      title={<IntlMessages id="components.userLeaveTypeForm.updateUserLeaveType" values={{ name: leaveType.leaveTypeName }}  />}
      open={visible}
      closeIcon={<CloseCircleOutlined />}
      confirmLoading={confirmLoading}
      okText={<IntlMessages id="app.save" />}
      onOk={onFinish}
      okButtonProps={{ disabled: !showQuotaAdditionalSummary }}
      width={600}
      cancelText={<IntlMessages id="app.cancel" />}
      onCancel={handleCancel}
    >
      <Form
        key="userUpdateForm"
        form={form}
        name="userUpdateForm"
        layout="horizontal"
        onFinish={onFinish}
        initialValues={defaultValue}
        labelWrap={true}
      >
        <Paragraph>
          <IntlMessages id="components.userLeaveTypeForm.leaveTypeEditInfo" values={{ leaveTypeName: leaveType.leaveTypeName, dateRangeName: dateRange.name}} />
        </Paragraph>
        {leaveType.accrualType === AccrualTypeEnum.none &&
          <Form.Item
            label={<IntlMessages id="components.userLeaveTypeForm.hasUnlimitedDays" />}
            name="hasUnlimitedDays"
            {...formItemLayout}
          >
            <Radio.Group
              options={[
                { label: <IntlMessages id="app.yes" />, value: true },
                { label: <IntlMessages id="app.no" />, value: false },
              ]}
              optionType="button"
              onChange={onChangeUnlimitedDays}
            />
          </Form.Item>
        }

        {(!hasUnlimitedDays || (leaveType.accrualType !== AccrualTypeEnum.none && hasUnlimitedDays)) &&
          <>
            <Form.Item
              label={
                <>
                  <IntlMessages id="components.userLeaveTypeForm.quotaBase" />
                  <Tooltip className='info-tooltip' title={<IntlMessages id="components.userLeaveTypeForm.quotaBaseTooltip" />}><InfoCircleOutlined /></Tooltip>
                </>
              }
              name="defaultDaysPerYear"
              {...formItemLayout}
              rules={[{ required: true, message: <IntlMessages id="error.fieldIsRequired" /> }]}
            >
              <InputNumber
                style={{width: '140px'}}
                step={isFullDayInterval(leaveType) || hourlyLeaveAccounting ? 1 : 0.5}
                min={0}
                max={hourlyLeaveAccounting ? 2920 : 365}
                onChange={(value) => onChangeDaysPerYear(value as number)}
                disabled={hasUnlimitedDays && leaveType.accrualType === AccrualTypeEnum.none}
                addonAfter={<Text><IntlMessages id={`app.${hourlyLeaveAccounting ? 'hours' : 'days'}`}/></Text>}
              />
            </Form.Item>

            {new Date(period?.end as string).getFullYear() >= new Date().getFullYear() && 
              <Form.Item
                label={<IntlMessages id="components.userLeaveTypeForm.applyForFutureYears" />}
                name="applyToFutureYears"
                {...formItemLayout}
              >
                <Radio.Group
                  onChange={onChangeApplyToFutureYears}
                  options={[
                    { label: <IntlMessages id="app.yes" />, value: true },
                    { label: <IntlMessages id="app.no" />, value: false },
                  ]}
                  optionType="button"
                />
              </Form.Item>
            }
            <>
              <Form.Item
                label={
                  <>
                    <IntlMessages id="components.userLeaveTypeForm.quotaAdditionalSeniority" />
                    <Tooltip className='info-tooltip' 
                      title={
                        <IntlMessages 
                          id={disableSeniorityEntitlementOverrideInFuture ? 
                            'components.userLeaveTypeForm.quotaAdditionalSeniorityInfoUpcomingYear' : 
                            'components.userLeaveTypeForm.quotaAdditionalSeniorityInfo'} 
                          values={{ leaveTypeName: leaveType.leaveTypeName }}
                        />
                      }
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </>
                }
                name="seniorityEntitlementDaysForYear"
                {...formItemLayout}
              >
                <InputNumber
                  style={{width: '140px'}}
                  step={isFullDayInterval(leaveType) || hourlyLeaveAccounting ? 1 : 0.5}
                  min={0}
                  max={hourlyLeaveAccounting ? 2920 : 365}
                  onChange={(value) => onChangeSeniorityEntitlementDaysPerYear(value as number)}
                  addonAfter={<Text><IntlMessages id={`app.${hourlyLeaveAccounting ? 'hours' : 'days'}`}/></Text>}
                  disabled={!leaveType.senioirtyEntitlementExists || disableSeniorityEntitlementOverrideInFuture}
                />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <IntlMessages id="components.userLeaveTypeForm.quotaAdditionalRole" />
                    <Tooltip className='info-tooltip' title={<IntlMessages id="components.userLeaveTypeForm.quotaAdditionalRoleInfo" />}><InfoCircleOutlined /></Tooltip>
                  </>
                }
                name="entitlementByRoleDaysForYear"
                {...formItemLayout}
                rules={[{ required: false, message: <IntlMessages id="error.fieldIsRequired" /> }]}
              >
                <InputNumber
                  style={{width: '140px'}}
                  step={isFullDayInterval(leaveType) || hourlyLeaveAccounting ? 1 : 0.5}
                  min={0}
                  max={hourlyLeaveAccounting ? 2920 : 365}
                  addonAfter={<Text><IntlMessages id={`app.${hourlyLeaveAccounting ? 'hours' : 'days'}`}/></Text>}
                  disabled={!leaveType.entitlementByRoleExists || disableSeniorityEntitlementOverrideInFuture}
                  onChange={(value) => onChangeEntitlementByRoleDaysPerYear(value as number)}
                />
              </Form.Item>
            </>
          </>
        }

        {
          userStartDate 
          && !isBefore(new Date(userStartDate as string), new Date(period?.start as string)) 
          && locationResetQuotas === 'FISCAL_YEAR'
          && isInitialPeriod
          && !hasUnlimitedDays 
          && leaveType.accrualType === AccrualTypeEnum.none
          && <Form.Item
            label={
              <IntlMessages
                id="components.userLeaveTypeForm.prorateLabel"
                values={{
                  startDate: `${dayjs(userStartDate)
                    .utc()
                    .locale(locale as string)
                    .format('MMMM D, YYYY')}`,
                }}
              />
            }
            name="shouldProrate"
            {...formItemLayout}
          >
            <Radio.Group
              onChange={onChangeShouldProrate}
              options={[
                { label: <IntlMessages id="app.yes" />, value: true },
                { label: <IntlMessages id="app.no" />, value: false },
              ]}
              optionType="button"
            />
          </Form.Item>
        }

        <Form.Item
          label={
            <>
              <IntlMessages id="app.timeOffInLieu" />
              <Tooltip className='info-tooltip' title={
                <IntlMessages id="app.timeOffInLieuTooltip" values={{
                  helpDesk: (...chunks) => <a href="https://vacationtracker.crisp.help/en/" target="_blank" rel="noopener noreferrer">{chunks}</a>,
                }} />
              }><InfoCircleOutlined /></Tooltip>
            </>
          }
          name="toilDays"
          {...formItemLayout}
        >
          <InputNumber
            style={{width: '140px'}}
            min={0}
            max={hourlyLeaveAccounting ? 2920 : 365}
            addonAfter={<Text><IntlMessages id={`app.${hourlyLeaveAccounting ? 'hours' : 'days'}`}/></Text>}
            disabled={true}
          />
        </Form.Item>

      </Form>
      {showQuotaAdditionalSummary &&
        <>
          <Divider/>
          <Alert
            message={<IntlMessages
              id={hasUnlimitedDays ? 'components.userLeaveTypeForm.quotaAdditionalSummaryUnlimited' : 'components.userLeaveTypeForm.quotaAdditionalSummary'}
              values={{
                user: userName,
                leaveTypeName: leaveType.leaveTypeName,
                total: hasUnlimitedDays ? <IntlMessages id="app.unlimited"/> : defaultDaysPerYear + seniorityEntitlementDaysPerYear + entitlementByRoleDaysForYear,
                future: applyToFutureYears ? 1 : 2, //plural for trans
                hourlyLeaveAccounting: hourlyLeaveAccounting ? 1 : 2, //plural for trans
                prorateText: locationResetQuotas === 'FISCAL_YEAR' && userStartDate && shouldProrate && period?.start && period?.end && form.getFieldsValue(['shouldProrate']) &&
                  <IntlMessages
                    id={`components.userLeaveTypeForm.${hourlyLeaveAccounting ? 'prorateInfoHours' : 'prorateInfoDays'}`}
                    values={{
                      ...getProratedPreciseQuota(!hourlyLeaveAccounting),
                      user: userName,
                      yearPeriod: `${dayjs(period.start)
                        .utc()
                        .locale(locale as string)
                        .format('MMMM D, YYYY')} - ${dayjs(period.end)
                        .utc()
                        .locale(locale as string)
                        .format('MMMM D, YYYY')}`,
                      fullQuota: defaultDaysPerYear,
                      startOfNextYear: dayjs(period.start).add(1, 'year').utc().locale(locale as string).format('MMMM D, YYYY'),
                    }}
                  />,
              }}
            />}
            type="info"
            style={{ marginBottom: '20px' }}
          />
        </>
      }
    </Modal>
  )
}

export default UserLeaveTypeForm
