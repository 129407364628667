import React from 'react'
import { Button, Tag, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import IntlMessages from '../../util/IntlMessages'

import { IAddonsData } from '@vacationtracker/shared/types/automations'
import { AddonsStatus, AddonsStatusEnum } from '@vacationtracker/shared/types/addons'
import { SubscriptionPlan } from '@vacationtracker/shared/types/company'

interface IAddonsCard extends IAddonsData {
  viewMode: boolean
  status: AddonsStatus
  onSelectAddon: (url: string, parent: string, type: string) => void
  addonsPrice: number
  isTrialSubscription: boolean
  authUserRole?: string
  helpDesklink?: string
  includedIn?: string[]
  subscriptionPlan: SubscriptionPlan
}

const AddonsCard = ({
  viewMode,
  status,
  type,
  parent,
  parentLabel,
  roles,
  url,
  icon,
  description,
  onSelectAddon,
  addonsPrice,
  authUserRole,
  helpDesklink,
  isTrialSubscription,
  includedIn,
  subscriptionPlan,
}: IAddonsCard) => {
  // authUserRole property is passed to the component only on the MyAddons page
  // ChoiceAutomations page doesn't have this property as user should be able to request any addon
  const canSelectAddon = authUserRole ? roles.includes(authUserRole) : true
  const addonCardExtra = (url: string, parent: string, type: string, subscriptionPlan: SubscriptionPlan, includedIn?: string[]) => {
    if (url === 'coming-soon') {
      return <Tag style={{ margin: 0 }}><IntlMessages id="app.comingSoon" /></Tag>
    }

    if (includedIn && includedIn.length > 0 && includedIn.includes(subscriptionPlan)) {
      return (
        <Button size="small" type="primary" onClick={() => { onSelectAddon(url, parent, type) }}>
          <IntlMessages id="addonsPaymentStatus.activateForFree" />
        </Button>
      )
    }

    return (
      <Button size="small" type="primary" onClick={() => { onSelectAddon(url, parent, type) }}>
        <IntlMessages id="choiceAutomations.reviewAndBuy" />
      </Button>
    )
  }

  const getAddonStatus = (status: AddonsStatus, subscriptionPlan: SubscriptionPlan, isTrialSubscription: boolean, includedIn?: string[]) => {
    if (includedIn && includedIn.length > 0 && includedIn.includes(subscriptionPlan)) {
      return <span className="free"><IntlMessages id="choiceAutomations.includedInYourPlan" /></span>
    }
    if (isTrialSubscription) {
      return <span className="trial-period"><IntlMessages id="app.trialPeriod" /></span>
    }
    if (status === AddonsStatusEnum.ACTIVE) {
      return <span className="active"><IntlMessages id="addonsPaymentStatus.paymentActive" /></span>
    }
    if (status === AddonsStatusEnum.CANCEL_AT_PERIOD_END) {
      return <span className="cancel-at-period-end"><IntlMessages id="addonsPaymentStatus.activeUntilBillingPeriodEnds" /></span>
    }
    if (status === AddonsStatusEnum.GRANT_PERIOD) {
      return <span className="grant-period"><IntlMessages id="addonsPaymentStatus.grantPeriod" /></span>
    }
    if (status === AddonsStatusEnum.CANCELED) {
      return <span className="canceled"><IntlMessages id="addonsPaymentStatus.canceled" /></span>
    }
    return ''
  }

  const getAddonPrice = (addonsPrice: number, subscriptionPlan: SubscriptionPlan, includedIn?: string[]) => {
    if (includedIn && includedIn.length > 0 && includedIn.includes(subscriptionPlan)) {
      return <span><IntlMessages id="choiceAutomations.includedInYourPlan" values={{ price: addonsPrice }}/></span>
    }
    return <span><IntlMessages id="choiceAutomations.automationPrice" values={{ price: addonsPrice }}/></span>
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectAddon = (event: any, url: string, parent: string, type: string) => {
    if (event.target && event.target.id === 'helpdesk') {
      return
    }
    onSelectAddon(url, parent, type)
  }

  return (
    <div
      className={`addons-card ${canSelectAddon ? 'hoverable' : 'disabled'} ${parent} ${status}`}
      onClick={(event) => canSelectAddon && handleSelectAddon(event, url, parent, type)}
      key={type}
    >
      <div className="addons-card-top-header"><Tag>{parentLabel}</Tag></div>
      <div className="addons-card-header">
        <div className="addons-card-header-icon">
          {icon}
        </div>
        <div className="addons-card-header-extra">
          {canSelectAddon && (viewMode ? 
            <Button size="small" type="primary" onClick={() => { onSelectAddon(url, parent, type) }}>
              <IntlMessages id="app.view" />
            </Button> :
            addonCardExtra(url, parent, type, subscriptionPlan, includedIn)
          )}
          {viewMode ? 
            getAddonStatus(status, subscriptionPlan, isTrialSubscription, includedIn) :
            getAddonPrice(addonsPrice, subscriptionPlan, includedIn)
          }
          {roles.length > 0 &&
            <IntlMessages
              id="choiceAutomations.accessibleTo"
              values={{
                approver: (...chunks) => roles.includes('Approver') ? chunks : '',
                admin: (...chunks) => roles.includes('Admin') ? chunks : '',
                and: (...chunks) => roles.length === 2 ? chunks : '',
                only: (...chunks) => roles.length === 1 ? chunks : '',
              }}
            />
          }
        </div>
      </div>
      <div className="addons-card-title">
        <IntlMessages id={`automations.${type}`} />
        {helpDesklink &&
          <Tooltip 
            title={
              <IntlMessages 
                id="automations.stepByStepHelpDesk" 
                values={{
                  automationName: () => <IntlMessages id={`automations.${type}`} />,
                  link: (...chunks) => <a href={helpDesklink} target="_blank" id="helpdesk" rel="noopener noreferrer">{chunks}</a>,
                }} 
              />
            }
          >
            <InfoCircleOutlined style={{ marginLeft: 10 }} />
          </Tooltip>
        }
      </div>
      <div className="addons-card-description">
        {description}
      </div>
    </div>
  )
}

export default AddonsCard
