import axios from 'axios'
import { ISignupRequest } from './companies.types'
import { IGetCompanyAndUserInfo } from '../../types/custom-queries'
import { setAuthCompany } from '../../store/auth-company-slice'
import { setAuthUser } from '../../store/auth-user-slice'
import { setLocale } from '../../store/locale-slice'
import { availableLanguages } from '@vacationtracker/shared/i18n'
import * as Sentry from '@sentry/react'
import { logout } from '../auth/logout-handler'
import { FrontendUrls } from '../../types/urls'
import { wait } from '@vacationtracker/shared/functions/wait'
import { History } from 'history'
import { AppDispatch } from '../../store/configure'
import * as logger from '../../services/logger'
import { FetchData } from 'graphql-hooks'
import { getCurrentUser, IAuthUser } from '@vacationtracker/shared/services/auth'

type GetCompanyAndUserInfoQueryFn = FetchData<IGetCompanyAndUserInfo, {
  userId: string
}, object>

const url = `${process.env.REACT_APP_API_URL}/core/companies`

export const signup = async (data: ISignupRequest) => {
  const response = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response.data
}

let numberOfRetry = 0

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCompanyAndUser = async (id: string, getCompanyAndUserInfoQuery: GetCompanyAndUserInfoQueryFn, dispatch: AppDispatch, history: History) => {
  try {
    const response = await getCompanyAndUserInfoQuery({ variables: { userId: id }})
    if (response.data?.getCompany && response.data.getUser && response.data.getUser.name) {
      numberOfRetry = 0
      if (response.data.getUser.status !== 'ACTIVE') {
        logout({
          history,
          reduxDispatch: dispatch,
          userId: id,
        })
      }
      dispatch(setAuthCompany(response.data.getCompany))
      dispatch(setAuthUser(response.data.getUser))
      if (response.data.getUser.locale) {
        dispatch(setLocale(availableLanguages[response.data.getUser.locale]))
      }
      Sentry.setUser({
        id: response.data.getUser.id,
        companyId: response.data.getCompany.id,
      })
    } else {
      throw new Error('No current user, retry')
    }
  } catch (error) {
    logger.debug('ERROR GET COMPANY AND USER (service)', error, numberOfRetry)
    let currentUser: IAuthUser | null = null
    try {
      // This can fail, so we have try-catch inside catch
      currentUser = await getCurrentUser()
    } catch (error) {
      // Swallow the error
    }
    if (numberOfRetry >= 10) {
      numberOfRetry = 0
      logout({
        history,
        reduxDispatch: dispatch,
        userId: id,
      })
    } else if (
      currentUser ||
      ![FrontendUrls.signin, FrontendUrls.signup].includes(location.pathname as FrontendUrls) ||
      location.pathname.includes(FrontendUrls.externalConnect)
    ) {
      numberOfRetry++
      await wait(200 * numberOfRetry)
      return await getCompanyAndUser(id, getCompanyAndUserInfoQuery, dispatch, history)
    }
  }
}
