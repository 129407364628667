import React, { useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Tabs, TabsProps } from 'antd'
import { useManualQuery } from 'graphql-hooks'

import { getPurchasedAddons } from '../../graphql/custom-queries'
import { track } from '../../services/analytics/analytics'
import { roleAdmin } from '../../store/auth-user-slice'
import { notificationStore } from '../../context/notificationsContext/store'

import { getExtendedAddons } from '../Add-ons/common/addons'

import CircularProgress from '../../components/circular-progress'
import AddonFeedback from '../../components/addon-feedback'
import MyAddons from '../../components/my-addons'
import ChoiceAddons from '../../components/choice-addons'
import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'

import { IGetPurchasedAddonsData } from '../../types/custom-queries'
import { AddonsStatusEnum, IAddon } from '@vacationtracker/shared/types/addons'
import { AddOnsActiveTabEnum } from '@vacationtracker/shared/types/automations'
import { ProductService } from '@vacationtracker/shared/data/stripe/product-service'
import { ProductName } from '@vacationtracker/shared/data/stripe/prices'

const AddonsPage = () => {
  const { formatMessage } = useIntl()
  const amIAdmin = useSelector(roleAdmin)
  const { actionNotifications } = useContext(notificationStore)
  const isMounted = useRef(false)

  const [isLoading, setIsLoading] = useState(true)
  const [subscribedAddons, setSubscribedAddons] = useState<IAddon[]>([])
  const [activeTab, setActiveTab] = useState<AddOnsActiveTabEnum>(AddOnsActiveTabEnum.myAddons)
  const [userCount, setUserCount] = useState(0)
  
  const [ getPurchasedAddonsQuery ] = useManualQuery<IGetPurchasedAddonsData>(getPurchasedAddons)

  const env = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'prod' : 'test'
  const productService = new ProductService(env)
  const addonsPrice = productService.getPriceUnitByProductName(ProductName.ADDONS)

  useEffect(() => {
    if (isLoading) {
      track('AUTOMATIONS_ACTIVE_VIEWED', {})
    }
  }, [isLoading])

  useEffect(() => {
    isMounted.current = true
    fetchData()
    return () => {
      isMounted.current = false
    }
  }, [actionNotifications])

  const fetchData = async () => {
    const response = await getPurchasedAddonsQuery()
    if (!isMounted.current) return
    if (!response.data || response.error) throw response.error

    const subscribedAddons = [
      ...(response.data?.getPurchasedAddons?.addons || []),
      ...(response.data?.getPurchasedAddons?.automations || []),
      ...(response.data?.getPurchasedAddons?.integrations || []),
    ]
    setSubscribedAddons(subscribedAddons as IAddon[])

    setUserCount(response.data.getUserCount.usersActive)
    setIsLoading(false)
  }

  useEffect(() => {
    if (subscribedAddons.length === 0 && !isLoading) {
      setActiveTab(AddOnsActiveTabEnum.discoveryMoreAddons)
    }
  }, [subscribedAddons, isLoading])

  const addonsEvent = getExtendedAddons(formatMessage)

  const handleChangeTab = (key: string) => {
    setActiveTab(key as AddOnsActiveTabEnum)
    if (key === AddOnsActiveTabEnum.discoveryMoreAddons) {
      track('DISCOVER_MORE_ADDONS_CLICKED', {})
    }
  }

  const addonsTabs: TabsProps['items'] = [
    {
      key: AddOnsActiveTabEnum.myAddons,
      label: <IntlMessages id="automations.myAddons" />,
      children: <MyAddons
        subscribedAddons={subscribedAddons} 
        addonsEvent={addonsEvent}
        changeTab={(tab: AddOnsActiveTabEnum) => {
          setActiveTab(tab)
        }}
        addonsPrice={addonsPrice}
      />,
    },
    {
      key: AddOnsActiveTabEnum.discoveryMoreAddons,
      label: <IntlMessages id="automations.discoveryMoreAddons" />,
      children: <ChoiceAddons
        addonsEvent={addonsEvent}
        subscribedAddons={subscribedAddons.filter((addon) => addon.status !== AddonsStatusEnum.CANCELED)}
        allSubscribedAddons={subscribedAddons}
        formatMessage={formatMessage}
        amIAdmin={amIAdmin}
        userCount={userCount}
        addonsPrice={addonsPrice}
      />,
    },
  ]

  return (
    <>
      {isLoading ?
        <CircularProgress /> :
        <div className='main-content'>
          <div className="main-content-header">
            <div className="main-content-header-title">
              <IntlMessages id="app.addOns" />
            </div>
          </div>
          <div className="main-content-body">
            <div>
              <Tabs
                tabBarExtraContent={<AddonFeedback buttonStyle={{ float: 'right' }} page="automation-page-list" />}
                activeKey={activeTab}
                onTabClick={(key) => handleChangeTab(key)}
                items={addonsTabs}
              />
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default AddonsPage
