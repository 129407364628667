import React, { useEffect, useState } from 'react'
import { App, Card, Timeline, DatePicker, Select, Typography, Row, Col, Space } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { useManualQuery } from 'graphql-hooks'

import subMilliseconds from 'date-fns/subMilliseconds'
import { sortBy, union } from 'lodash'
import IntlMessages from '../../util/IntlMessages'
import { filterOptions } from '../../util/filter-options'
import { getLogLine, IUserLogLineResult } from '../log-line'
import timezoneData from '@vacationtracker/shared/data/timezone'
import * as logger from '../../services/logger'

import { getUserLogs } from '../../graphql/custom-queries'
import { IGetUserLogsData, IUserLogs } from '../../types/custom-queries'
import CircularProgress from '../circular-progress'
import { wait } from '@vacationtracker/shared/functions/wait'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'
import { WorkingHours } from '@vacationtracker/shared/types/work-week'
import { getUserWorkingHoursPerDay } from '@vacationtracker/shared/functions/work-week'

dayjs.extend(utc)
dayjs.extend(timezone)

const { RangePicker } = DatePicker
const { Option } = Select
const { Paragraph, Text } = Typography

interface IUserLogsTab {
  userId: string
  amIAdmin: boolean
  reload: string
  locationTimezone: string | undefined
  hourlyLeaveAccounting: boolean
  hourFormat: HourFormatEnum
  workingHours?: WorkingHours
}

const userEvents = [
  { eventType: 'ALL' },
  { eventType: 'USER_STATUS_CHANGED' },
  { eventType: 'LOCATION_USER_MOVED' },
  { eventType: 'TEAM_USER_MOVED' },
  { eventType: 'USER_UPDATED' },
  { eventType: 'USER_INVITATION_ACCEPTED' },
  { eventType: 'USER_WORK_WEEK_UPDATED' },
  { eventType: 'USER_WORK_WEEK_DELETED' },
  { eventType: 'ROLLOVER_EXPIRED' },
  { eventType: 'YEAR_START' },
  { eventType: 'ACCRUED_DAYS' },
  { eventType: 'USER_LEAVE_TYPES_UPDATED' },
  { eventType: 'USER_LEAVE_TYPES_DELETED' },
  { eventType: 'APPROVER_STATUS' },
]

const UserLogsTab = ({
  userId,
  amIAdmin,
  reload,
  locationTimezone,
  hourlyLeaveAccounting,
  hourFormat,
  workingHours,
}: IUserLogsTab): React.ReactElement => {
  const { notification } = App.useApp()
  const dateFormat = 'YYYY-MM-DD'
  const [isLoading, setIsLoading] = useState(true)
  const [isFilterLoading, setIsFilterLoading] = useState(false)
  const [userLogs, setUserLogs] = useState<IUserLogs[]>([])
  const [allUserLogs, seAllUserLogs] = useState<IUserLogs[]>([])
  const [timezone, setTimezone] = useState(() => {
    const storageTimezone = localStorage.getItem('USER_TIMEZONE')
    const existTimezone = timezoneData.find((item) => item.id === (storageTimezone || locationTimezone))
    if (existTimezone) {
      return existTimezone.id
    }
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  })
  const [startDate, setStartDate] = useState(dayjs().utc().tz(timezone).subtract(1, 'year'))
  const [endDate, setEndDate] = useState(dayjs().utc().tz(timezone))
  const [filterEvent, setFilterEvent] = useState('ALL')
  const [editTimezone, setEditTImezone] = useState(false)
  const [filterEvents, setFilterEvents] = useState(userEvents)

  const [getUserLogsQuery] = useManualQuery<IGetUserLogsData, {
    id: string
    fromDate: string
    toDate: string
  }>(getUserLogs)

  useEffect(() => {
    if(isLoading) {
      fetchUserLogs(userId, startDate.utc().tz(timezone).format(dateFormat), endDate.utc().tz(timezone).format(dateFormat))
    }
  }, [isLoading])

  // Stupid fix for reload data every time when you open user log tabs
  useEffect(() => {
    setFilterEvent('ALL')
    setIsFilterLoading(false)
    setIsLoading(true)
  },[reload])

  const fetchUserLogs = async (id: string, fromDate: string, toDate: string) => {
    try {
      const response = await getUserLogsQuery({ variables:{
        id,
        fromDate,
        toDate,
      }})
      if (!response.data || response.error) throw response.error

      const allEventTypes: string[] = ['ALL']
      const logs = sortBy(response.data.getUser.logs
        .map(log => {
          allEventTypes.push(log.type)
          if (log.type === 'USER_STATUS_CHANGED') {
            log.timestamp = subMilliseconds(new Date(log.timestamp), 500).toISOString()
          }
          return log
        }), ['timestamp']).reverse()

      seAllUserLogs(logs)
      const uniqueEventTypes = union(allEventTypes)


      setFilterEvents(uniqueEventTypes.map(name => {
        return {
          eventType: name,
        }
      }))

      if (filterEvent !== 'ALL') {
        const filterEventType = userEvents.find(event => event.eventType === filterEvent)?.eventType
        setUserLogs(logs.filter(log => log.type === filterEventType))
      } else {
        setUserLogs(logs)
      }

      setIsFilterLoading(false)
      setIsLoading(false)
    } catch (error) {
      logger.warning('error fetching user logs by user id', error)
      const message = error.response?.data?.message ? error.response?.data?.message : error.message ? error.message : JSON.stringify(error)
      notification.error({
        message,
        duration: 0,
      })
    }
  }

  const changeTimezone = (newTimezone) => {
    setIsFilterLoading(true)
    setTimezone(newTimezone)
    localStorage.setItem('USER_TIMEZONE', newTimezone)
    const start = startDate.utc().tz(newTimezone)
    const end = endDate.utc().tz(newTimezone)
    setStartDate(start)
    setEndDate(end)
    fetchUserLogs(userId, start.format(dateFormat), end.format(dateFormat))
    setEditTImezone(false)
  }

  const changeRangePicker = (dates) => {
    setStartDate(dates[0].utc().tz(timezone))
    setEndDate(dates[1].utc().tz(timezone))
    fetchUserLogs(userId, dates[0].format(dateFormat), dates[1].format(dateFormat))
  }

  const changeEventFilter = async (eventName) => {
    setIsFilterLoading(true)
    setFilterEvent(eventName)
    if (eventName === 'ALL') {
      setUserLogs(allUserLogs)
    } else {
      setUserLogs(allUserLogs.filter(log => log.type === eventName))
    }
    // Stupid fix for refresh logs data on page
    await wait(200)
    setIsFilterLoading(false)
  }

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current > dayjs().endOf('day')
  }

  const filters = () => {
    return (<>
      <Row gutter={[8,24]} justify='space-between' align='middle'>
        <Col xs='24' xl='8'>
          <IntlMessages id="components.userLogsTab.filter" />
          <Select
            style={{ marginLeft: 10, marginRight: 10, width: 260 }}
            value={filterEvent}
            showSearch
            onSelect={changeEventFilter}
            filterOption={filterOptions}
          >
            {filterEvents.map(event =>
              <Option key={event.eventType} value={event.eventType}>
                <IntlMessages id={`components.userLogsTab.${event.eventType}`} />
              </Option>)
            }
          </Select>
        </Col>
        <Col xs='24' xl='8'>
          <IntlMessages id="components.userLogsTab.selectFromTo" />
          <RangePicker onChange={changeRangePicker}
            style={{ marginLeft: 10, marginRight: 10 }}
            allowClear={false}
            defaultValue={[startDate, endDate]}
            format={dateFormat}
            disabledDate={disabledDate}
          />
        </Col>
        <Col xs='24' xl='8'>
          {editTimezone ?
            <Select style={{ width: 300 }}
              value={timezone}
              showSearch
              onSelect={changeTimezone}
              filterOption={filterOptions}
            >
              {timezoneData.map(tz => <Option key={tz.id} value={tz.id}>{tz.text}</Option>)}
            </Select> :
            <><Text type="secondary">{timezone}</Text> <EditOutlined className="edit-timezone" onClick={() => setEditTImezone(true)} /></>
          }
        </Col>
      </Row>
    </>)
  }

  return (
    <Card
      className="user-logs-card"
      title={filters()}
      style={{ marginBottom: 30 }}
      styles={{ body: { padding: 0 } }}
    >
      {(isLoading || isFilterLoading) ?
        <CircularProgress style={{ marginTop: 40 }} /> :
        <>
          {userLogs?.length > 0 ?
            <Timeline
              mode="left"
              className="logs-timeline ant-timeline-label"
              items={
                userLogs.map((log, index) => getLogLine({
                  type: log.type,
                  timestamp: log.timestamp,
                  creatorName: log.creatorName,
                  creatorId: log.creatorId,
                  paramsJson: log.paramsJson,
                  timezone,
                  amIAdmin,
                  hourlyLeaveAccounting,
                  hourFormat,
                  numberOfWorkingHoursPerDay: getUserWorkingHoursPerDay(workingHours),
                  index,
                }))
                  .filter(log => log !== null) as IUserLogLineResult[]
              }  
            />
            :
            <Paragraph style={{ textAlign: 'center', marginTop: 30 }}><IntlMessages id="components.userLogsTab.logsNotFound" /></Paragraph>
          }
        </>
      }
    </Card>
  )
}

export default UserLogsTab
