import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { useIntl } from 'react-intl'
import * as Sentry from '@sentry/react'
import { setUser } from '@sentry/react'
import { useManualQuery } from 'graphql-hooks'
import { Alert, AlertProps, Button, Col, Form, Row, Typography } from 'antd'
import axios from 'axios'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'

import Api from '@vacationtracker/shared/services/api'
import VtLogo from '../../assets/images/logo-purple.svg'
import { useAppDispatch } from '../../store/hooks'
import { setAuthCompany } from '../../store/auth-company-slice'
import { setLocale } from '../../store/locale-slice'
import { setAuthUser } from '../../store/auth-user-slice'
import { setUserId } from '../../store/user-id-slice'
import { setFeatureFlags } from '../../store/feature-flags-slice'
import { setOnboardingActions, hideOnboardingFlow } from '../../store/onboarding-slice'
import { wait } from '@vacationtracker/shared/functions/wait'
import { availableLanguages } from '@vacationtracker/shared/i18n'
import { InviteAcceptedException, InvitationExpiredException, InvitationNotFoundException } from '@vacationtracker/shared/errors/core-events'
import { getCompanyAndUserInfo, getUserActionFlow } from '../../graphql/custom-queries'
import { logout } from '../../services/auth/logout-handler'
import { identify, identifyCompany, identifySingleUser } from '../../services/analytics/analytics'

import { UserSignUpForm } from '../../components/user-signup-form'
import CircularProgress from '../../components/circular-progress'
import IntlMessages from '../../util/IntlMessages'

import { IGetCompanyAndUserInfo, IGetUserActionFlowData } from '../../types/custom-queries'

import { FrontendUrls } from '../../types/urls'
import { IEmailInvite } from '@vacationtracker/shared/types/user'
import { ICompanyInfo } from '@vacationtracker/shared/types/company'
import { IUserInfo } from '../../types/users'
import { uploadAvatar } from '../../services/api/files'
import { RcFile } from 'antd/lib/upload'
import { confirmSigninWithCustomChallengeAnswer, IAuthNextStep, signin, updatePassword } from '@vacationtracker/shared/services/auth'
import { AuthStateEnum } from '@vacationtracker/shared/types/auth-state'
import { setAuthState } from '../../store/auth-state-slice'

const { Paragraph } = Typography

const UserSignup = ({ match }) => {
  const [form] = Form.useForm()
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()
  const history = useHistory()

  const [invitation, setInvitation] = useState<IEmailInvite | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isSigningUp, setIsSigningUp] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const [getCompanyAndUserInfoQuery] = useManualQuery<IGetCompanyAndUserInfo, { userId: string }>(getCompanyAndUserInfo)
  const [getUserActionFlowQuery] = useManualQuery<IGetUserActionFlowData, { type: 'dashboard' }>(getUserActionFlow)

  useEffect(() => {
    const code = match?.params?.code
    getInvitation(code)
  }, [])

  const getInvitation = async (code: string) => {
    setIsLoading(true)
    try {
      setError(null)
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/core/invitations/${code}`)
      setInvitation(res.data)
      setIsLoading(false)
    } catch (error) {
      if (error.response.data.code) {
        setError(error.response.data.code)
      } else {
        setError(InvitationNotFoundException.code)
      }
      setIsLoading(false)
    }
  }
  const goToLogin = (state?: string) => {
    dispatch(setAuthState(AuthStateEnum.signIn))
    const url = FrontendUrls.signin + (state ? `?${state}=true` : '')
    history.push(url)
  }

  const getErrorMessage = (code) => {
    const data: AlertProps = {
      message: '',
      description: '',
      type: 'error',
    }
    switch (code) {
      case InviteAcceptedException.code:
        data.message = formatMessage({ id: 'error.userSignup.invitationAccepted.title' })
        data.description = <>
          <Paragraph><IntlMessages id="error.userSignup.invitationAccepted.description" /></Paragraph>
          <Paragraph><Button onClick={() => goToLogin()} type="primary"><IntlMessages id="app.login" /></Button></Paragraph>
        </>
        data.type = 'info'
        break
      case InvitationExpiredException.code:
        data.message = formatMessage({ id: 'error.userSignup.invitationExpired.title' })
        data.description = formatMessage({ id: 'error.userSignup.invitationExpired.description' })
        break
      case InvitationNotFoundException.code:
        data.message = formatMessage({ id: 'error.userSignup.invitationNotFound.title' })
        data.description = formatMessage({ id: 'error.userSignup.invitationNotFound.description' })
        break
      default:
        // TODO: add default error
        break
    }

    return <Alert
      type={data.type}
      message={data.message}
      description={data.description}
      style={{ margin: 12 }}
      showIcon
    />
  }

  const getFeatureFlags = async (role: string, url: string) => {
    try {
      const response = await Api.get(`/core/status?role=${role}&url=${url}`, { withCredentials: true })
      dispatch(setFeatureFlags(response.enabledFeatures))
    } catch(error) {
      console.log('ERROR', error)
    }
  }

  const getOnboardingChecklist = async () => {
    try {
      const response = await getUserActionFlowQuery({ variables: {type: 'dashboard'}})
      if (!response.data || response.error) throw response.error
      if(!response.data.getUserActionFlow) {
        dispatch(hideOnboardingFlow())
        return
      }
      const actions = pickBy(response.data.getUserActionFlow.actions || {}, identity)
      response.data.getUserActionFlow.actions = actions
      dispatch(setOnboardingActions(response.data.getUserActionFlow || {}))
    } catch(error) {
      console.log('ERROR', error)
    }
  }

  const setCrispSessionInfoForLoggedInUsers = (companyInfo: ICompanyInfo, userInfo: IUserInfo): void => {
    try {
      if (!window.$crisp) {
        return
      }

      // Set Crisp segments, i.e., "chat," "support," "dashboard," "slack," and "core-plan"
      const segments = ['chat', 'support', 'dashboard']
      segments.push(companyInfo.platform === 'microsoft' ? 'teams' : companyInfo.platform)
      if (companyInfo.plan) {
        segments.push(companyInfo.plan.toLowerCase() + '-plan')
      }
      window.$crisp.push(['set', 'session:segments', [segments, true]])

      // Set user name and company (user data)
      window.$crisp.push(['set', 'user:nickname', userInfo.name])
      window.$crisp.push(['set', 'user:company', companyInfo.name])

      // Set session data (company Id, user Id, user role and if the user is approver)
      window.$crisp.push(['set', 'session:data', [[
        ['company_id', companyInfo.id],
        ['user_id', userInfo.id],
        ['role', userInfo.role],
        ['locale', userInfo.locale],
        ['is_approver', userInfo.approverTo.length > 0],
        ['subscription_status', companyInfo.subscriptionStatus],
        ['plan', companyInfo.plan],
        ['platform', companyInfo.platform],
        ['payment_processor', companyInfo.billing?.paymentProcessor ?? ''],
      ]]])

    } catch(err) {
      // Swallow errors
      window.$crisp.push(['set', 'session:data', ['error', err.message]])
      console.log(err)
    }
  }

  let numberOfRetry = 0
  const getCompanyAndUser = async (id: string) => {
    try {
      const response = await getCompanyAndUserInfoQuery({ variables: { userId: id } })
      if (!response.data || response.error) throw response.error

      if (response.data.getCompany && response.data.getUser && response.data.getUser.name) {
        const companyData = response.data.getCompany
        dispatch(setAuthCompany(companyData))
        const userData = response.data.getUser
        dispatch(setAuthUser(userData))
        if (userData.locale) {
          dispatch(setLocale(availableLanguages[userData.locale]))
        }

        getOnboardingChecklist()
        getFeatureFlags(response.data.getUser.role, history.location.pathname)

        setUser(userData)
        Sentry.setUser({
          id: userData.id,
          companyId: companyData.id,
        })
        if (userData?.id) {
          identify(userData.id, {
            name: userData.name,
            role: userData.role,
            subscriptionStatus: companyData?.subscriptionStatus || '',
            trialPeriod: companyData?.trialPeriod || '',
            companyId: companyData?.id,
          })
          identifySingleUser(userData.id, {
            name: userData.name,
            role: userData.role,
          }, companyData?.id)
          identifyCompany(companyData.id, {
            name: companyData.name,
            subscriptionStatus: companyData.subscriptionStatus,
            trialPeriod: companyData.trialPeriod,
            platform: companyData.platform,
            plan: companyData.plan,
            organizationId: companyData.organizationId,
          })
        }
        setCrispSessionInfoForLoggedInUsers(companyData, userData)
        setIsSigningUp(false)
      } else {
        throw new Error('No current user, retry')
      }
    } catch (error) {
      console.log('ERROR GET COMPANY AND USER', error, numberOfRetry)
      if (numberOfRetry >= 10) {
        logout({
          history: history,
          reduxDispatch: dispatch,
          userId: id,
        })
      } else if (location.pathname !== FrontendUrls.connect) {
        numberOfRetry++
        await wait(200 * numberOfRetry)
        return await getCompanyAndUser(id)
      }
    }
  }

  const onSubmit = async () => {
    setIsSigningUp(true)
    try {
      const { userName, password, profilePicture } = await form.validateFields()
      const params: any = {
        name: userName.trimEnd(),
      }
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/core/invitations/${match?.params?.code}`, params)
      await login(res.data?.userId, res.data?.temporaryPassword, password, userName, profilePicture)
    } catch (error) {
      setIsSigningUp(true)
      console.error(false)
    }
  }

  const login = async (userId: string, temporaryPassword: string, password: string, name: string, profilePicture?: RcFile) => {
    if (temporaryPassword) {
    // Log in with password
      const cognitoResponse = await signin(userId as string, temporaryPassword) as IAuthNextStep
      if (cognitoResponse.challengeName === 'NEW_PASSWORD_REQUIRED') {
        await confirmSigninWithCustomChallengeAnswer(password as string)
      } else {
        await updatePassword(temporaryPassword, password as string)
      }
      await signin(userId as string, password as string)
    }

    localStorage.setItem('userId', userId as string)

    if (profilePicture) {
      // Upload avatar
      const imageUrl = await uploadAvatar(profilePicture)
      await Api.post('/core/event', {
        eventType: 'USER_UPDATED',
        eventGroup: 'USER',
        userId: userId,
        name,
        imageUrl,
      })
      // Wait for the user updated event to show the avatar
      // We can wait for the action notification, but this timeout will do the trick :)
      await wait(500)
    }
    await getCompanyAndUser(userId as string)
    dispatch(setUserId(userId))
    dispatch(setAuthState(AuthStateEnum.signedIn))
    history.push(FrontendUrls.dashboard)
  }

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  }

  return <>
    <section className="ant-layout ant-layout-has-sider app-layout create-company">
      <section className="ant-layout content-layout">
        <main className="ant-layout-content">
          <div className="main-content-wrapper">
            <div className="main-content">
              <div className="main-content-body">
                {isLoading ?
                  <CircularProgress /> : error ?
                    <Row gutter={[{ xs: 16, lg: 48},{ xs: 16, lg: 48}]} justify="center">
                      <Col span={24}>
                        <div className="auth-sidebar-logo">
                          <VtLogo />
                        </div>
                      </Col>
                      <Col lg={{span: 14}} md={{span: 14}} xs={{span: 24}}>
                        {getErrorMessage(error)}
                      </Col>
                    </Row> : <>
                      <Form
                        form={form}
                        layout="horizontal"
                        onFinish={() => onSubmit()}
                        {...layout}
                      >
                        <Row gutter={[{ xs: 0, lg: 0},{ xs: 16, lg: 48}]} justify="center">
                          <Col span={24}>
                            <div className="auth-sidebar-logo">
                              <VtLogo />
                            </div>
                          </Col>
                          <Col lg={{span: 14}} md={{span: 14}} xs={{span: 24}}>
                            <Paragraph><IntlMessages id="user.signup.intro" values={{ invitedByName: invitation?.invitedBy.name }} /></Paragraph>
                            <Paragraph>
                              <IntlMessages
                                id="user.signup.introHelpdesk"
                                values={{ helpdesk: (chunks) => (<a href="https://vacationtracker.crisp.help/en/" target="_blank" rel="noopener noreferrer">{chunks}</a>) }}
                              />
                            </Paragraph>
                          </Col>
                          <Col lg={{span: 14}} md={{span: 14}} xs={{span: 24}}>
                            <UserSignUpForm
                              form={form}
                              addAvatar
                            />
                          </Col>
                          <Col lg={{span: 14}} md={{span: 14}} xs={{span: 24}} style={{ textAlign: 'right' }}>
                            <Button
                              type="primary"
                              loading={isLoading || isSigningUp}
                              htmlType="submit"
                            >
                              <IntlMessages id="user.signUp" />
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </>}
              </div>
            </div>
          </div>
        </main>
      </section>
    </section>
  </>
}

export default UserSignup