import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Form, Select, Button, Typography, Input, Space, Alert, Table, Dropdown } from 'antd'
import { Link } from 'react-router-dom'
import Icon, {
  DeleteOutlined,
  TagOutlined,
  CopyOutlined,
  EnvironmentFilled,
  AppstoreAddOutlined,
  WarningTwoTone,
  ReloadOutlined
} from '@ant-design/icons'

import googleCalendarIconSvg from '../../../assets/images/google-calendar.svg'
import office365CalendarIconSvg from '../../../assets/images/microsoft-office365-outlook.svg'
import { selectAuthUserSlice } from '../../../store/auth-user-slice'
import { useAppSelector } from '../../../store/hooks'
import { useShouldEnableFeatures } from '../../../store/use-should-enable-features'

import FilterAdvanced from '@vacationtracker/shared/components/filter-advanced'
import CircularProgress from '../../../components/circular-progress'
import IntlMessages from '../../../util/IntlMessages'
import EmptyData from '../../../components/empty-data'

import { IGoogleCalendar, IOutlookCalendar, ISyncedCalendar, ISyncedCalendarTypeEnum } from '@vacationtracker/shared/types/calendar'
import { FrontendUrls } from '../../../types/urls'
import {
  CalendarColumnType,
  CalendarSyncViewEnum,
  ICalCreateProps,
  ICalSummaryProps,
  ICalendarsProps,
  IConnectCalendarActionProps,
  IConnectProps,
  IEmptyProps,
  IEmptySearchProps,
  IReconnectProps
} from '../types'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { ICreateUpdateProps } from '../types'
import { RefreshTokenRenewalFailed } from '@vacationtracker/shared/errors/calendar'
import { ColumnType } from 'antd/es/table'

const { Text, Paragraph, Title } = Typography
export const helpDeskLink = 'https://vacationtracker.crisp.help/en/article/guide-how-do-i-sync-my-calendar-with-vacation-tracker-l5psgm/'
export const iCalHelpDeskLink = 'https://vacationtracker.crisp.help/en/article/adding-a-new-calendar-using-ical-connection-y0mnnb/?bust=1706204571100'
export const createICalLink = (calendarId: string) => `${process.env.REACT_APP_API_URL}/core/calendar/${calendarId}/upcoming.ics`


export const Cancel = ({
  handleCancel,
}: {
  handleCancel: () => void
}) => {
  return <Button onClick={handleCancel}>
    <IntlMessages id="app.cancel" />
  </Button>
}

export const Connect = ({
  provider,
  whatToRender,
  handleGoogleConnect,
  handleOutlookConnect,
  handleCancel,
}: IConnectProps) => {
  return whatToRender === CalendarSyncViewEnum.googleConnect
    || whatToRender === CalendarSyncViewEnum.outlookConnect
    ? <div>
      <Title level={4}>
        <IntlMessages id="calendar.sync.connect" values={{ provider }} />
      </Title>
      <br />
      <Paragraph>
        <IntlMessages id="calendar.sync.connectInfo" values={{ provider }} />
      </Paragraph>

      <br />
      <div>
        <Button
          type="primary"
          onClick={provider === 'Google'
            ? handleGoogleConnect
            : handleOutlookConnect
          }
        >
          <IntlMessages id="calendar.sync.connect" values={{ provider }} />
        </Button>&nbsp;
        <Cancel handleCancel={handleCancel} />
      </div>
    </div>
    : null
}

export const CreateUpdate = ({
  provider,
  state,
  filterChanged,
  handleSelectCalendarToSync,
  handleLoginWihDifferentAccount,
  handleGoogleCreate,
  handleOutlookCreate,
  handleGoogleUpdate,
  handleOutlookUpdate,
  confirmDeleteCalendar,
  handleCancel,
  refreshCalendarList,
}: ICreateUpdateProps) => {
  const {
    whatToRender,
    googleSyncCalendarData,
    outlookSyncCalendarData,
    locations,
    departments,
    labels,
    isFetchingData,
    initialFilterValues,
    calendar,
    calendarToConnect,
  } = state
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const shouldEnableFeatures = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.labels)

  const isCreate = whatToRender === CalendarSyncViewEnum.googleCreate || whatToRender === CalendarSyncViewEnum.outlookCreate
  const isUpdate = whatToRender === CalendarSyncViewEnum.googleUpdate || whatToRender === CalendarSyncViewEnum.outlookUpdate
  const isGoogle = provider === 'Google'
  const calendarList = provider === 'Google' ? googleSyncCalendarData?.calendarList : outlookSyncCalendarData?.calendarList

  return isCreate || (isUpdate && calendar) ? <div>
    <Title level={4}>
      <IntlMessages
        id={`calendar.sync.${isCreate ? 'connect' : 'update'}`}
        values={{ provider }}
      />
    </Title>
    <br />
    <div className="filters">
      <Paragraph>
        <IntlMessages id="calendar.sync.createSyncCalendarFiltersInfo" values={{ provider }} />
      </Paragraph>
      {isFetchingData
        ? <CircularProgress />
        : <FilterAdvanced
          page='calendar'
          data={{
            Locations: locations,
            Departments: departments,
            Labels: labels,
          }}
          onChangeFilter={filterChanged}
          initialValues={initialFilterValues}
          showLabels={shouldEnableFeatures}
          currentUserId={authUser.id}
        />
      }
    </div>
    <br />
    {isCreate && <div>
      <Paragraph>
        <IntlMessages id="calendar.sync.createPushToSharedInfo" values={{ provider }} />
      </Paragraph>
      <Form.Item label={<IntlMessages id="app.select" />}>
        <Select
          value={calendarToConnect?.id}
          style={{ width: 280, marginRight: 5 }}
          onChange={handleSelectCalendarToSync}
          placeholder={<IntlMessages id="calendar.sync.selectSharedCalendarPlaceholder" values={{ provider }} />}
        >
          {calendarList?.map((calendar: IGoogleCalendar | IOutlookCalendar) => {
            return <Select.Option
              value={calendar.id}
              key={calendar.id}
            >
              {calendar.summary || calendar.name}
            </Select.Option>
          })}
        </Select>
        <Button
          shape="circle"
          icon={<ReloadOutlined />}
          onClick={() => refreshCalendarList(provider)}
        />
      </Form.Item>
      <Paragraph>
        <IntlMessages id="calendar.sync.loginWithDifferentAccountInfo" />&nbsp;
        <Link
          to={provider === 'Google' ?
            FrontendUrls.calendarSyncPageGoogleConnect :
            FrontendUrls.calendarSyncPageOutlookConnect
          }
          onClick={() => handleLoginWihDifferentAccount(provider)}
        >
          <IntlMessages
            id="calendar.sync.loginWithDifferentAccountLink"
            values={{ provider }}
          />
        </Link>
      </Paragraph>
    </div>}

    {isUpdate && calendar && <div>
      <Paragraph>
        <IntlMessages id="calendar.sync.canNotUpdateSharedCalendar" values={{ provider, calendarName: calendar?.calendarName }} />
      </Paragraph>
    </div>}

    <div style={{ display: 'flex' }}>
      <Cancel handleCancel={handleCancel} />&nbsp;
      {isCreate && <Button
        type="primary"
        disabled={!calendarToConnect?.id}
        onClick={isGoogle ? handleGoogleCreate : handleOutlookCreate}
      >
        <IntlMessages id="calendar.sync.createSyncCalendarButton" />
      </Button>}

      {isUpdate && <div>
        <Button
          type='primary'
          onClick={isGoogle ? handleGoogleUpdate : handleOutlookUpdate}
        >
          <IntlMessages id="calendar.sync.updateSyncCalendarButton" />
        </Button>&nbsp;
        <Button
          type='primary'
          danger
          onClick={(event) => confirmDeleteCalendar(calendar as ISyncedCalendar, event)}
        >
          <IntlMessages id="app.delete" />
        </Button>
      </div>}
    </div>
  </div> : isUpdate && !calendar ? <div>
    <Paragraph>
      <IntlMessages id='calendar.sync.deletedCalendar' />
    </Paragraph>
    <Link to={FrontendUrls.calendarSyncPage}>
      <Cancel handleCancel={handleCancel} />
    </Link>
  </div> : null
}

export const ICalWarningInfo = () => {
  return <div>
    <Alert
      message={<IntlMessages id="calendar.sync.createICalFiltersWarning" />}
      type={'warning'}
    />
  </div>
}

export const IcalSummary = ({
  state,
  confirmDeleteCalendar,
  handleCancel,
}: ICalSummaryProps) => {
  const { formatMessage } = useIntl()
  const {
    calendar,
    labels,
    locations,
    departments,
    whatToRender,
  } = state
  return whatToRender === CalendarSyncViewEnum.icalSummary && calendar ? <div>
    <Paragraph><IntlMessages id="calendar.syncModal.description" /></Paragraph>
    <Paragraph>
      <Input
        id="calendar-url"
        value={createICalLink(calendar.id)}
        readOnly={true}
        suffix={<CopyOutlined style={{ cursor: 'pointer' }} onClick={() => {
        // copyCalendarUrlToClipboard()
        }} />} />
    </Paragraph>
    <br />
    <Paragraph><IntlMessages id="calendar.syncModal.filters" /></Paragraph>
    <Space direction="vertical">
      <Text><EnvironmentFilled /> <IntlMessages id="app.locations" />: <Text strong>{
        calendar?.locations?.length as number < 1
          ? formatMessage({ id: 'app.all' })
          : calendar?.locations?.map((location, index) => {
            const foundLocation = locations?.find(loc => location === loc.id)
            const suffix = calendar?.locations?.length as number - 2 === index
              ? ` ${formatMessage({ id: 'app.and' })}`
              : calendar?.locations?.length as number - 1 === index
                ? ''
                : ','
            return ` ${foundLocation?.name}${suffix}`
          })
      }</Text></Text>
      <Text><AppstoreAddOutlined /> <IntlMessages id="app.departments" />: <Text strong>{
        calendar?.departments?.length as number < 1
          ? formatMessage({ id: 'app.all' })
          : calendar?.departments?.map((department, index) => {
            const foundDepartment = departments?.find(dep => department === dep.id)
            const suffix = calendar?.departments?.length as number - 2 === index
              ? ` ${formatMessage({ id: 'app.and' })}`
              : calendar?.departments?.length as number - 1 === index
                ? ''
                : ','
            return ` ${foundDepartment?.name}${suffix}`
          })
      }</Text></Text>
      {
        calendar?.labels?.length as number > 0 &&
      <Text><TagOutlined /> <IntlMessages id="app.labels" />: <Text strong>{
        calendar?.labels?.map((labelId, index) => {
          const label = labels?.find(label => label.id === labelId)
          const suffix = calendar?.labels?.length as number - 2 === index
            ? ` ${formatMessage({ id: 'app.and' })}`
            : calendar?.labels?.length as number - 1 === index
              ? ''
              : ','
          return ` ${label?.name}${suffix}`
        })
      }</Text></Text>
      }
      <Paragraph>
        <IntlMessages
          id="calendar.syncModal.helpdesk"
          values={{
          // eslint-disable-next-line
          helpDesk: (...chunks) => (
              <a href={iCalHelpDeskLink} target="_blank" rel="noopener noreferrer">
                {chunks}
              </a>
            ),
          }}
        />
      </Paragraph>
    </Space>
    <br />
    <div>
      <Cancel handleCancel={handleCancel} />&nbsp;
      <Button
        type="primary"
        href={iCalHelpDeskLink}
        target="_blank"
      >
        <IntlMessages id="calendar.sync.stepByStep" />
      </Button>&nbsp;
      <Button
        type='primary'
        danger
        onClick={(event) => confirmDeleteCalendar(calendar, event)}
      >
        <IntlMessages id="app.delete" />
      </Button>
    </div>
    <br />
    <ICalWarningInfo />
  </div> : !calendar && whatToRender === CalendarSyncViewEnum.icalSummary
    ? <div>
      <Paragraph>
        <IntlMessages id='calendar.sync.deletedCalendar' />
      </Paragraph>
      <Link to={FrontendUrls.calendarSyncPage}>
        <Button type="primary">
          <IntlMessages id="app.back" />
        </Button>
      </Link>
    </div> : null
}

export const IcalCreate = ({
  state,
  filterChanged,
  handleCreateIcalCalendarSync,
  handleCancel,
}: ICalCreateProps) => {
  const {
    whatToRender,
    isFetchingData,
    locations,
    departments,
    labels,
    initialFilterValues,
  } = state
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const shouldEnableFeatures = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.labels)

  return whatToRender === CalendarSyncViewEnum.icalCreate
    ? <div>
      <Paragraph><IntlMessages id="calendar.sync.createICalInfo" /></Paragraph>
      <br />
      <div className="filters">
        <Paragraph><IntlMessages id="calendar.sync.createICalFiltersInfo" /></Paragraph>
        {isFetchingData
          ? <CircularProgress />
          : <FilterAdvanced
            page='calendar'
            data={{
              Locations: locations,
              Departments: departments,
              Labels: labels,
            }}
            onChangeFilter={filterChanged}
            initialValues={initialFilterValues}
            showLabels={shouldEnableFeatures}
            currentUserId={authUser.id}
          />
        }

      </div>
      <br />
      <br />
      <div>
        <Cancel handleCancel={handleCancel} />&nbsp;
        <Button
          type="primary"
          onClick={handleCreateIcalCalendarSync}
        >
          <IntlMessages id="calendar.sync.createICalButton" />
        </Button>
      </div>
      <br />
      <br />
      <ICalWarningInfo />
    </div>
    : null
}

export const Calendars = ({
  state,
  history,
  confirmDeleteCalendar,
}: ICalendarsProps) => {
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const hasError = useMemo(() => state.filteredCalendars.some(row => row.error), [state.filteredCalendars])

  const parsedErrorMessage = (error) => {
    try {
      const parsed = JSON.parse(error.message)
      return parsed.error.message || parsed.error.code || parsed.error
    } catch (e) {
      return error.message || error.code || error
    }
  }

  const shouldShowReconnect = (error) => {
    return error?.code === RefreshTokenRenewalFailed.code
  }

  const calendarsColumns = [
    {
      title: <IntlMessages id="app.type" />,
      dataIndex: 'type',
      key: 'type',
      render: (type: ISyncedCalendarTypeEnum) => {
        if (type === ISyncedCalendarTypeEnum.google) {
          return <span style={{display: 'flex', alignItems: 'center'}}>
            <Icon component={googleCalendarIconSvg} alt="Google Calendar" />&nbsp;{type}
          </span>
        } else if (type === ISyncedCalendarTypeEnum.outlook) {
          return <span style={{display: 'flex', alignItems: 'center'}}>
            <Icon component={office365CalendarIconSvg} alt="Office 365 Calendar" />&nbsp;{type}
          </span>
        } else {
          return <span>{type}</span>
        }
      },
    },
    {
      title: <IntlMessages id="app.locations" />,
      dataIndex: 'locations',
      key: 'locations',
      render: (locations) => {
        if (!locations || locations?.length === 0) {
          return <span><IntlMessages id="app.all" /></span>
        }
        const locationNames = state.locations
          .filter((location) => locations?.includes(location.id))
          .map((location) => location.name).join(', ')
        return <span>{locationNames}</span>
      },
    },
    {
      title: <IntlMessages id="app.departments" />,
      dataIndex: 'departments',
      key: 'departments',
      render: (departments) => {
        if (!departments || departments?.length === 0) {
          return <span><IntlMessages id="app.all" /></span>
        }
        const departmentNames = state.departments
          // .filter((department) => department.active)
          .filter((department) => departments?.includes(department.id))
          .map((department) => department.name).join(', ')
        return <span>{departmentNames}</span>
      },
    },
    {
      title: <IntlMessages id="app.labels" />,
      dataIndex: 'labels',
      key: 'labels',
      render: (labels) => {
        if (!labels || labels?.length === 0) {
          return <span><IntlMessages id="app.all" /></span>
        }
        const labelNames = state.labels
          .filter((label) => labels?.includes(label.id))
          .map((label) => label.name).join(', ')
        return <span>{labelNames}</span>
      },
    },
    {
      title: <IntlMessages id="app.createdBy" />,
      dataIndex: 'user',
      key: 'user',
      render: (creator: {
        id: string
        name: string
      }) => {
        if (!creator?.name) {
          return ''
        }
        return <Text
          style={{ maxWidth: 200 }}
          ellipsis={{
            tooltip: creator?.name,
          }}
        >
          {creator?.name}
        </Text>
      },
    },
    hasError ?
      {
        title: <IntlMessages id="app.error" />,
        dataIndex: 'calendar',
        key: 'calendar-error',
        render: (id, row) => {
          const calendar = state.calendars?.find(calendar => calendar.id === row.id) as ISyncedCalendar
          const iCalLink = createICalLink(row.id)

          if (!row.error) {
            return ''
          }
          if (shouldShowReconnect(row.error)) {
            return <div style={{ textAlign: 'center' }}>
              <Text
                style={{ maxWidth: 200 }}
                ellipsis={{
                  tooltip: row.type === ISyncedCalendarTypeEnum.ical ? iCalLink : <IntlMessages
                    id="connectCalendar.reconnectNotificationSharedCalendar"
                    values={{calendarName: calendar.calendarName,
                    }} />,
                }}
                copyable={row.type === ISyncedCalendarTypeEnum.ical}
                keyboard={row.type === ISyncedCalendarTypeEnum.ical}
              ><WarningTwoTone twoToneColor="red" style={{ fontSize: 20 }} /> <IntlMessages
                  id="connectCalendar.reconnectNotificationSharedCalendar"
                  values={{calendarName: calendar.calendarName,
                  }}
                /></Text>
              <Button
                type="dashed"
                danger
                onClick={() => {
                  const historyParams = {
                    pathname: calendar?.type === ISyncedCalendarTypeEnum.google ? FrontendUrls.calendarSyncPageGoogleReconnect : FrontendUrls.calendarSyncPageOutlookReconnect,
                    search: `?calendarId=${calendar?.id}`,
                  }
                  history.push(historyParams)
                }}
              >
                <IntlMessages id="connectCalendar.reconnect" />
              </Button>
            </div>
          } else
            return <Text
              style={{ maxWidth: 200 }}
              ellipsis={{
                tooltip: parsedErrorMessage(row.error),
              }}
            > <WarningTwoTone twoToneColor="red" style={{ fontSize: 20 }} />&nbsp;
              {parsedErrorMessage(row.error)}
            </Text>
        },
      } : null,
    {
      title: '',
      width: 124,
      dataIndex: 'id',
      key: 'id',
      // eslint-disable-next-line react/display-name
      render: (id: string, row) => {
        const iCalLink = createICalLink(id)
        const text = row.type === ISyncedCalendarTypeEnum.ical
          ? iCalLink
          : row.calendarName
        const calendar = state.calendars?.find(calendar => calendar.id === id) as ISyncedCalendar
        return <>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Text
              style={{ maxWidth: 200 }}
              ellipsis={{
                tooltip: iCalLink,
              }}
              copyable={row.type === ISyncedCalendarTypeEnum.ical}
              keyboard={row.type === ISyncedCalendarTypeEnum.ical}
            >
              {text}
            </Text>
            <Button
              type="link"
              disabled={(authUser.role !== 'Admin' && (row.creator && authUser.id !== row.creator))}
              onClick={(event) => confirmDeleteCalendar(calendar, event)}
            >
              <DeleteOutlined style={{ color: 'red' }} />
            </Button>
          </div>
        </>
      },
    },
  ].filter(Boolean)

  return state.whatToRender === CalendarSyncViewEnum.calendars
  && !state.isFetchingData
  && state.filteredCalendars?.length > 0 ? <Table
      dataSource={state.filteredCalendars as CalendarColumnType[]}
      columns={calendarsColumns as ColumnType<CalendarColumnType>[]}
      className="clickable-table"
      rowKey="id"
      pagination={false}
      loading={state.isFetchingData}
      onRow={(record) => {
        if (authUser.role !== 'Admin' && (record.creator && authUser.id !== record.creator)) {
          return record
        }

        if (record?.error) {
          return record
        }
        if (record.type === ISyncedCalendarTypeEnum.ical) {
          return {
            onClick: () => {
              history.push(`${FrontendUrls.calendarSyncPageIcalSummary}?calendarId=${record.id}`)
            },
          }
        } else if (record.type === ISyncedCalendarTypeEnum.google) {
          return {
            onClick: () => {
              history.push(`${FrontendUrls.calendarSyncPageGoogleUpdate}?calendarId=${record.id}`)
            },
          }
        }  else if (record.type === ISyncedCalendarTypeEnum.outlook) {
          return {
            onClick: () => {
              history.push(`${FrontendUrls.calendarSyncPageOutlookUpdate}?calendarId=${record.id}`)
            },
          }
        } else {
          return {
            onClick: () => {
              // message.error(formatMessage({ id: 'notifications.cantOpenNoptification' }))
            },
          }
        }
      }}
    /> : null
}

export const ConnectCalendarAction = ({
  handleSelectCalendarToSyncType,
  shouldEnableSyncedSharedCalendar,
}: IConnectCalendarActionProps) => {
  const completePlanOnlyLabel = !shouldEnableSyncedSharedCalendar
    ? <Text code>
      <IntlMessages id="calendar.sync.connectCalendarCompletePlanyOnly" />
    </Text>
    : null

  const menuProps = [
    {
      label: <div style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}>
        <IntlMessages id="calendar.sync.connectCalendarOptionLabel" values={{ platform: 'Google'  }} />
        {completePlanOnlyLabel}
      </div>,
      key: ISyncedCalendarTypeEnum.google,
      icon: <Icon component={googleCalendarIconSvg} alt="Google Calendar" />,
      disabled: !shouldEnableSyncedSharedCalendar,
    },
    {
      label: <div style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}>
        <IntlMessages id="calendar.sync.connectCalendarOptionLabel" values={{ platform: 'Outlook'  }} />
        {completePlanOnlyLabel}
      </div>,
      key: ISyncedCalendarTypeEnum.outlook,
      icon: <Icon component={office365CalendarIconSvg} alt="Office 365 Calendar" />,
      disabled: !shouldEnableSyncedSharedCalendar,
    },
    {
      label: <IntlMessages id="calendar.sync.connectCalendarIcalOptionLabel" />,
      key: ISyncedCalendarTypeEnum.ical,
      icon: null,
      disabled: false,
    },
  ]

  return <Form.Item>
    <Dropdown menu={{
      items: menuProps,
      onClick: handleSelectCalendarToSyncType,
      style: { width: !shouldEnableSyncedSharedCalendar ? 330 : 200 },
    }} >
      <Button type="primary"><IntlMessages id="calendar.sync.connectCalendar" /></Button>
    </Dropdown>
  </Form.Item>}

export const Empty = ({
  state,
  shouldEnableSyncedSharedCalendar,
  handleSelectCalendarToSyncType,
}: IEmptyProps) => {
  const {
    whatToRender,
    isFetchingData,
    calendars,
  } = state
  return whatToRender === CalendarSyncViewEnum.calendars
  && !isFetchingData
  && calendars?.length === 0 ? <EmptyData
      title={<IntlMessages id="calendar.sync.emptyDataTitle" />}
      subTitle={<IntlMessages id="calendar.sync.emptyDataMessage" />}
      // videoId="gR8pI28xgGo" TODO: add video id when calendar sync video is ready
      helpDesText={<IntlMessages id="app.learnMore" />}
      helpDeskLink={helpDeskLink}
      action={<ConnectCalendarAction
        shouldEnableSyncedSharedCalendar={shouldEnableSyncedSharedCalendar}
        handleSelectCalendarToSyncType={handleSelectCalendarToSyncType}
      />
      }
    /> : null
}

export const EmptySearch = ({
  state,
}: IEmptySearchProps) => {
  const {
    whatToRender,
    isFetchingData,
    filteredCalendars,
    calendars,
  } = state
  return whatToRender === CalendarSyncViewEnum.calendars
  && !isFetchingData
  && filteredCalendars?.length === 0
  && calendars?.length > 0 ? <Table
      dataSource={filteredCalendars}
      columns={[]}
      className="clickable-table"
      rowKey="id"
      pagination={false}
      loading={isFetchingData}
    /> : null
}

export const Reconnect = ({
  provider,
  whatToRender,
  handleGoogleReconnect,
  handleOutlookReconnect,
  handleCancel,
}: IReconnectProps) => {
  return whatToRender === CalendarSyncViewEnum.googleReconnect
    || whatToRender === CalendarSyncViewEnum.outlookReconnect
    ? <div>
      <Title level={4}>
        <IntlMessages id="calendar.sync.reconnect" values={{ provider }} />
      </Title>
      <br />
      <Paragraph>
        <IntlMessages id="calendar.sync.reconnectInfo" values={{ provider }} />
      </Paragraph>

      <br />
      <div>
        <Button
          type="primary"
          onClick={provider === 'Google'
            ? handleGoogleReconnect
            : handleOutlookReconnect
          }
        >
          <IntlMessages id="app.reconnect" values={{ provider }} />
        </Button>&nbsp;
        <Cancel handleCancel={handleCancel} />
      </div>
    </div>
    : null
}