import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { App, Button, Form, Input, Modal, Typography } from 'antd'
import axios from 'axios'
import * as Sentry from '@sentry/react'
import IntlMessages from '../../util/IntlMessages'
import SetPassword from '../set-password'
import { EmailNotValid, UserNotFound } from '@vacationtracker/shared/errors/users'
import { forgotPasswordSubmit, initiateForgotPasswordFlow } from '@vacationtracker/shared/services/auth'

interface IForgotPasswordModalProps {
  forgotPasswordModalVisible: boolean
  showForgotPasswordModal: (visible: boolean) => void
}

enum StepsEnum {
  email = 'email',
  code = 'code'
}

const { Paragraph } = Typography

const ForgotPasswordModal = ({
  forgotPasswordModalVisible,
  showForgotPasswordModal,
}: IForgotPasswordModalProps) => {
  const { formatMessage } = useIntl()
  const [form] = Form.useForm()
  const { notification } = App.useApp()
  const [step, setStep] = useState<StepsEnum>(StepsEnum.email)
  const [username, setUsername] = useState<string | null>(null)

  useEffect(() => {
    const emailFromSessionStorage = sessionStorage.getItem('vtUserEmail')
    if (emailFromSessionStorage) {
      form.setFieldsValue({ email: emailFromSessionStorage })
    }
  }, [])

  const resendCode = async (errorKey?: string) => {
    await resetPassword(true)
    if (errorKey) {
      notification.destroy(errorKey)
    }
    notification.success({
      message: formatMessage({ id: 'notification.codeResent' }),
      description: formatMessage({ id: 'notification.codeResentMessage' }),
      duration: 10,
    })
  }

  const resetPassword = async (justResendCode?: boolean) => {
    let values
    try {
      if (justResendCode) {
        values = await form.validateFields(['email'])
        const email: string = values.email
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/core/auth-info`, {
          email,
        })
        setUsername(res.data.username)
        await initiateForgotPasswordFlow(res.data.username)
        if (values.email && step === StepsEnum.email) {
          setStep(StepsEnum.code)
        }
      } else if (step === StepsEnum.code && username) {
        values = await form.validateFields()
        const { code, password } = values
        await forgotPasswordSubmit(username, code, password)
        notification.success({
          message: formatMessage({ id: 'notification.passwordReset' }),
          key: 'PASSWORD_RESET_SUCCESSFUL',
          description: formatMessage({ id: 'notification.passwordResetMessage' }),
          duration: 10,
        })
        showForgotPasswordModal(false)
      }
    } catch(error) {
      if (error.response?.data?.code === UserNotFound.code) {
        // https://vacationtracker.atlassian.net/browse/VT-6076
        // prevent User Email Enumeration
        // notification.error({
        //   message: formatMessage({ id: 'error.emailError' }),
        //   key: 'USER_NOT_FOUND',
        //   description: formatMessage({ id: 'error.userNotFound' }),
        //   duration: 0,
        // })
        if (values.email && step === StepsEnum.email) {
          setStep(StepsEnum.code)
        }
      }

      if (error.response?.data?.code === EmailNotValid.code) {
        notification.error({
          message: formatMessage({ id: 'error.emailError' }),
          key: 'EMAIL_NOT_VALID',
          description: formatMessage({ id: 'error.emailError.description' }),
          duration: 0,
        })
      }

      // Errors: https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-cognito-identity-provider/classes/cognitoidentityproviderserviceexception.html
      switch (error.code) {
        case 'LimitExceededException':
        case 'TooManyFailedAttemptsException':
        case 'TooManyRequestsException':
          notification.error({
            message: formatMessage({ id: 'error.passwordResetLimitExceededException' }),
            key: 'LIMIT_EXCEEDED',
            description: formatMessage({ id: 'error.passwordResetLimitExceededExceptionMessage' }),
            duration: 0,
          })
          break

        case 'CodeMismatchException':
          notification.error({
            message: formatMessage({ id: 'error.codeMismatchException' }),
            key: 'CODE_INVALID',
            description: formatMessage({ id: 'error.codeMismatchExceptionMessage' }),
            btn: (
              <Button onClick={() => resendCode('CODE_INVALID')}>{ formatMessage({ id: 'app.resendCode' }) }</Button>
            ),
            duration: 0,
          })
          break

        case 'ExpiredCodeException':
          notification.error({
            message: formatMessage({ id: 'error.expiredCodeException' }),
            key: 'CODE_EXPIRED',
            description: formatMessage({ id: 'error.expiredCodeExceptionMessage' }),
            btn: (
              <Button onClick={() => resendCode('CODE_EXPIRED')}>{ formatMessage({ id: 'app.resendCode' }) }</Button>
            ),
            duration: 0,
          })
          break

        case 'InvalidPasswordException':
          notification.error({
            message: formatMessage({ id: 'error.invalidPasswordException' }),
            key: 'INVALID_PASSWORD',
            description: formatMessage({ id: 'error.invalidPasswordExceptionMessage' }),
            duration: 0,
          })
          break

        case 'CodeDeliveryFailureException':
          notification.error({
            message: formatMessage({ id: 'error.codeDeliveryFailureException' }),
            key: 'CODE_DELIVERY_FAILURE',
            description: formatMessage({ id: 'error.codeDeliveryFailureExceptionMessage' }),
            duration: 0,
          })
          setStep(StepsEnum.email)
          break

        default:
          Sentry.captureException(error)
          break
      }
    }
  }

  return <Modal
    title={formatMessage({ id: 'app.resetPassword' })}
    open={forgotPasswordModalVisible}
    closable={true}
    onCancel={() => showForgotPasswordModal(false)}
    footer={[
      step === StepsEnum.code && <Button
        key="resend"
        type="link"
        onClick={() => resendCode()}
      >
        <IntlMessages id='app.resendCode' />
      </Button>,
      step === StepsEnum.code && <Button
        key="back"
        type="default"
        onClick={() => setStep(StepsEnum.email)}
      >
        <IntlMessages id="app.back" />
      </Button>,
      step === StepsEnum.email && <Button
        key="cancel"
        type="default"
        onClick={() => showForgotPasswordModal(false)}
        danger
      >
        <IntlMessages id="app.cancel" />
      </Button>,
      <Button
        key="submit"
        type="primary"
        onClick={() => resetPassword(step === StepsEnum.email)}
      >
        <IntlMessages id='app.resetPassword' />
      </Button>,
    ]}
  >
    <Form
      name='reset-password-form'
      form={form}
      layout="vertical"
    >
      {
        step === StepsEnum.email ? (
          <Form.Item
            name="email"
            label={<IntlMessages id="connect.forgotYourPasswordWhatsYourEmail" />}
            style={{ width: '100%' }}
            rules={[{ required: true, type: 'email', message: (<IntlMessages id="connect.pleaseInputValidEmail" />) }]}
          >
            <Input className="email-input" size="large" placeholder={formatMessage({ id: 'app.enterWorkEmail' })} />
          </Form.Item>
        ) : (
          <>
            <Paragraph><IntlMessages id="modal.forgotPassword.step2Intro" /></Paragraph>
            <Form.Item
              name="code"
              label="Code"
              style={{ width: '100%' }}
              rules={[{ required: true }]}
            >
              <Input className="code-input" size="large" placeholder={formatMessage({ id: 'signInAsUser.enterTheCode' })} />
            </Form.Item>
            <SetPassword />
          </>
        )
      }
    </Form>
  </Modal>
}

export default ForgotPasswordModal
