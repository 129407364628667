import React, { useState } from 'react'
import { App, Modal, Form, Checkbox, Select, DatePicker, Input, Upload, TreeSelect } from 'antd'
import { CloseCircleOutlined, LoadingOutlined, PlusOutlined, WarningOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import ImgCrop from 'antd-img-crop'
import dayjs from 'dayjs'

import { useAppSelector } from '../../store/hooks'
import { selectAuthUserSlice } from '../../store/auth-user-slice'
import { isMicrosoftPayment } from '../../store/auth-company-slice'

import IntlMessages from '../../util/IntlMessages'

import { IUserProps } from '../user-profile-tab/types'
import { IGetTeamsShort } from '../../types/teams'
import { IGetLocationsShort } from '../../types/locations'
import { RcFile } from 'antd/lib/upload/interface'
import { UserStatus } from '@vacationtracker/shared/types/user'
import { uploadAvatar } from '../../services/api/files'

const { Option } = Select

interface IUserUpdateFormProps {
  initialRolloverChanged: boolean
  user: IUserProps
  onSave: (v) => void
  handleCancel: () => void
  visible: boolean
  teams: IGetTeamsShort[]
  locations:  IGetLocationsShort[]
  enableUserEndDate: boolean | undefined
}

interface IUserData {
  changeTeam: boolean
  changeLocation: boolean
  changeStatus: boolean
  changeName: boolean
  name: string
  status: UserStatus
  teamId: string
  locationId: string
  isAdmin: boolean
  startDate: string
  endDate?: string
  employeeId?: string
  imageUrl?: string
}

const UserUpdateForm = ({ initialRolloverChanged, user, onSave, handleCancel, visible, teams, locations, enableUserEndDate = false }: IUserUpdateFormProps) => {
  const [form] = Form.useForm()
  const { formatMessage } = useIntl()
  const { notification } = App.useApp()
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const isMicrosoftBillingPayment = useSelector(isMicrosoftPayment)
  const loggedInAdminId = authUser.id
  const defaultValue = {
    isActive: user.status === 'ACTIVE' ? true : false,
    role: user.role,
    name: user.name,
    isAdmin: user.isAdmin,
    teamId: user.team.id,
    locationId: user.location.id,
    startDate: dayjs(user.startDate.split('T')[0]),
    endDate: user.endDate ? dayjs(user.endDate.split('T')[0]) : '',
    employeeId: user.employeeId ?? '',
  }

  const [showWarningForInitialRolloverLocationChanged, setShowWarningForInitialRolloverLocationChanged] = useState(false)
  const [showWarningForInitialRolloverStartDateChanged, setShowWarningForInitialRolloverStartDateChanged] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [uploadInProgress, setUploadInProgress] = useState(false)
  const [selectedFile, setSelectedFile] = useState<RcFile | null>(null)
  const [disableStatus, setDisableStatus] = useState(() => {
    return user.isAdmin && user.status === 'ACTIVE'
  })

  const onChangeUserRole = (newStatus) => {
    if (newStatus === 'Admin') {
      setDisableStatus(true)
      form.setFieldsValue({ isActive: true})
    } else {
      setDisableStatus(false)
    }
  }

  const onChangeLocation = (locationId) => {
    if(locationId !== user.location.id && initialRolloverChanged) {
      setShowWarningForInitialRolloverLocationChanged(true)
    } else {
      setShowWarningForInitialRolloverLocationChanged(false)
    }
  }

  const onChangeStartDate = () => {
    const startDate = dayjs(form.getFieldValue('startDate') as string).format('YYYY-MM-DD')
    if(startDate !== user.startDate.split('T')[0] && initialRolloverChanged) {
      setShowWarningForInitialRolloverStartDateChanged(true)
    } else {
      setShowWarningForInitialRolloverStartDateChanged(false)
    }
  }

  const onFinish = async () => {
    try {
      const values = await form.validateFields()
      setConfirmLoading(true)
      const newDataUser: IUserData = {
        changeTeam: false,
        changeLocation: false,
        changeStatus: false,
        changeName: false,
        name: user.name,
        status: user.status as UserStatus,
        teamId: user.team.id,
        locationId: user.location.id,
        isAdmin: values.role === 'Admin',
        startDate: dayjs(values.startDate as string).format('YYYY-MM-DD'),
        endDate: values.endDate ? dayjs(values.endDate as string).format('YYYY-MM-DD') : '',
        employeeId: values.employeeId ?? '',
      }

      if(values.teamId !== user.team.id) {
        newDataUser.changeTeam = true
        newDataUser.teamId = values.teamId
      }
      if (values.locationId !== user.location.id) {
        newDataUser.changeLocation = true
        newDataUser.locationId = values.locationId
      }
      if (!isMicrosoftBillingPayment && values.isActive !== (user.status === 'ACTIVE' ? true : false) && user.status !== 'DELETED') {
        newDataUser.changeStatus = true
        newDataUser.status = values.isActive ? 'ACTIVE' : 'INACTIVE'
      }
      if (values.name !== user.name) {
        newDataUser.changeName = true
        newDataUser.name = values.name.trimEnd()
      }

      if (selectedFile) {
        setUploadInProgress(true)
        const imageUrl = await uploadAvatar(selectedFile)
        newDataUser.imageUrl = imageUrl
      }

      onSave(newDataUser)
      setUploadInProgress(false)
    } catch(info) {
      setConfirmLoading(false)
    }
  }

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const beforePictureUpload = (file: RcFile) => {
    notification.destroy('INVALID_FILE_TYPE')
    const allowedFileFormats = ['image/png', 'image/jpeg', 'image/gif']
    if (!allowedFileFormats.includes(file.type)) {
      notification.error({
        message: formatMessage({ id: 'error.invalidFileType' }),
        key: 'INVALID_FILE_TYPE',
        description: formatMessage({ id: 'error.invalidFileType.description' }),
        duration: 0,
      })
      return
    }

    setSelectedFile(file)

    return false
  }

  return (
    <Modal
      title={<IntlMessages id="components.userUpdateForm.updateUser" />}
      open={visible}
      closeIcon={<CloseCircleOutlined />}
      confirmLoading={confirmLoading}
      okText={<IntlMessages id="app.update" />}
      onOk={() => {
        onFinish()
      }}
      cancelText={<IntlMessages id="app.cancel" />}
      onCancel={() => {
        handleCancel()
      }}
    >
      <Form
        key="userUpdateForm"
        form={form}
        name="userUpdateForm"
        initialValues={defaultValue}
      >
        {!isMicrosoftBillingPayment && !(user.platform === 'email' && user.email?.startsWith('deleted+')) &&
          <Form.Item
            name="isActive"
            valuePropName="checked"
            label={<IntlMessages id="app.active" />}
            {...formItemLayout}
          >
            <Checkbox disabled={disableStatus}/>
          </Form.Item>
        }
        <Form.Item
          name="role"
          label={<IntlMessages id="app.users.role" />}
          {...formItemLayout}
          rules={[{ required: true, message: <IntlMessages id="components.userUpdateForm.pleaseSelectAUserRole" /> }]}
        >
          <Select
            disabled={user.id === loggedInAdminId}
            value={user.isAdmin ? 'Admin' : ''}
            placeholder={<IntlMessages id="components.userUpdateForm.pleaseSelectAUserRole" />}
            onChange={(newStatus) => {
              onChangeUserRole(newStatus)
            }}
          >
            <Option value="User" key="User"><IntlMessages id="app.user" /></Option>
            <Option value="Admin" key="Admin"><IntlMessages id="app.role.administrator" /></Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="name"
          label={<IntlMessages id="app.name" />}
          {...formItemLayout}
          rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
        >
          <Input maxLength={100} style={{ width: 300 }} />
        </Form.Item>
        <Form.Item
          name="teamId"
          label={<IntlMessages id="app.department" />}
          {...formItemLayout}
          rules={[{ required: true, message: <IntlMessages id="components.userUpdateForm.pleaseSelectATeam" /> }]}
        >
          <TreeSelect 
            placeholder={<IntlMessages id="components.userUpdateForm.pleaseSelectATeam" />} 
            disabled={teams.length === 1 ? true : false}
            treeData={teams}
            treeDataSimpleMode={{id: 'id', pId: 'parentId'}}
            fieldNames={{label: 'name', value: 'id'}}
          />
        </Form.Item>
        <Form.Item
          name="locationId"
          label={<IntlMessages id="app.location" />}
          {...formItemLayout}
          rules={[{ required: true, message: <IntlMessages id="components.userUpdateForm.pleaseSelectALocation" /> }]}
          extra={showWarningForInitialRolloverLocationChanged ?
            <span><WarningOutlined /> <IntlMessages id="components.userUpdateForm.warningInitialRolloverForChangeLocation" /></span> : ''
          }
        >
          <Select
            placeholder={<IntlMessages id="components.userUpdateForm.pleaseSelectALocation" />}
            disabled={locations.length === 1 ? true : false}
            onChange={onChangeLocation}
          >
            {locations.sort((a, b) => a.name.localeCompare(b.name)).map(location => <Option value={location.id} key={location.id}>{location.name}</Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          name="startDate"
          label={<IntlMessages id="user.employeeSince" />}
          {...formItemLayout}
          rules={[{ required: true, message: <IntlMessages id="components.userUpdateForm.pleaseSelectEmployeeSince" /> }]}
          extra={showWarningForInitialRolloverStartDateChanged ?
            <span><WarningOutlined /> <IntlMessages id="components.userUpdateForm.warningInitialRolloverForChangeStartDate" /></span> : ''
          }
        >
          <DatePicker onChange={onChangeStartDate}/>
        </Form.Item>

        {enableUserEndDate && <Form.Item
          name="endDate"
          label={<IntlMessages id="app.employeeEndDate" />}
          {...formItemLayout}
        >
          <DatePicker />
        </Form.Item>}

        <Form.Item
          name="employeeId"
          label={<IntlMessages id="user.employeeId" />}
          {...formItemLayout}
          rules={[{ required: false, message: <IntlMessages id="form.inputRequired" /> }]}
        >
          <Input maxLength={32} style={{ width: 300 }} />
        </Form.Item>

        { user.id === loggedInAdminId && user.platform === 'email' && (
          <Form.Item
            name="profilePicture"
            label={<IntlMessages id="app.profilePicture" />}
            valuePropName="fileList"
            getValueFromEvent={normFile}
            {...formItemLayout}
          >
            <ImgCrop rotationSlider>
              <Upload
                listType="picture-card"
                maxCount={1}
                beforeUpload={beforePictureUpload}
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
              >
                { (user.imageUrl && !selectedFile)
                  ? <img src={`https://${user.imageUrl}?v=${Date.now()}`.replace('https://https://', 'https://')} alt="avatar" style={{ width: '100%' }} />
                  : (
                    <div>
                      { uploadInProgress ? <LoadingOutlined /> : <PlusOutlined />}
                      <div style={{ marginTop: 8 }}><IntlMessages id="app.upload" /></div>
                    </div>
                  )
                }
              </Upload>
            </ImgCrop>
          </Form.Item>
        ) }

      </Form>
    </Modal>
  )
}
export default UserUpdateForm
