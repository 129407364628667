import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { App } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useManualQuery } from 'graphql-hooks'

import Api from '@vacationtracker/shared/services/api'
import { getPurchasedAddons } from '../../../graphql/custom-queries'
import { useAppSelector } from '../../../store/hooks'
import { roleAdmin, selectAuthUserSlice } from '../../../store/auth-user-slice'
import { isTrial, selectAuthCompanySlice } from '../../../store/auth-company-slice'
import { selectDateFormatSlice } from '../../../store/date-format-slice'
import { notificationStore } from '../../../context/notificationsContext/store'
import { getExtendedAddons } from '../common/addons'
import { track } from '../../../services/analytics/analytics'

import BlackoutPeriodView from '../../../components/blackout-period-view'
import SeniorityEntitlementView from '../../../components/seniority-entitlement-view'
import EntitlementByRoleView from '../../../components/entitlement-by-role-view'
import ProbationPeriodView from '../../../components/probation-period-view'
import CircularProgress from '../../../components/circular-progress'
import VisibilityAddonView from '../../../components/visibility-addon'

import { IPageParams } from '../../../types/data'
import { IGetPurchasedAddonsData } from '../../../types/custom-queries'
import { FrontendUrls } from '../../../types/urls'
import { IAddon } from '@vacationtracker/shared/types/addons'
import { IAddonsData } from '@vacationtracker/shared/types/automations'

const AddOnsViewPage = ({ match }: IPageParams): React.ReactElement => {
  const history = useHistory()
  const { notification } = App.useApp()
  
  const { dateFormat } = useAppSelector(selectDateFormatSlice)
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { formatMessage } = useIntl()
  
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const amIAdmin = useSelector(roleAdmin)
  const isTrialSubscription = useSelector(isTrial)

  const addOnsEventTypes = getExtendedAddons(formatMessage)

  const [selectAddonType] = useState<string | null>(() => {
    if (match?.params?.addonType) {
      const addOn = addOnsEventTypes.find(addon => {
        return addon.url === match.params.addonType.replace(/_/g, '-')
      })
      return addOn?.type as string
    }
    return null
  })
  const [selectAddon] = useState<IAddonsData | undefined>(() => {
    if (match?.params?.addonType) {
      const addOn = addOnsEventTypes.find(addon => {
        return addon.url === match.params.addonType.replace(/_/g, '-')
      })
      return addOn as IAddonsData
    }
    return undefined
  })
  const [deleteCorrelationId, setDeleteCorrelationId] = useState<string | null>(null)
  const [subscribedAddons, setSubscribedAddons] = useState<IAddon[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [userActiveCount, setUserActiveCount] = useState<number>(0)
  
  const [getPurchasedAddonsQuery] = useManualQuery<IGetPurchasedAddonsData>(getPurchasedAddons)

  useEffect(() => {
    fetchData()
  }, [actionNotifications])

  const fetchData = async () => {
    const response = await getPurchasedAddonsQuery()
    if (!response.data || response.error) throw response.error

    const subscribedAddons = [
      ...(response.data?.getPurchasedAddons?.addons || []),
      ...(response.data?.getPurchasedAddons?.automations || []),
      ...(response.data?.getPurchasedAddons?.integrations || []),
    ]
    setSubscribedAddons(subscribedAddons as IAddon[])

    setUserActiveCount(response.data.getUserCount.usersActive)

    setIsLoading(false)
  }

  const deleteAddOn = async (automationId: string, name: string, automationType: string) => {
    let response
    try {
      response = await Api.post('/core/event', {
        eventType: `${automationType}_DELETED`,
        eventGroup: 'AUTOMATION',
        automationId,
      })

      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'automations.deleteInProgress' }, { automationName: name }),
        icon: <LoadingOutlined />,
        duration: 0,
      })
      setDeleteCorrelationId(response.correlationId as string)
      setActionNotifications([ ...actionNotifications, response.correlationId ])
    } catch (error) {
      let message = formatMessage({ id: 'app.deleteFailed' }) 
      let description: string | undefined = undefined
      if (error.response?.data?.error) {
        description = formatMessage({ id: error.response.data.error })
      } else if (error.message === 'Request failed with status code 400') {
        message = formatMessage({ id: 'error.automations.insufficientPrivileges.title' })
        description = formatMessage({ id: 'error.automations.insufficientPrivileges.description' })
      } else {
        description = response.correlationId ? formatMessage({ id: 'app.correlationIdError' }, { correlationId: response.correlationId }) : JSON.stringify(error)
      }
      showErrorNotification(error, message, description)
    }
  }

  const handleSubscribe = async (automationType: string, isActive) => {
    if (!amIAdmin) return
    try {
      const response = await Api.post('/core/event', {
        eventType: 'ADDONS_UPDATED',
        eventGroup: 'BILLING',
        type: automationType,
        isActive,
      })

      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: isActive ? 'automations.activateInProgress' : 'automations.deactivateInPgoress' }),
        description: formatMessage({ id: isActive ? 'automations.activateInProgressDescription' : 'automations.deactivateInPgoressDescription' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...actionNotifications, response.correlationId ])
      if (!isActive) {
        track('ADDON_UNSUBSCRIBED', { addonName: automationType })
      } else {
        if (subscribedAddons.find(addon => addon.type === automationType)?.status === 'CANCELED') {
          track('ADDON_RESUBSCRIBED', { addonName: automationType })
        } else {
          track('ADDON_SUBSCRIBED', { addonName: automationType })
        }
      }

      if (!isActive && isTrialSubscription) {
        history.push(`${FrontendUrls.addOns}`)
      }
    } catch (error) {
      showErrorNotification(error)
    }
  }

  const showErrorNotification = (error, message?: string, description?: string) => {
    const errorDescription = description ? description : error.response?.data?.message ? error.response?.data.message : error.message ? error.message : JSON.stringify(error)
    notification.error({
      message: message ? message : formatMessage({ id: 'error.generic' }),
      description: errorDescription,
      duration: 0,
    })
  }

  return (
    <>
      {isLoading ?
        <CircularProgress /> :
        <>
          {selectAddonType === 'BLACKOUT_PERIOD' &&
            <BlackoutPeriodView
              formatMessage={formatMessage}
              amIAdmin={amIAdmin}
              deleteAutomation={(id: string, name: string, automationType: string) => {
                deleteAddOn(id, name, automationType)
              }}
              authUserId={authUser.id}
              dateFormat={dateFormat}
              deleteCorrelationId={deleteCorrelationId}
              handleSubscribe={(automationType: string, isActive: boolean) => {
                handleSubscribe(automationType, isActive)
              }}
              status={subscribedAddons.find(addon => addon.type === 'BLACKOUT_PERIOD')?.status}
              userCount={userActiveCount}
            />
          }
          {selectAddonType === 'SENIORITY_ENTITLEMENT' &&
            <SeniorityEntitlementView
              formatMessage={formatMessage}
              amIAdmin={amIAdmin}
              deleteAutomation={(id: string, name: string, automationType: string) => {
                deleteAddOn(id, name, automationType)
              }}
              authUserId={authUser.id}
              dateFormat={dateFormat}
              deleteCorrelationId={deleteCorrelationId}
              handleSubscribe={(automationType: string, isActive: boolean) => {
                handleSubscribe(automationType, isActive)
              }}
              status={subscribedAddons.find(addon => addon.type === 'SENIORITY_ENTITLEMENT')?.status}
              userCount={userActiveCount}
            />
          }
          {selectAddonType === 'ENTITLEMENT_BY_ROLE' &&
            <EntitlementByRoleView
              formatMessage={formatMessage}
              amIAdmin={amIAdmin}
              deleteAutomation={(id: string, name: string, automationType: string) => {
                deleteAddOn(id, name, automationType)
              }}
              authUserId={authUser.id}
              dateFormat={dateFormat}
              deleteCorrelationId={deleteCorrelationId}
              handleSubscribe={(automationType: string, isActive: boolean) => {
                handleSubscribe(automationType, isActive)
              }}
              status={subscribedAddons.find(addon => addon.type === 'ENTITLEMENT_BY_ROLE')?.status}
              userCount={userActiveCount}
            />
          }   
          {selectAddonType === 'VISIBILITY' &&
            <VisibilityAddonView
              formatMessage={formatMessage}
              amIAdmin={amIAdmin}
              authUserId={authUser.id}
              dateFormat={dateFormat}
              handleSubscribe={(automationType: string, isActive: boolean) => {
                handleSubscribe(automationType, isActive)
              }}
              status={subscribedAddons.find(addon => addon.type === 'VISIBILITY')?.status}
              userCount={userActiveCount}
              backToAddons={() => history.push(FrontendUrls.addOns)}
            />
          }
          {selectAddonType === 'PROBATION_PERIOD' &&
            <ProbationPeriodView
              formatMessage={formatMessage}
              amIAdmin={amIAdmin}
              deleteAutomation={(id: string, name: string, automationType: string) => {
                deleteAddOn(id, name, automationType)
              }}
              authUserId={authUser.id}
              dateFormat={dateFormat}
              deleteCorrelationId={deleteCorrelationId}
              handleSubscribe={(automationType: string, isActive: boolean) => {
                handleSubscribe(automationType, isActive)
              }}
              status={subscribedAddons.find(addon => addon.type === 'PROBATION_PERIOD')?.status}
              userCount={userActiveCount}
              addonDetails={selectAddon}
              currentPlan={authCompany?.plan}
            />
          }
        </>
      }
    </>
  )
}

export default AddOnsViewPage