import { getAuthTokens } from '../auth'

interface IPostJsonRequest {
  url: string
  data: BodyInit
  token?: string
}

interface IOptions {
  headers?: Record<string, string>
  withCredentials?: boolean
}

const getNormalizedHeaders = (headers: Headers) => {
  const headerObj: Record<string, string | null> = {}
  if (!headers) {
    return {}
  }
  const keys = headers.keys()
  let header = keys.next()
  while (header.value) {
    headerObj[header.value.toLowerCase()] = headers.get(header.value)
    header = keys.next()
  }
  return headerObj
}

const prepareError = async (response: Response) => {
  const responseJson = await response.json()
  const errorResponse = (typeof responseJson === 'string') ? { error: responseJson } : responseJson
  return {
    // Added for Amplify API.post error handling compatibility
    response: {
      data: errorResponse,
    },
    ...errorResponse,
  }
}

export const getJsonRequest = async (url: string, token?: string, withCredentials = false, f = fetch, getTokensFn = getAuthTokens) => {
  let idToken = token
  if (!token) {
    const tokens = await getTokensFn()
    idToken = tokens.idToken
  }

  const params: RequestInit = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${idToken}`,
    },
  }

  if (withCredentials) {
    params.credentials = 'include'
  }

  const response = await f(url, params)

  if (response.status >= 400) {
    const error = await prepareError(response)
    throw error
  }

  return response.json()
}

export const postJsonRequest = async ({
  url,
  data,
  token,
}: IPostJsonRequest, f = fetch, getTokensFn = getAuthTokens) => {
  let idToken = token
  if (!token) {
    const tokens = await getTokensFn()
    idToken = tokens.idToken
  }

  const response = await f(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${idToken}`,
    },
    body: typeof data === 'string' ? data : JSON.stringify(data),
  })

  if (response.status >= 400) {
    const error = await prepareError(response)
    throw error
  }

  const normalizedHeaders = getNormalizedHeaders(response.headers)

  if (response.status === 204 || normalizedHeaders['content-length'] === '0') {
    return
  }

  return response.json()
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const get = async <T = any>(url: string, options?: IOptions, getRequest = getJsonRequest): Promise<T> => {
  let token: string | undefined
  if (options?.headers && options.headers.Authorization) {
    token = options.headers.Authorization
  }
  const params = {
    url: `${process.env.REACT_APP_API_URL}${url}`,
  }
  return getRequest(params.url, token)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const post = async <T = BodyInit, TR = any>(url: string, data: T, options?: IOptions, postRequest = postJsonRequest): Promise<TR> => {
  const params: IPostJsonRequest = {
    url: `${process.env.REACT_APP_API_URL}${url}`,
    data: JSON.stringify(data),
  }
  if (options?.headers && options.headers.Authorization) {
    params.token = options.headers.Authorization
  }
  return postRequest(params)
}

const Api = {
  get,
  post,
}

export default Api
