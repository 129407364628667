import { ICoreEvent, Platform } from './core-event'
import { ICompanySlackEvents } from './slack'
import { IGoogleDetails } from './google'
import { BillingTypesEnum, PaymentProcessor } from './billing'
import { LocaleEnum } from './i18n'
import { FeatureFlagEnum } from './feature-flags'
import  { Dayjs } from 'dayjs'
import { IMicrosoftUserDetails } from './microsoft'

export type SubscriptionPlan = 'Core' | 'Complete' | 'Enterprise'
export type SubscriptionStatus =
| 'active'
| 'canceled'
| 'past_due'
| 'trialing'
export type TrialPeriod = 'in_trial' | 'not_trial' | 'trial_will_end'

export enum SubscriptionPlanEnum {
  core = 'Core',
  complete = 'Complete',
  enterprise = 'Enterprise'
}

export type SignupVariation = 'A' | 'B' | 'C'

export interface ICompany {
  id: string
  name: string
  contactEmail: string
  platform: Platform
  importUsersAutomatically: boolean
  aiBotHelper?: boolean
  userEndDate?: boolean
  organizationId: string
  timestamp?: string
  plan?: SubscriptionPlan
  trialPeriod?: TrialPeriod
  subscriptionStatus?: SubscriptionStatus
  announceNewUsers?: boolean
  hourlyLeaveAccounting?: boolean
  calculationStartYear?: string
  slack?: {
    slackBotToken: string
    slackUserToken: string
    slackTeamId: string
    slackUserId: string
    slackOrgTeamId: string
    isOwner: boolean
    slackBotId: string
  }
  shouldProrate?: boolean
  shouldImportGuestUsers?: boolean
  proratedLeaveTypes?: string[]
  defaultDepartmentForAutomaticImport?: string
  defaultLocationForAutomaticImport?: string
  defaultWorkingHours?: number
  dpaConsent?: boolean
  privacyPolicyAccepted?: boolean
  termsOfServiceAccepted?: boolean
}

export interface ICompanyInfo {
  id: string
  msTeamId?: string
  msIsBotInstalled?: string
  name: string
  organizationId: string
  contactEmail: string
  plan?: SubscriptionPlan
  trialPeriod: TrialPeriod
  subscriptionStatus: SubscriptionStatus
  userEndDate?: boolean | null
  platform: Platform
  shouldProrate?: boolean
  shouldImportGuestUsers?: boolean
  proratedLeaveTypes?: string[]
  defaultDepartmentForAutomaticImport?: string
  defaultLocationForAutomaticImport?: string
  importUsersAutomatically?: boolean
  aiBotHelper?: boolean
  announceNewUsers?: boolean
  hourlyLeaveAccounting?: boolean
  calculationStartYear?: string | Dayjs
  timestamp?: string
  billing?: {
    stripeCurrentPeriodEnd?: string
    address?: {
      city: string
      country: string
      state?: string
      address: string
      addressLine2?: string
    }
    paymentMethodAdded?: boolean
    paymentProcessor?: PaymentProcessor
    subscriptionPeriod?: string
    subscriptionPlan?: string
    nextSubscriptionPeriod?: string
    nextSubscriptionPlan?: string
    quantity?: number
    msPlanId?: string
    msSubscriptionStatus?: string
    msPeriodStartDate?: string
    msPeriodEndDate?: string
    couponCodes?: string[]
    billingType?: BillingTypesEnum
    seats?: number
    billingName?: string
    billingEmails?: string[]
    stripePriceId?: string
  }
}

export interface ICompanyCreatedEvent extends ICoreEvent {
  eventType: 'COMPANY_CREATED'
  eventGroup: 'COMPANY'
  companyId: string
  locationId: string
  teamId: string
  msTeamId?: string
  name: string
  contactEmail: string
  organizationId: string
  country: string
  state?: string
  hasUnlimitedDays: boolean
  daysPerYear: number
  timezone: string
  plan: SubscriptionPlan
  paymentProcessor: PaymentProcessor
  dpaConsent: boolean
  privacyPolicyAccepted: boolean
  termsOfServiceAccepted: boolean
  locale?: LocaleEnum
  calculationStartYear?: string
  msPurchaseToken?: string
  slack?: ICompanySlackEvents
  google?: IGoogleDetails
  microsoft?: IMicrosoftUserDetails
  affiliateCode?: string
  signupVariation?: SignupVariation
  surveyQuestionMainOption?: string
  surveyQuestionSubOption?: string
  announceNewUsers?: boolean
  utmCookie?: {[key: string]: string}
  featureFlags?: FeatureFlagEnum[]
}

export interface ICompanyDeletedEvent extends ICoreEvent {
  eventType: 'COMPANY_DELETED'
  eventGroup: 'COMPANY'
  companyId: string
}

export interface ICompanySettingsUpdatedEvent extends ICoreEvent {
  eventType: 'COMPANY_SETTINGS_UPDATED'
  eventGroup: 'COMPANY'
  companyId: string
  refreshToken?: string
  importUsersAutomatically?: boolean
  aiBotHelper?: boolean
  announceNewUsers?: boolean
  name?: string
  userEndDate?: boolean
  hourlyLeaveAccounting?: boolean
  shouldProrate?: boolean
  shouldImportGuestUsers?: boolean
  proratedLeaveTypes?: string[]
  defaultLocation?: string
  defaultDepartment?: string
  calculationStartYear?: string
  contactEmail?: string
  defaultWorkingHours?: number
}

export interface ICompanySettingsUpdatedBody {
  importUsersAutomatically?: boolean
  announceNewUsers?: boolean
  name?: string
  userEndDate?: boolean
  hourlyLeaveAccounting?: boolean
  contactEmail?: string
}

export interface ICompanyStatusChangedEvent extends ICoreEvent {
  eventType: 'COMPANY_STATUS_CHANGED'
  eventGroup: 'COMPANY'
  companyId: string
  status: string
}

export interface IGetCompanyGeneral {
  getCompany: ICompanyGeneral
}

export interface ICompanyGeneral {
  id: string
  name: string
  contactEmail: string
  platform: Platform
  shouldProrate?: boolean
  shouldImportGuestUsers?: boolean
  proratedLeaveTypes?: string[]
  defaultDepartmentForAutomaticImport?: string
  defaultLocationForAutomaticImport?: string
  importUsersAutomatically?: boolean
  aiBotHelper?: boolean
  announceNewUsers?: boolean
  userEndDate?: boolean
  hourlyLeaveAccounting?: boolean
  calculationStartYear?: string | Dayjs
}

export interface ICheckUserId {
  vacationTrackerId?: string
  id?: string
  mail?: string
  name?: string
  isAdmin?: boolean
  type?: 'COMPANY_NOT_FOUND' | 'USER_NOT_FOUND'
  organizationName?: string
  contactEmail?: string
  adminContacts?: {name: string; email: string}[]
  subscriptionStatus?: SubscriptionStatus
  existsOnAnotherPlatform?: string
  existingOrganizationName?: string
  slackUser?: {
    slackTeamId: string
    slackUserId: string
    isOwner: boolean | null
    isAdmin: boolean | null
    imageUrl: string | null
    timezone: string | null
  }
}

export interface IFeatureFlag {
  companyId: string
  featureFlags: FeatureFlagEnum[]
}

export interface ICompanyPlatformChangedEvent extends ICoreEvent {
  eventType: 'COMPANY_PLATFORM_CHANGED'
  eventGroup: 'COMPANY'
  deleteUnmapped: boolean
  companyId: string
  newPlatform: Platform
  oldPlatform: Platform
  userMappings: {
    [key: string]: string | null
  }
  organizationId: string
  token?: string
}