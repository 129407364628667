import React from 'react'
import { Button, Row, Switch, Typography } from 'antd'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'
import { isTrial } from '../../store/auth-company-slice'
import AddonsCard from '../addons-card'
import EmptyData from '../empty-data'

import { useAppSelector } from '../../store/hooks'
import { selectAuthUserSlice } from '../../store/auth-user-slice'
import { selectAuthCompanySlice } from '../../store/auth-company-slice'
import { track } from '../../services/analytics/analytics'

import { AddOnsActiveTabEnum, IAddonsData } from '@vacationtracker/shared/types/automations'
import { AddonsStatusEnum, IAddon } from '@vacationtracker/shared/types/addons'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'

interface IMyAddons {
  subscribedAddons: IAddon[]
  addonsEvent: IAddonsData[]
  changeTab: (tab: AddOnsActiveTabEnum) => void
  addonsPrice: number
}

const MyAddons = ({
  subscribedAddons,
  addonsEvent,
  changeTab,
  addonsPrice,
}: IMyAddons): JSX.Element => {
  const history = useHistory()

  const [hideCanceledAddons, setHideCanceledAddons] = React.useState(true)
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const isTrialSubscription = useSelector(isTrial)
  const { authCompany } = useAppSelector(selectAuthCompanySlice)

  return (
    <div className='my-addons'>
      <div className="my-addons-title">
        <Typography.Text style={{ marginBottom: 30, fontWeight: 700, display: 'block' }}>
          <IntlMessages id="choiceAutomations.mySbscribedAddons" />
        </Typography.Text>
        {subscribedAddons.length > 0 &&
          <div>
            <IntlMessages id={'automations.showCaneledAddons'} />
            <Switch style={{ marginLeft: 7 }} onClick={() => { 
              setHideCanceledAddons(!hideCanceledAddons)
              track('SHOW_CANCELED_ADDONS_TOGGLED', {})
            }} />
          </div>
        }
      </div>

      <Row>
        {subscribedAddons.map((addon) => {
          if(addon.status === AddonsStatusEnum.CANCELED && hideCanceledAddons) {
            return
          }
          const addonData = addonsEvent.find((a) => a.type === addon.type)
          if(!addonData) {
            return ''
          }

          return (
            <AddonsCard
              viewMode={true}
              status={addon.status}
              key={addonData.type}
              type={addonData.type}
              parent={addonData.parent}
              parentLabel={addonData.parentLabel}
              roles={addonData.roles}
              url={addonData.url}
              icon={addonData.icon}
              description={addonData.description}
              onSelectAddon={(url: string, parent: string, type: string) => {
                history.push(`/app/add-ons/${url}/view`)
                track('ADDONS_MODULE_OPENED', {
                  addonName: type,
                })
              }}
              addonsPrice={addonsPrice}
              authUserRole={authUser.role}
              helpDesklink={addonData.helpDesklink}
              isTrialSubscription={isTrialSubscription}
              includedIn={addonData.includedIn}
              subscriptionPlan={authCompany?.plan as SubscriptionPlanEnum}
            />
          )
        })}

        {subscribedAddons.filter(item => item.status !== AddonsStatusEnum.CANCELED).length === 0 && hideCanceledAddons && 
          <EmptyData
            title={<IntlMessages id="automations.emptyViewTitle" />}
            subTitle={<IntlMessages id="automations.emptyViewMessage" />}
            action={
              <Button type="primary" onClick={() => changeTab(AddOnsActiveTabEnum.discoveryMoreAddons)}>
                <IntlMessages id="automations.emptyViewButton" />
              </Button>
            }
          />
        }
      </Row>

    </div>
  )
}

export default MyAddons
