import { ProductName } from '../data/stripe/prices'
import { AddonsStatus, IntegrationsType, AutomationsType, AddonsType, VisibilityLevel } from './addons'
import { PaymentProcessor } from './billing'
import { SubscriptionPlan } from './company'
import { ICoreEvent } from './core-event'
import { Dayjs } from 'dayjs'

export type SeniorityEntitlementCalculateFrom = 'USER_EMPLOYMENT_DATE' | 'TODAY' | 'CURRENT_YEAR'
export type AwardSeniorityEntitlementDaysOn = 'USER_WORK_ANNIVERSARY' | 'BEGINNING_OF_THE_FISCAL_YEAR'

export type IAutomationsInterface = 
  IAutomationBlackOutPeriod | 
  IAutomationSeniorityEntitlement | 
  IAutomationEntitlementByRole | 
  IAutomationProbationPeriod

export interface IAutomationViewPage {
  formatMessage: Function
  amIAdmin: boolean
  deleteAutomation: (id: string, name: string, automationType: string) => void
  authUserId: string
  dateFormat: string
  handleSubscribe: (automationType: string, isActive: boolean) => void
  deleteCorrelationId: string | null
  status?: AddonsStatus
  userCount: number
  addonDetails?: IAddonsData
  currentPlan?: SubscriptionPlan
}

export interface IAddonsUpdatedEvent extends ICoreEvent {
  eventType: 'ADDONS_UPDATED'
  eventGroup: 'BILLING'
  type: AutomationsType | AddonsType | IntegrationsType
  isActive: boolean
}

export interface IAutomationBlackOutForm {
  automationId: string
  name: string
  leaveTypeIds: string[]
  startDate: string
  endDate: string
  recurring: boolean
  isActive: boolean
  reason: string
  period: [Dayjs, Dayjs]
  applyToAllUsers: boolean
  locations?: string[]
  teams?: string[]
  labels?: string[]
  owner?: {
    id: ''
    name: ''
  }
}

export interface IAutomationSeniorityEntitlementForm {
  automationId: string
  name: string
  leaveTypeId: string
  calculateFrom?: SeniorityEntitlementCalculateFrom
  awardSeniorityEntitlementDaysOn?: AwardSeniorityEntitlementDaysOn
  isActive: boolean
  prorate: boolean
  periods: {
    years: number
    days: number
  }[]
  applyToAllUsers: boolean
  locations?: string[]
  teams?: string[]
  labels?: string[]
  owner?: {
    id: ''
    name: ''
  }
}

export interface IAutomationEntitlementByRoleForm {
  automationId: string
  name: string
  leaveTypeId: string
  startDate: string
  isActive: boolean
  labelId: string
  days: number
  owner?: {
    id: ''
    name: ''
  }
}

export interface IAutomationBlackOutCreateEvent extends ICoreEvent {
  eventType: 'BLACKOUT_PERIOD_CREATED' | 'BLACKOUT_PERIOD_UPDATED'
  eventGroup: 'AUTOMATION'
  name: string
  automationType: string
  automationId: string
  startDate: string
  endDate: string
  recurring: boolean
  isActive: boolean
  reason: string
  leaveTypeIds: string[]
  locations?: string[]
  teams?: string[]
  labels?: string[]
}

export interface IAutomationProbationPeriodCreateEvent extends ICoreEvent {
  eventType: 'PROBATION_PERIOD_CREATED' | 'PROBATION_PERIOD_UPDATED'
  eventGroup: 'AUTOMATION'
  name: string
  automationType: string
  automationId: string
  lengthDays: number
  isActive: boolean
  reason: string
  leaveTypeIds: string[]
  locations?: string[]
  teams?: string[]
  labels?: string[]
}

export interface IAutomationDeleteEvent extends ICoreEvent {
  eventType: 'BLACKOUT_PERIOD_DELETED' | 'SENIORITY_ENTITLEMENT_DELETED' | 'ENTITLEMENT_BY_ROLE_DELETED' | 'PROBATION_PERIOD_DELETED'
  eventGroup: 'AUTOMATION'
  automationId: string
}

export interface IAutomationSeniorityEntitlementCreateEvent extends ICoreEvent {
  eventType: 'SENIORITY_ENTITLEMENT_CREATED' | 'SENIORITY_ENTITLEMENT_UPDATED'
  eventGroup: 'AUTOMATION'
  name: string
  automationType: string
  automationId: string
  startDate?: string
  calculateFrom?: SeniorityEntitlementCalculateFrom
  awardSeniorityEntitlementDaysOn?: AwardSeniorityEntitlementDaysOn
  periods: {
    years: number
    days: number
  }[]
  isActive: boolean
  leaveTypeId: string
  prorate?: boolean
  locations?: string[]
  teams?: string[]
  labels?: string[]
}

export interface ISeniorityEntitlementByLeaveTypeKey extends IAutomationSeniorityEntitlementCreateEvent {
  allowSeniorityEntitlement: boolean
}

export interface IAutomationSeniorityEntitlementDeleteEvent extends ICoreEvent {
  eventType: 'SENIORITY_ENTITLEMENT_DELETED'
  eventGroup: 'AUTOMATION'
  automationId: string
}

export interface IAutomationEntitlementByLabelCreateEvent extends ICoreEvent {
  eventType: 'ENTITLEMENT_BY_ROLE_CREATED' | 'ENTITLEMENT_BY_ROLE_UPDATED'
  eventGroup: 'AUTOMATION'
  name: string
  automationType: string
  automationId: string
  startDate: string
  days: number
  isActive: boolean
  leaveTypeId: string
  labelId: string
}

export interface IAutomationBlackOutPeriod {
  companyId: string
  id: string
  automationType: string
  name: string
  leaveTypeIds: string[]
  startDate: string
  endDate: string
  recurring: boolean
  isActive: boolean
  reason: string
  owner: string
  createdAt: string
  locations?: string[]
  teams?: string[]
  labels?: string[]
}

export interface IAutomationSeniorityEntitlement {
  companyId: string
  id: string
  automationType: string
  name: string
  leaveTypeId: string
  calculateFrom: SeniorityEntitlementCalculateFrom
  awardSeniorityEntitlementDaysOn?: AwardSeniorityEntitlementDaysOn
  isActive: boolean
  prorate: boolean
  periods?: {
    days: number
    years: number
  }[]
  owner: string
  createdAt: string
  locations: string[]
  teams: string[]
  labels: string[]
}

export interface IAutomationEntitlementByRole {
  companyId: string
  id: string
  automationType: string
  name: string
  leaveTypeId: string
  startDate: string
  isActive: boolean
  days: number
  labelId: string
  owner: string
  createdAt: string
}

export interface IAddonsBillingUpdatedEvent {
  type: 'ADDONS-BILLING-UPDATED'
  meta: {
    paymentProcessor: PaymentProcessor
  }
  payload: IAddonsBillingUpdatedPayload
}

export interface IAddonsBillingUpdatedPayload extends IAddonsUpdatedEvent {
  product: ProductName
}

export enum AddOnsActiveTabEnum {
  discoveryMoreAddons = 'DISCOVERY_MORE_ADDONS',
  myAddons = 'MY_ADDONS'
}

export enum AddonsParent {
  automations = 'automations',
  integrations = 'integrations',
  addons = 'addons'
}

export interface IAddonsData {
  type: string
  parent: AddonsParent
  parentLabel: string
  roles: string[]
  url: string
  icon?: React.ReactNode
  description: string
  helpDesklink?: string
  includedIn?: string[]
}

export interface IUpdateAutomation {
  name?: string
  automationType?: string
  calculateFrom?: SeniorityEntitlementCalculateFrom
  awardSeniorityEntitlementDaysOn?: AwardSeniorityEntitlementDaysOn
  periods?: {
    years: number
    days: number
  }[]
  startDate?: string
  endDate?: string
  recurring?: boolean
  isActive?: boolean
  reason?: string
  leaveTypeId?: string
  locations?: string[]
  leaveTypeIds?: string[]
  teams?: string[]
  labels?: string[]
  createdAt?: string
  owner?: string
  prorate?: boolean
  labelId?: string
  days?: number
  visibilityLevel?: VisibilityLevel[]
  lengthDays?: number
}

export interface IAutomationProbationPeriodForm {
  automationId: string
  name: string
  leaveTypeIds: string[]
  isActive: boolean
  reason: string
  lengthDays: number
  applyToAllUsers: boolean
  locations?: string[]
  teams?: string[]
  labels?: string[]
  owner?: {
    id: ''
    name: ''
  }
}

export interface IAutomationProbationPeriod {
  companyId: string
  id: string
  automationType: string
  name: string
  leaveTypeIds: string[]
  lengthDays: number
  isActive: boolean
  reason: string
  owner: string
  createdAt: string
  locations?: string[]
  teams?: string[]
  labels?: string[]
}